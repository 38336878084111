<template>
  <div class="monitoring-wrap">
    <template-search-read :readApi="readApi"
                          :reload="reload"
                          :buttonOption="buttonOption"
                          @button="button"
                          @searchFilter="getSearchFilter">
    </template-search-read>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam">
    </template-detail>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { alertError } from '@/utils/tools'

import { updateSamsunCodeCheckMemo, getSamsunCodeCheckList, getSamsungUserApply } from '@/api/monitoring'

import TemplateSearchRead from '@/components/_template/SearchRead'
import TemplateDetail from '@/components/_template/Detail'

export default {
  name: 'MonitoringPaidUser',
  components: {
    TemplateSearchRead,
    TemplateDetail
  },
  methods: {
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
      this.apiParam = null
      if (value === 'save') {
        EventBus.$emit('onLoading', true)
        updateSamsunCodeCheckMemo({
          aid: data._id,
          memo: data.adminMemo
        }).then(response => {
          alert('메모가 저장되었습니다.')
          this.reload++
        }).catch(error => {
          alertError('저장에 실패했습니다.', error)
        }).finally(() => {
          EventBus.$emit('onLoading', false)
        })
      } else if (value === 'adminPlanUpgrade' ||
        value === 'adminPvEndAlarm' ||
        value === 'adminPvAlarm' ||
        value === 'adminApplyNotInstall'
      ) {
        const params = JSON.parse(JSON.stringify(data))
        params.templateName = value
        this.apiParam = params
      } else if (value === 'showDetail') {
        this.showDetail(data)
      }
    },
    showDetail (data) {
      this.apiParam = { aid: data.aid }
    }
  },
  data () {
    return {
      infoApi: getSamsungUserApply,
      editApi: true,
      reload: 0,
      readApi: getSamsunCodeCheckList,
      searchFilter: {},
      apiParam: null,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.monitoring-wrap {
  .table-list .list-table tbody tr td {
    overflow: hidden !important;

    span {
      overflow: initial !important;
    }
  }
}
</style>
