const state = {
  beusPlanData: null,
  baPlanData: null,
  beusPlanDataByGroup: [],
  baPlanDataByGroup: []
}

const getters = {
  beusPlanData: state => state.beusPlanData,
  beusPlanDataByGroup: state => state.beusPlanDataByGroup,
  baPlanData: state => state.baPlanData,
  baPlanDataByGroup: state => state.baPlanDataByGroup
}

const mutations = {
  setBeusPlanData (state, data) {
    state.beusPlanData = data
  },
  setBeusPlanDataByGroup (state, data) {
    state.beusPlanDataByGroup = data
  },
  setBaPlanData (state, data) {
    state.baPlanData = data
  },
  setBaPlanDataByGroup (state, data) {
    state.baPlanDataByGroup = data
  }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
