<template>
  <div>
    AWS Security Group
  </div>
</template>

<script>

import { EventBus } from '@/utils/event-bus'

export default {
  name: 'AWSSecurityGroup',
  created () {
    EventBus.$emit('onLoading', false)
  }
}
</script>

<style lang="scss" scoped>
.dev-aws-list-wrap {
}
</style>
