<template>
  <div class="ba-domain-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :menu="'baDomain'"
                          :selected="selected"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @toReport="toReport"
                          @showDetail="showDetail"
                          @searchSiteMap="searchSiteMap"
    >
    </template-search-list>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     @button="button"
                     @close="setSelected">
    </template-detail>
  </div>
</template>

<script>
import { BA_URL } from '@/utils/tools'
import { EventBus } from '@/utils/event-bus'
import { getBaDomainList, getBaDomainInfo, getBaDomainExcelMeta } from '@/api/baService'

import UiButton from '@/components/_ui/UiButton'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'

import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { STORAGE_ITEM } from '@/utils/constants'
import { getBaRawDataInfo, getBaRawDataList } from '@/api/rawData'
import { format } from 'date-fns'
import { da } from 'date-fns/locale'
import axios from 'axios'

export default {
  name: 'RawDataBaList',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail
  },
  created () {
    // console.log('eventbus: ', EventBus.$on('searchData', this.apiParam))
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.userId) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.userId = searchData.userId
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    async button (value, data) {
      if (value === 'rawJourneyDownload') {
        if (data.s3Path) {
          const s3PathList = data.s3Path.replace('s3://').split('/')
          const fileName = s3PathList[s3PathList.length - 1]

          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/rawData/download?s3Path=${data.s3Path}`, {
            responseType: 'blob'
          })

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      }
    },
    toReport (data) {
      window.open(`${BA_URL}/journey?sid=${data.orgSid}`)
    },
    searchSiteMap (data) {
      const searchFilter = {}
      searchFilter.parentSid = data.orgSid
      searchFilter.userId = data.userId
      this.$router.push({ name: 'ServiceBaSitemap', params: { initialSearchFilter: searchFilter } })
    },
    showDetail (data) {
      console.log(data)
      this.apiParam = {
        parentSid: data.parentSid,
        startDateStr: data.startDateStr,
        endDateStr: data.endDateStr,
      }
    },
    openCreateModal () {
      this.show.domainCreate = true
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    getStartDate () {
      let currenDate = new Date()
      currenDate.setDate(currenDate.getDate() - 1)
      return format(currenDate, 'yyyy-MM-dd')
    },
    getEndDate () {
      let currenDate = new Date()
      currenDate.setDate(currenDate.getDate() - 1)
      return format(currenDate, 'yyyy-MM-dd')
    },
    setSelected (row) {
      this.selected = row
    }
  },
  data () {
    return {
      selected: null,
      infoApi: getBaRawDataInfo,
      apiParam: null,
      listApi: getBaRawDataList,
      buttonOption: [
        {
          action: 'toReport',
          columnText: '리포트',
          buttonText: '<i class="xi-document"></i>',
          if: ['state', '!=', 'error']
        },
        {
          action: 'searchSiteMap',
          columnText: '사잍맵',
          buttonText: '<i class="xi-sitemap"></i>',
          if: ['sitemapCount', '>', 0]
        },
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',
      excelDownload: false,
      searchFilter: {},
      initialSearchFilter: {
        startDateStr: '',
        endDateStr: this.getStartDate(),
        period: 'day'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ba-domain-list-wrap {
}
</style>
