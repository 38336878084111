<template>
  <div class="ba-sitemap-list-wrap">
    <template-search-list :listApi="listApi"
                          :initialSearchFilter="initialSearchFilter"
                          @searchFilter="getSearchFilter"
                          @listCount="getListCount"
                          :defaultSortBy="defaultSortBy">
      <ui-button :color="'purple'"
                 @click="toggleExcel(true)">
        <i class="xi-view-list"></i> 엑셀 다운로드
      </ui-button>
    </template-search-list>

    <excel-column-select :excelDownload="excelDownload"
                         :metaApi="excelListMetaApi"
                         :listApi="listApi"
                         :searchFilter="searchFilter"
                         @export="excelExport"
                         @close="toggleExcel(false)"/>
  </div>
</template>

<script>
import { getBaSitemapExcelMeta, getBaSitemapList } from '@/api/baService'

import TemplateSearchList from '@/components/_template/SearchList'
import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import UiButton from '@/components/_ui/UiButton'
import { EventBus } from '@/utils/event-bus'

export default {
  name: 'BaSitemapList',
  components: {
    TemplateSearchList,
    ExcelColumnSelect,
    UiButton
  },
  props: {
    initialSearchFilter: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      listApi: getBaSitemapList,
      excelListMetaApi: getBaSitemapExcelMeta,
      navigationOption: {
        pageNum: 1,
        pageSize: 50,
        totalCount: 0
      },
      defaultSortBy: '-regDate',
      searchFilter: {},
      excelDownload: false,
      listCount: 0
    }
  },
  methods: {
    toggleExcel (value = false) {
      if (value) {
        if (!this.searchFilter.parentSid || !this.searchFilter.userId) {
          alert('sid와 userId를 입력 후 검색해주세요.')
        } else {
          let confirmResult = true
          if (this.listCount > 1000) {
            confirmResult = false
            confirmResult = confirm('1000개까지 다운로드가 가능합니다.')
          }
          if (confirmResult) {
            this.excelDownload = value
          }
        }
      } else {
        this.excelDownload = value
      }
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_ba_sitemap'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('service', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    getListCount (value) {
      this.listCount = value
    }
  }
}
</script>

<style lang="scss" scoped>
.ba-sitemap-list-wrap {
}
</style>
