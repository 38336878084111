import { request } from '@/utils/api-request'
import { encrypt } from '@/utils/tools'

export function signIn (params) {
  const url = '/admin/user/login'
  const newParams = {
    ...params,
    password: encrypt(params.password, params.username)
  }

  const config = {
    method: 'post',
    url,
    data: newParams
  }
  return request(config, true)
}

export function getUserList (params) {
  const url = '/admin/user/user_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUnauthUserList (params) {
  const url = '/admin/user/unauth_user_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getChangeInsertMeta (force) {
  const url = '/admin/user/change_user_id'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function changeUser (params) {
  const url = '/admin/user/change_user_id'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUserInsertMeta (force) {
  const url = '/admin/user/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function insertUser (params) {
  const url = '/admin/user/insert'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUserInfo (params) {
  const url = '/admin/user/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUnauthUserInfo (params) {
  const url = '/admin/user/unauth_user_info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateUser (params) {
  const url = '/admin/user/update'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function searchUser (params) {
  const url = '/admin/user/search'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function searchNotExistUser (params) {
  const url = '/admin/user/check_user'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUserExcelMeta (force) {
  const url = '/admin/user/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getUserListSs (params) {
  const url = '/admin/samsung/user/user_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUnauthUserListSs (params) {
  const url = '/admin/samsung/user/unauth_user_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUnauthUserInfoSs (params) {
  const url = '/admin/samsung/user/unauth_user_info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getChangeInsertMetaSs (force) {
  const url = '/admin/samsung/user/change_user_id'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function changeUserSs (params) {
  const url = '/admin/samsung/user/change_user_id'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUserInsertMetaSs (force) {
  const url = '/admin/samsung/user/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function insertUserSs (params) {
  const url = '/admin/samsung/user/insert'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUserInfoSs (params) {
  const url = '/admin/samsung/user/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateUserSs (params) {
  const url = '/admin/samsung/user/update'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getUserExcelMetaSs (force) {
  const url = '/admin/samsung/user/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function setUserForceLoginSs (params) {
  const url = '/admin/samsung/user/force_login'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
