export const NODE_ENV = process.env.VUE_APP_NODE_ENV || 'development'

let home = process.env.VUE_APP_HOME_BASE_URL || 'http://localhost:3000'
if (NODE_ENV === 'staging') {
  home = 'https://staging-home.beusable.net'
} else if (NODE_ENV === 'production') {
  home = 'https://www.beusable.net'
}
export const BEUSABLE_HOME_URL = home

let mailer = process.env.VUE_APP_MAILER_BASE_URL || 'http://localhost:8092/beusable-mailer/addMailQueue'
if (NODE_ENV === 'staging' || NODE_ENV === 'production') {
  mailer = 'https://52.79.145.34/beusable-mailer/addMailQueue'
}

export const MAILER_URL = mailer

let dreamHost = process.env.VUE_APP_DREAM_BASE_URL || 'http://localhost:8540'
if (NODE_ENV === 'staging') {
  dreamHost = 'https://dream.beusable.net'
} else if (NODE_ENV === 'production') {
  dreamHost = 'https://tool.beusable.net'
}

export const BEUSABLE_URL = dreamHost

let beusablyHome = process.env.VUE_APP_BEUSABLY_HOME_BASE_URL || 'http://localhost:3302'
if (NODE_ENV === 'staging') {
  beusablyHome = 'https://staging.beusably.net'
} else if (NODE_ENV === 'production') {
  beusablyHome = 'https://www.beusably.net'
}

export const BEUSABLY_URL = beusablyHome

let renderer = process.env.VUE_APP_RENDERER_BASE_URL || 'http://localhost:8092'
if (NODE_ENV === 'production') {
  renderer = 'https://renderer-api.beusable.net'
} else if (NODE_ENV === 'staging') {
  renderer = 'https://staging-renderer-api.beusable.net'
}

export const RENDERER_API_URL = renderer

let ba = process.env.VUE_APP_ANALYTICS_URL || 'http://localhost:8550'
if (NODE_ENV === 'staging') {
  ba = 'http://staging-analytics.beusable.net/tool'
} else if (NODE_ENV === 'production') {
  ba = 'https://analytics.beusable.net/tool'
}

export const BA_URL = ba

let baHome = process.env.VUE_APP_ANALYTICS_HOME_URL || 'http://localhost:3050'
if (NODE_ENV === 'staging') {
  baHome = 'https://staging-analytics.beusable.net'
} else if (NODE_ENV === 'production') {
  baHome = 'https://analytics.beusable.net'
}

export const BA_HOME_URL = baHome

export const STORAGE_ITEM = {
  searchData: {}
}

export const STORAGE_KEY = {
  devTimestamp: 'devTimestamp'
}

export const SERVICE_NAME_BEUSABLE = 'beusable'
export const SERVICE_NAME_BA = 'ba'

export const DASHBOARD_CHART_DATA = {
  plan: 'plan',
  pv: 'pv_charge',
  change: 'plan_change',
  jam: 'jam'
}

export const DEFAULT_TIMEZONE = '+09:00'
export const DEFAULT_TIMEZONE_NAME = 'Asia/Seoul'

export const PLAN_GROUP = [
  { value: 'VOID', label: '무효플랜' },
  { value: 'TRIAL', label: '트라이얼' },
  { value: 'PAID', label: '일반' },
  { value: 'ENT', label: '엔터프라이즈' },
  { value: 'JA_PAID', label: '일본-일반' },
  { value: 'JA_ENT', label: '일본-엔터프라이즈' },
  { value: 'YR', label: '연간플랜' },
  { value: 'CUS', label: '커스텀플랜' },
  { value: 'UNUSED', label: '미사용플랜' },
  { value: 'NOGROUP', label: '그룹없음' }
]

export const POSITION_MY_JAM = 'MY_JAM'
export const POSITION_BA_APPLY_BASKET = 'BA_APPLY_BASKET'

export const JAM_TYPE_USE = 'use'
export const JAM_TYPE_CHARGE = 'charge'
export const JAM_TYPE_MOVE = 'move'
export const JAM_TYPE_EXTINCTION = 'extinction'

export const JAM_REFUND = 'JAM_REFUND'
export const JAM_CHARGE = 'JAM_CHARGE'
export const JAM_EXPIRED = 'JAM_EXPIRED'
export const MOVE_TO_BASKET = 'MOVE_TO_BASKET'
export const MOVE_TO_MY_JAM = 'MOVE_TO_MY_JAM'
export const PV_CHARGE = 'PV_CHARGE'
export const BUY_REPORT_PREFIX = 'BUY_REPORT_PREFIX'
export const BUY_REPORT_INDEPTH = 'BUY_REPORT_INDEPTH'
export const BUY_REPORT_PERIOD = 'BUY_REPORT_PERIOD'
export const CANCEL_REPORT_PREFIX = 'CANCEL_REPORT_PREFIX'
export const CANCEL_REPORT_INDEPTH = 'CANCEL_REPORT_INDEPTH'
export const CANCEL_REPORT_PERIOD = 'CANCEL_REPORT_PERIOD'
export const CANCEL_REPORT_SEGMENT = 'CANCEL_REPORT_SEGMENT'
export const CX_HEATMAP = 'CX_HEATMAP'
export const JAM_SERVICE_BA = 'journey'
export const JAM_SERVICE_COMMON = 'common'

export const PAYMENT_CARD = 'CARD'

export const JAM_USE_ERR_MSG = {
  MY_JAM_LACK: '나의 JAM 보유 수량이 부족합니다.',
  CX_HEATMAP: '실행 중인 히트맵이 있어 JAM을 이동시킬 수 없습니다.',
  BASKET_JAM_LACK: '히트맵 바구니 보유 수량이 부족합니다.',
  WRONG_CREDIT_DATE: '크레딧 날짜가 다릅니다.',
  NO_CREDIT_INFO: '크레딧 데이터가 없습니다.',
  NO_PLAN: '플랜이 없습니다.'
}

export const SERVICE_CODE_BEUSABLE = 1
export const SERVICE_CODE_SAMSUNG = 2

export const PAYMENT_STATUS_COMPLETE = "complete";
export const PAYMENT_STATUS_WAITING = "waiting";
export const PAYMENT_STATUS_UNPAID = "unpaid";