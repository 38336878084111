import { isString } from './utils'

const TEL_REGEX = /^\d{2,3}-?\d{3,4}-?\d{4}$/
const EMAIL_REGEX = /([\w-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
const URL_REGEX = /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/
const DOMAIN_REGEX = /(http|https):\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](\/*)/g
// const IP_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
const IP_SIMPLE_REGEX = /^[\x21-\x7E]*$/

export function isValidUrl (value) {
  if (!isString(value)) {
    return false
  }
  value = value.trim()
  return URL_REGEX.test(value)
}

export function isValidDomain (value) {
  if (!isString(value)) {
    return false
  }
  value = value.trim()
  const matched = value.match(DOMAIN_REGEX)
  if (matched) {
    return matched[0] === value
  } else {
    return false
  }
}

export function isValidRegex (value) {
  if (!isString(value)) {
    return false
  }
  value = value.trim()

  if (value.charAt(0) === '/' && value.charAt(value.length - 1) === '/') {
    value = value.slice(1, -1)
  }

  try {
    // eslint-disable-next-line no-unused-vars
    const regex = new RegExp(value)
  } catch (err) {
    return false
  }
  return true
}

export function isValidTel (value) {
  if (!isString(value)) {
    return false
  }
  value = value.trim()
  return TEL_REGEX.test(value)
}

export function isValidEmail (value) {
  if (!isString(value)) {
    return false
  }
  value = value.trim()
  return EMAIL_REGEX.test(value)
}

export function isValidIpAddress (value) {
  if (!isString(value)) {
    return false
  }
  value = value.trim()
  return IP_SIMPLE_REGEX.test(value)
}
