<template>
  <transition name="from-right">
    <div class="side-modal"
         :style="{ maxWidth: width + 'px' }">
      <i class="close-button xi-close"
         @click="$emit('close')"></i>
      <div class="side-modal-wrap">
        <transition name="fade">
          <slot></slot>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiSideModal',
  props: {
    width: {
      type: Number,
      default: 600
    }
  }
}
</script>

<style lang="scss" scoped>
.side-modal {
  z-index: 999999999;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: calc(100vw - 43px);
  max-width: 600px;
  overflow: auto;
  background-color: $dimlight;
  margin-left: 200px;
  border-radius: 10px 0 0 10px;
  transition: margin-left 0.3s ease;
  box-shadow: -8px 0 15px 0 rgba(0, 0, 0, 0.16);

  .close-button {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 55;
    background-color: $light;
    color: $gray;
    border-radius: 20px;
    padding: 3px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s ease,
                color 0.3s ease;

    &:hover {
      background-color: $red;
      color: $white;
    }
  }

  .fade-leave-active,
  .fade-enter-active {
    transition: opacity 0.3s ease;
    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    box-shadow: inset 0 0 10px 10px $gray;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px $gray;
  }

  &.from-right-leave-active,
  &.from-right-enter-active {
    transition: transform 0.3s ease;
    transform: translateX(0);
  }

  &.from-right-enter,
  &.from-right-leave-to {
    transform: translateX(100%);
  }
}
</style>
