import { render, staticRenderFns } from "./EventList.vue?vue&type=template&id=22b37aa1&scoped=true"
import script from "./EventList.vue?vue&type=script&lang=js"
export * from "./EventList.vue?vue&type=script&lang=js"
import style0 from "./EventList.vue?vue&type=style&index=0&id=22b37aa1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b37aa1",
  null
  
)

export default component.exports