<template>
  <div class="credit-list-wrap">
    <template-search-list :listApi="listApi"
                          :defaultSortBy="defaultSortBy"
                          :reload="reload"
                          :initialSearchFilter="initialSearchFilter"
                          @searchFilter="getSearchFilter"
    >
      <ui-button :color="'purple'"
                 @click="toggleExcel(true)">
        <i class="xi-view-list"></i> 엑셀 다운로드
      </ui-button>
    </template-search-list>

    <excel-column-select :excelDownload="excelDownload"
                         :metaApi="excelListMetaApi"
                         :listApi="listApi"
                         :searchFilter="searchFilter"
                         @export="excelExport"
                         @close="toggleExcel(false)"/>
  </div>
</template>

<script>

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import UiButton from '@/components/_ui/UiButton'

import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { EventBus } from '@/utils/event-bus'
import { getBaCreditList, getBaCreditListExcelMeta } from '@/api/credit'
import { STORAGE_ITEM } from '@/utils/constants'

export default {
  name: 'BaCreditList',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    UiButton
  },
  created () {
    // console.log('eventbus: ', EventBus.$on('searchData', this.apiParam))
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.userId) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.userId = searchData.userId
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    reloadList () {
      this.reload++
    },
    toggleExcel (value = false) {
      let excelAvailable = false
      for (const key in this.searchFilter) {
        if (key) {
          excelAvailable = true
        }
      }
      if (excelAvailable) {
        this.excelDownload = value
      } else {
        alert('검색 옵션을 넣어주세요.')
      }
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_ba_credit_list'
      const excel = new Excel()
      // EventBus.$emit('onLoading', true)
      excel.setExcelData('planBa', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    }
  },
  data () {
    return {
      reload: 0,
      apiParam: null,

      listApi: getBaCreditList,
      excelListMetaApi: getBaCreditListExcelMeta,
      defaultSortBy: '-date',
      show: {
        insert: false
      },
      excelDownload: false,
      searchFilter: {},
      initialSearchFilter: null
    }
  }
}
</script>

<style lang="scss" scoped>
.credit-list-wrap {
}
</style>
