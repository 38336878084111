import { request } from '@/utils/api-request'

export function getBeusPaymentMonitoring (params) {
  const url = '/admin/monitoring/beus/payment_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaymentMonitoring (params) {
  const url = '/admin/monitoring/ba/payment_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaidUserMonitoring (params) {
  const url = '/admin/monitoring/beus/paid_user_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaidUserMonitoring (params) {
  const url = '/admin/monitoring/ba/paid_user_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateBeusPaidUser (params) {
  const url = '/admin/monitoring/beus/update_paid_user'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateBaPaidUser (params) {
  const url = '/admin/monitoring/ba/update_paid_user'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaymentErrorMonitoring (params) {
  const url = '/admin/monitoring/beus/payment_error_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaymentErrorMonitoring (params) {
  const url = '/admin/monitoring/ba/payment_error_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function deleteBeusPaymentDateError (params) {
  const url = '/admin/monitoring/beus/delete_payment_date_error'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function deleteBaPaymentDateError (params) {
  const url = '/admin/monitoring/ba/delete_payment_date_error'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function deleteBeusPaymentLastStatusError (params) {
  const url = '/admin/monitoring/beus/delete_payment_last_status_error'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function deleteBaPaymentLastStatusError (params) {
  const url = '/admin/monitoring/ba/delete_payment_last_status_error'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getCaptureMonitoring (params) {
  const url = '/admin/monitoring/beus/capture'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateCaptureMemo(params) {
  const url = "/admin/monitoring/beus/update_capture_memo";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, true);
}

export function getEmailTemplateInfo (params) {
  const url = '/admin/monitoring/beus/mail_template'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaEmailTemplateInfo (params) {
  const url = '/admin/monitoring/ba/mail_template'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function sendMail (params) {
  const url = '/admin/monitoring/beus/send_mail'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function sendBaMail (params) {
  const url = '/admin/monitoring/ba/send_mail'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getEmailTemplate (params) {
  const url = '/admin/email/get_template'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getSamsunCodeCheckList (params) {
  const url = '/admin/samsung/code_check/list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getSamsungUserApply (params) {
  const url = '/admin/samsung/code_check/apply'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateSamsunCodeCheckMemo (params) {
  const url = '/admin/samsung/code_check/update_memo'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getMemoryList (params) {
  const url = '/admin/monitoring/beus/memory_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
