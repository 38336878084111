const state = {
  userData: null,
  devTimestamp: null
}

const getters = {
  userData: state => state.userData,
  devTimestamp: state => state.devTimestamp
}

const mutations = {
  setUserData (state, data) {
    state.userData = data
  },
  setDevTimestamp (state, payload) {
    state.devTimestamp = payload
  }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
