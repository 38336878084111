<template>
  <div class="sign-in">
    <div class="sign-in-form">
      <div class="sign-in-logo">
        <h1>
          <i class="bi-eagle-logo"><span class="a11y-invisible">EAGLE</span></i>
        </h1>
      </div>

      <ui-input :type="'text'"
                :required="true"
                :error="error.username"
                :lower="true"
                v-model.trim="model.username"
                @focus="error.username = null"
                @keyup.enter="onSignIn">
        아이디
      </ui-input>
      <ui-input type="password"
                :error="error.password"
                :required="true"
                v-model.trim="model.password"
                :menu="'signIn'"
                @focus="error.password = null"
                @keyup.enter="onSignIn">
        비밀번호
      </ui-input>
      <ui-button :color="'blue'"
                 :width="'100%'"
                 @click="onSignIn">
        로그인
      </ui-button>
    </div>
  </div>
</template>

<script>
import isobject from 'isobject'
import { signIn } from '@/api/user'
import { setAuthToken } from '@/utils/auth'
import { alertError } from '@/utils/tools'

import UiInput from '@/components/_ui/UiInput'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'SignIn',
  components: {
    UiInput,
    UiButton
  },
  data () {
    return {
      model: {
        username: '',
        password: ''
      },
      error: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    onSignIn () {
      let success = true

      if (!this.model.username.length) {
        this.error.username = '아이디는 필수입니다.'
        success = false
      }
      if (!this.model.password.length) {
        this.error.password = '비밀번호는 필수입니다.'
        success = false
      }

      if (success) {
        signIn(this.model).then(response => {
          if (response.message) {
            this.error.username = response.message
          } else {
            setAuthToken(response.result)
            this.$router.push({ name: 'Dashboard' })
          }
        }).catch(error => {
          const message = error.response ? error.response.data.message : false
          if (isobject(message)) {
            for (const [key, value] of Object.entries(message)) {
              this.$set(this.error, key, value)
            }
          } else {
            alertError('로그인에 실패했습니다.', error)
            this.model.password = ''
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .sign-in {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &::before {
      content: "";
      z-index: -2;
      position: absolute;
      top: -15px;
      left: -15px;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      background: url('~@/assets/signin-background.jpg') no-repeat center center;
      filter: blur(15px);
      background-size: cover;
    }

    &::after {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      opacity: 0.8;
    }

    .sign-in-form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $white;
      border-radius: 15px;
      padding: 20px;
      box-shadow: 0 23px 15px -12px rgba(0, 0, 0, 0.08);

      .sign-in-logo {
        padding: 210px 20px 30px;
        margin: -20px -20px 20px;
        border-radius: 15px 15px 0 0;
        background-color: $blue;

        h1 {
          color: #ffffff;
          font-weight: bold;
          font-size: 30px;
        }
      }
    }
  }
</style>
