import { render, staticRenderFns } from "./UiEditor4.vue?vue&type=template&id=3c219124&scoped=true"
import script from "./UiEditor4.vue?vue&type=script&lang=js"
export * from "./UiEditor4.vue?vue&type=script&lang=js"
import style0 from "./UiEditor4.vue?vue&type=style&index=0&id=3c219124&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c219124",
  null
  
)

export default component.exports