<template>
  <div class="sign-in-form">
    <div class="sign-in-logo">
      <h1>
        <i class="bi-eagle-logo"><span class="a11y-invisible">EAGLE</span></i>
      </h1>
    </div>

    <ui-input type="password"
              :error="error.password"
              :required="true"
              v-model.trim="model.password"
              @focus="error.password = null"
              @keyup.enter="onSignIn">
      비밀번호
    </ui-input>
    <ui-button :color="'blue'"
               :width="'100%'"
               @click="onSignIn">
      로그인
    </ui-button>
  </div>
</template>

<script>
import UiInput from '@/components/_ui/UiInput'
import UiButton from '@/components/_ui/UiButton'
import { signIn } from '@/api/user'
import { alertError } from '@/utils/tools'
import isobject from 'isobject'
import { mapGetters, mapMutations } from 'vuex'
import { addMinutes } from 'date-fns'
import { STORAGE_KEY } from '@/utils/constants'

export default {
  name: 'PasswordChecker',
  components: {
    UiInput,
    UiButton
  },
  data () {
    return {
      model: {
        password: ''
      },
      error: {
        password: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  methods: {
    ...mapMutations([
      'setDevTimestamp'
    ]),
    onSignIn () {
      let success = true

      if (!this.model.password.length) {
        this.error.password = '비밀번호는 필수입니다.'
        success = false
      }

      if (success) {
        const model = JSON.parse(JSON.stringify(this.model))
        model.username = this.userData.userId
        signIn(model).then(response => {
          if (response.message) {
            this.error.username = response.message
          } else {
            const expireTimestamp = addMinutes(new Date(), 30).getTime()
            localStorage.setItem(STORAGE_KEY.devTimestamp, expireTimestamp)
            this.setDevTimestamp(expireTimestamp)
            this.$emit('check')
          }
        }).catch(error => {
          const message = error.response ? error.response.data.message : false
          if (isobject(message)) {
            for (const [key, value] of Object.entries(message)) {
              this.$set(this.error, key, value)
            }
          } else {
            alertError('비밀번호를 다시 확인해주세요.', error)
            this.model.password = ''
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.sign-in-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 23px 15px -12px rgba(0, 0, 0, 0.08);

  .sign-in-logo {
    padding: 28px 20px 30px;
    margin: -20px -20px 20px;
    border-radius: 15px 15px 0 0;
    background-color: $blue;

    h1 {
      color: #ffffff;
      font-weight: bold;
      font-size: 30px;
    }
  }
}

</style>
