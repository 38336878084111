<template>
  <div class="ui-loading circle">
    <svg x="0px"
         y="0px"
         viewBox="0 0 150 150"
         :style="{ width: size }">
      <circle class="loading-inner" cx="75" cy="75" r="60"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'UiLoader',
  props: {
    size: {
      type: String,
      default: '70px',
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-loading {
  display: inline-block;
  z-index: 11;

  svg {
    animation: loading 3s linear infinite;

    .loading-inner {
      stroke: {
        dashoffset: 0;
        dasharray: 300;
        width: 20;
        miterlimit: 10;
        linecap: round;
      }

      animation: loading-circle 2s linear infinite;
      stroke: $blue;
      fill: transparent;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loading-circle {
    0% {
      stroke-dashoffset: 0
    }
    100% {
      stroke-dashoffset: -600;
    }
  }
}
</style>
