<template>
  <div class="email-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :isRangeToday="true"
                          :rangeTodayField="'insertedAt'"
                          :selected="selected"
                          @select="setSelected"
                          @showDetail="showDetail">
    </template-search-list>

    <email-detail :infoApi="infoApi"
                  :apiParam="apiParam"
                  @close="setSelected">

    </email-detail>
  </div>
</template>

<script>
import { getEmail, getEmailList } from '@/api/contact'

import TemplateSearchList from '@/components/_template/SearchList'
import EmailDetail from '@/components/contact/EmailDetail'
import { format } from 'date-fns'

export default {
  name: 'EmailList',
  components: {
    TemplateSearchList,
    EmailDetail
  },
  methods: {
    showDetail (data) {
      this.apiParam = { id: data._id }
    },
    setSelected (row) {
      this.selected = row
    }
  },
  created () {
    this.initialSearchFilter = {
      insertedAt: {
        start: format(new Date(), 'yyyy-MM-dd'),
        end: format(new Date(), 'yyyy-MM-dd'),
        itsEmailList: true
      }
    }
  },
  data () {
    return {
      apiParam: null,
      selected: null,
            
      infoApi: getEmail,
      listApi: getEmailList,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-insertedAt',
      initialSearchFilter: null
    }
  }
}
</script>

<style lang="scss" scoped>
.email-list-wrap {
}
</style>
