import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as ModalDialogs from 'vue-modal-dialogs'
import CKEditor from 'ckeditor4-vue'

Vue.use(ModalDialogs)
Vue.use(CKEditor)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
