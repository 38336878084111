<template>
  <div class="table-form">
    <div class="form-top">
      <slot></slot>
    </div>
    <div class="form-content">
      <table v-if="meta"
             class="form-table">
        <thead>
          <tr>
            <th class="delete"></th>
            <template v-for="(metaItem, index) in metaColumnList">
              <th v-for="[field, option] in Object.entries(metaItem)"
                  v-if="option.name && !option.hidden"
                  v-html="option.name"
                  :key="`th-${field}-${index}`"
                  :class="{ disabled: option.static }">
              </th>
            </template>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in data"
              :key="`tr-${idx}`">
            <td class="delete">
              <i class="xi-close"
                 @click="deleteRow(idx)"></i>
            </td>

            <td v-for="[field, option] in Object.entries(metaList[idx])"
                v-if="option.name && !option.hidden"
                :key="`td-${field}`"
                :class="{ disabled: option.static }">
              <ui-data v-if="option.static || option.readOnly"
                       :type="option.type"
                       :value="item[option.field || field] || option.default"
                       :option="option.option" />
              <ui-data-input v-else
                             :type="option.type"
                             :label="null"
                             :options="option.options"
                             :update="option.update"
                             :required="option.required"
                             :model="item"
                             :field="option.field || field"
                             :value="item[option.field || field] !== null ? item[option.field || field] : option.default"
                             :error="error && error[idx] ? error[idx][option.field || field] : null"
                             @set="sendData(idx, ...$event)"
                             @error="sendError(idx, ...$event)"
                             @input="input(idx, (option.field || field), $event)" />
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import UiData from '@/components/_ui/UiData'
import UiDataInput from '@/components/_ui/UiDataInput'

export default {
  name: 'UiTableForm',
  components: {
    UiData,
    UiDataInput
  },
  props: {
    meta: {
      type: Array,
      default: () => {
        return []
      },
      required: false,
      deep: true
    },
    data: {
      type: Array,
      default: () => {
        return []
      },
      required: false
    },
    error: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    }
  },
  watch: {
    meta: {
      handler () {
        this.metaListChange()
      }
    }
  },
  methods: {
    sendData (idx, data) {
      this.$emit('set', idx, data)
    },
    sendError (idx, key, message) {
      this.$emit('error', idx, key, message)
    },
    deleteRow (idx) {
      if (confirm('정말로 삭제하시겠습니까?')) {
        this.$emit('delete', idx)
      }
    },
    metaListChange () {
      if (this.metaList.length > 0) {
        this.metaListExist = true
      } else {
        this.metaColumnList = JSON.parse(JSON.stringify(this.meta))
      }
      this.metaList = JSON.parse(JSON.stringify(this.meta))
    },
    input (idx, field, value) {
      if (field === 'timezoneName' && value) {
        this.meta[idx].startDate.static = false
        this.meta[idx].endDate.static = false
        this.metaListChange()
      } else if (field === 'url' && value !== this.data[idx].url) {
        this.meta[idx].startDate.static = true
        this.meta[idx].endDate.static = true
        this.metaListChange()
      }
      this.$emit('input', idx, field, value)
    }
  },
  data () {
    return {
      metaList: [],
      metaColumnList: [],
      metaListExist: false
    }
  },
  created () {
    this.metaListChange()
  }
}
</script>

<style lang="scss" scoped>
.table-form {
  .form-top {
    height: 31.64px;
    padding: 20px;
    text-align: right;

    & :deep {
      .title {
        float: left;
        font-size: 20px;
        padding: 5px 0 5px 0;
        font-weight: bold;

        i {
          margin: 1px 5px 0 0;
        }
      }
    }
  }

  .form-content {
    padding: 0 15px 19px;
    height: calc(100vh - 91.64px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      box-shadow: inset 0 0 10px 10px $gray;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $gray;
    }

    .form-table {
      width: 100%;
      table-layout: fixed;

      th {
        padding: 5px 10px;
        text-align: left;
        width: 120px;
        border-bottom: 1px solid $light;
        transition: background-color 0.3s ease;
        overflow: hidden;
        text-overflow: ellipsis;

        &.delete {
          width: 1px;
        }
      }

      td {
        padding: 15px 10px 0;
        line-height: 1.4;
        border-bottom: 1px solid $light;
        background: $white;
        height: 1.4em;
        transition: background-color 0.3s ease;

        .type1image {
          display: block;
          margin: -11px -10px -10px;
          background: $dimlight;

          img {
            margin-bottom: -4px;
          }
        }

        .type2code {
          display: block;
          margin: -10px;
        }

        &.delete {
          padding-top: 0;
        }

        &.delete {
          color: $red;

          i {
            transition: opacity 0.2s ease;
            opacity: 0;
            cursor: pointer;
          }
        }
      }

      tr.disabled {
        th,
        td {
          opacity: 0.5;
          background:transparent;
        }

        td {
          padding: 10px;
        }
      }

      tr:not(.inner):not(.disabled):hover {
        th,
        td {
          background-color: $dim;
        }

        td.delete i {
          opacity: 1;
        }
      }

      thead {
        th {
          padding: 15px;
          border-bottom: none;
          background-color: $purple;
          color: $white;
          font-weight: bold;

          &:first-child {
            border-radius: 10px 0 0 10px;
          }

          &:last-child {
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
  }
}
</style>
