<template>
  <span v-if="computedValue !== null || value === null"
        class="ui-data-input"
        :class="[type1 ? `type1${type1}` : null,
                 type2 ? `type2${type2}` : null,
                 type3 ? `type3${type3}` : null]">
    <template v-if="type3 === 'range'">
        <ui-data-input class="range-start"
                       :type="type.replace('|range', '')"
                       :label="label"
                       :options="options"
                       :update="update"
                       :required="required"
                       :disabled="disabled"
                       :itsEmailList="itsEmailList"
                       :field="field"
                       :value.sync="computedValue.start"
                       @enter="$emit('enter')"
                       @input="setRangeValue('start', $event)"
                       @update="$emit('update', $event)"
                       @focus="$emit('focus')" />
        <ui-data-input class="range-end"
                       :type="type.replace('|range', '')"
                       :label="'~'"
                       :options="options"
                       :update="update"
                       :error="error"
                       :required="required"
                       :disabled="disabled"
                       :itsEmailList="itsEmailList"
                       :field="field"
                       :value.sync="computedValue.end"
                       @enter="$emit('enter')"
                       @input="setRangeValue('end', $event)"
                       @update="$emit('update', $event)"
                       @focus="$emit('focus')" />
    </template>
    <template v-else>
      <template v-if="type1 === 'selectcheck'">
        <ui-select-check :error="error"
                     :value="computedValue"
                     :options="computedOptions"
                     @input="setValue"
                     @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-select-check>
      </template>
      <template v-else-if="options">
        <ui-dropdown-multiple v-if="type3 === 'multiple'"
                              :required="required"
                              :options="computedOptions"
                              :error="error"
                              :value="computedValue"
                              :model="model"
                              :type="type4"
                              :type2="type2"
                              @input="setValueMultiple">
          <span v-if="label"
                v-html="label"></span>
        </ui-dropdown-multiple>
        <ui-dropdown v-else
                     :required="required"
                     :options="computedOptions"
                     :error="error"
                     :value="computedValue"
                     :model="model"
                     :type="type4"
                     @input="setValue"
                     @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-dropdown>
      </template>
      <template v-else-if="type1 === 'text'">
        <ui-input :type="type2 === 'password' ? type2 : 'text'"
                  :required="required"
                  :error="error"
                  :value="computedValue"
                  :minLength="minLength"
                  :cancelMode="cancelMode"
                  :field="field"
                  :lower="['email', 'user', 'notexistuser'].includes(type2)"
                  @enter="$emit('enter')"
                  @input="setValue"
                  @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
          <ui-button v-if="type2 === 'user'"
                     @click="searchUser">확인</ui-button>
          <ui-button v-if="type2 === 'paymentId'"
                     @click="searchPaymentId">확인</ui-button>
          <ui-button v-if="type2 === 'notexistuser'"
                     @click="searchNotExistUser">확인</ui-button>
          <ui-button v-if="type2 === 'tzurl'"
                     @click="validateUrlTimezone">확인</ui-button>
          <template v-if="type2 === 'imageupload'">
            <input type="file"
                   class="a11y-invisible"
                   ref="file"
                   accept="image/*"
                   @change="imageRead">
            <ui-button @click="imageUpload">업로드</ui-button>
          </template>
          <template v-if="type2 === 's3upload'">
            <input type="file"
                   class="a11y-invisible"
                   ref="file"
                   accept="image/*"
                   @change="s3Read">
            <ui-button @click="imageUpload">업로드</ui-button>
          </template>

        </ui-input>
      </template>
      <template v-else-if="type1 === 'textarea'">
        <ui-textarea :type="'text'"
                  :required="required"
                  :error="error"
                  :value="computedValue"
                  :minLength="minLength"
                  @enter="$emit('enter')"
                  @input="setValue"
                  @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-textarea>
      </template>
      <template v-else-if="type1 === 'datetime'">
        <template v-if="type3 === 'apply' && field === 'startDate'">
          <ui-input :type="type3"
                    :value="computedStartDateStr"
                    @input="setValue"
                    @clicked="toggleDatePicker(field)"/>
          <div v-if="showStartDatePicker && type4 === 'bulk'" class="datepicker-bulk--wrap data__start-date">
            <date-picker v-if="showStartDatePicker"
              :field="field"
              :type="type4"
              :value="this.model.startDate ? this.model.startDate : newDateStr"
              :minDate="minStartDate"
              :maxDate="maxStartDate"
              :timezone="this.model.timezone"
              :timezoneName="this.model.timezoneName"
              @input="onCalendarDateChange('startDate', $event)"
              @onCloseClick="showStartDatePicker = false"/>
          </div>
          <div v-else class="datepicker--wrap data__start-date">
            <date-picker v-if="showStartDatePicker"
              :field="field"
              :type="type4"
              :value="this.model.startDate ? this.model.startDate : newDateStr"
              :minDate="minStartDate"
              :maxDate="maxStartDate"
              :timezone="this.model.timezone"
              :timezoneName="this.model.timezoneName"
              @input="onCalendarDateChange('startDate', $event)"
              @onCloseClick="showStartDatePicker = false"/>
          </div>
        </template>
        <template v-else-if="type3 === 'apply' && (field === 'endDate')">
          <ui-input :type="type3"
                    :value="computedEndDateStr"
                    @input="setValue"
                    @clicked="toggleDatePicker(field)"/>
          <div v-if="showEndDatePicker && type4 === 'bulk'" class="datepicker-bulk--wrap data__end-date">
            <date-picker v-if="showEndDatePicker"
              :field="field"
              :type="type4"
              :value="endDateVal"
              :minDate="minEndDate"
              :timezone="this.model.timezone"
              :timezoneName="this.model.timezoneName"
              @input="onCalendarDateChange('endDate', $event)"
              @onCloseClick="showEndDatePicker = false"/>
          </div>
          <div v-else class="datepicker--wrap data__end-date">
            <date-picker v-if="showEndDatePicker"
              :field="field"
              :type="type4"
              :value="endDateVal"
              :minDate="minEndDate"
              :timezone="this.model.timezone"
              :timezoneName="this.model.timezoneName"
              @input="onCalendarDateChange('endDate', $event)"
              @onCloseClick="showEndDatePicker = false"/>
          </div>

        </template>
        <template v-else>
          <ui-input :type="type2 === 'date' ? 'date' : 'datetime-local'"
                    :required="required"
                    :error="error"
                    :value.sync="computedValue"
                    :itsEmailList="itsEmailList"
                    @enter="$emit('enter')"
                    @input="setValue"
                    @focus="$emit('focus')">
            <span v-if="label"
                  v-html="label"></span>
          </ui-input>
        </template>
      </template>
      <template v-else-if="type1 === 'number'">
        <ui-input :type="'number'"
                  :required="required"
                  :error="error"
                  :value="computedValue"
                  :minLength="minLength"
                  @enter="$emit('enter')"
                  @input="setValue"
                  @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-input>
      </template>
      <template v-else-if="type1 === 'boolean'">
        <ui-checkbox :error="error"
                     :value="computedValue"
                     @input="setValue"
                     @focus="$emit('focus')">
          <span v-if="label"
                v-html="label"></span>
        </ui-checkbox>
      </template>
      <template v-else-if="type1 === 'editor4'">
        <ui-editor-4 :value="computedValue"
                     @input="setValue" />
      </template>
      <template v-else-if="type1 === 'editor'">
        <ui-editor-5 :value="computedValue"
                     @input="setValue" />
      </template>
      <template v-else-if="type1 === 'html'">
        <ui-editor-4 :value="computedValue"
                     :readonly="disabled"
                     @input="setValue" />
      </template>
    </template>
  </span>
</template>

<script>
import isobject from 'isobject'
import { mapGetters } from 'vuex'
import { format, parse, addDays, startOfDay, endOfDay, parseISO } from 'date-fns'
import { alertError, isEndWithConsonant } from '@/utils/tools'

import { searchNotExistUser, searchUser } from '@/api/user'
import { getBaPaymentInfo, getBeusPaymentInfo } from '@/api/plan'
import { getPopupTemplateList } from '@/api/marketing'
import { validateUrlTimezone } from '@/api/beusService'

import UiDataInput from '@/components/_ui/UiDataInput'
import UiInput from '@/components/_ui/UiInput'
import UiButton from '@/components/_ui/UiButton'
import UiDropdown from '@/components/_ui/UiDropdown'
import UiDropdownMultiple from '@/components/_ui/UiDropdownMultiple'
import UiCheckbox from '@/components/_ui/UiCheckbox'
import DatePicker from '@/components/_ui/date-picker'
import UiSelectCheck from '@/components/_ui/UiSelectCheck'

import UiEditor4 from '@/components/_ui/UiEditor4'
import UiEditor5 from '@/components/_ui/UiEditor5'
import UiTextarea from '@/components/_ui/UiTextarea'
import { EventBus } from '@/utils/event-bus'
import { triggerClickEvent, utcToLocalTimezone } from '@/libs/utils'

export default {
  name: 'UiDataInput',
  components: {
    UiTextarea,
    UiDataInput,
    UiInput,
    UiButton,
    UiDropdown,
    UiDropdownMultiple,
    UiCheckbox,
    UiSelectCheck,
    UiEditor4,
    UiEditor5,
    DatePicker
  },
  props: {
    type: {
      type: String,
      default: 'text',
      required: false
    },
    field: {
      type: String,
      required: false
    },
    defaultValue: {
      required: false
    },
    model: {
      required: false
    },
    value: {
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    update: {
      type: String,
      required: false
    },
    options: {
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    list: {
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    error: {
      type: String,
      default: '',
      required: false
    },
    minLength: {
      type: Number,
      default: -1
    },
    isRangeToday: {
      required: false,
      default: false
    },
    cancelMode: {
      type: Boolean,
      required: false,
      default: false
    },
    itsEmailList: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters([
      'beusPlanData',
      'baPlanData',
      'beusPlanDataByGroup',
      'baPlanDataByGroup'
    ]),
    computedStartDateStr () {
      if (this.model.startDate) {
        return this.model.startDate.split(' ')[0]
      } else {
        return this.model.startDate
      }
    },
    computedEndDateStr () {
      if (this.model.endDate) {
        return this.model.endDate.split(' ')[0]
      } else {
        return this.model.endDate
      }
    },
    minStartDate () {
      return utcToLocalTimezone(new Date(), this.model.timezoneName)
    },
    newDateStr () {
      return format(utcToLocalTimezone(new Date(), this.model.timezoneName), 'yyyy-MM-dd')
    },
    maxStartDate () {
      if (this.model.endDate) {
        return parseISO(this.model.endDate)
      } else {
        return null
      }
    },
    minEndDate () {
      const now = utcToLocalTimezone(new Date(), this.model.timezoneName)
      if (!this.model.startDate) {
        return now
      } else {
        return parseISO(this.model.startDate)
      }
    },
    endDateVal () {
      if (this.model.endDate) {
        return this.model.endDate
      } else {
        return this.getMinEnd()
      }
    },
    splitedType () {
      return this.type.split('|')
    },
    type1 () {
      return this.splitedType[0] || null
    },
    type2 () {
      return this.splitedType[1] || null
    },
    type3 () {
      return this.splitedType[2] || null
    },
    type4 () {
      return this.splitedType[3] || null
    },
    computedOptions () {
      if (this.options === 'beusPlanList') {
        if (this.type4 === 'group') {
          const filteredPlanDataByGroup = this.beusPlanDataByGroup.filter(plan => {
            if (this.type3 !== 'noTrial') {
              return plan.children && plan.children.length > 0
            } else {
              return plan.children && plan.children.length > 0 && plan.value !== 'VOID' && plan.value !== 'TRIAL'
            }
          })
          return filteredPlanDataByGroup.map(group => {
            for (let i in group.children) {
              if (group.children[i].name === 'Coupon') {
                group.children[i].disabled = true
              }
            }
            return group 
          })
        } else {
          return Object.values(this.beusPlanData).filter(plan => {
            return (this.type3 !== 'noTrial' || plan.planName !== 'Trial')
          }).map(plan => {
            let name = plan.planName
            if (plan.bFree) {
              name += ' [Free]'
            }
            if (plan.pvLimit) {
              name += ` (${plan.pvLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} PV)`
            }
            return {
              name: name,
              value: plan.planNumber
            }
          })
        }
      } else if (this.options === 'baPlanList') {
        if (this.type4 === 'group') {
          return this.baPlanDataByGroup.filter(plan => {
            if (this.type3 !== 'noTrial') {
              return plan.children && plan.children.length > 0
            } else {
              return plan.children && plan.children.length > 0 && plan.value !== 'VOID' && plan.value !== 'TRIAL'
            }
          })
        } else {
          return Object.values(this.baPlanData).filter(plan => {
            return (this.type3 !== 'noTrial' || plan.planName !== 'Trial')
          }).map(plan => {
            let name = plan.planName
            if (plan.bFree) {
              name += ' [Free]'
            }
            if (plan.pvLimit) {
              name += ` (${plan.pvLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} PV)`
            }
            return {
              name: name,
              value: plan.planNumber
            }
          })
        }
      } else if (this.options === 'popupTemplateList') {
        return this.ayncList
      } else {
        return this.options
      }
    }
  },
  watch: {
    value () {
      this.setComputedValue()
    },
    computedValue (value) {
      if ((this.type2 === 'user' || this.type2 === 'notexistuser' || this.type2 === 'tzurl') && this.searched !== this.computedValue) {
        this.$emit('error', this.field, '확인 버튼을 눌러 확인하세요.')
      }

      if (this.type1 === 'number' && this.type3 === 'limit') {
        if (value > Number(this.type4)) {
          this.$emit('error', this.field, this.type4 + '까지 입력이 가능합니다.')
        }
      }

      if (this.minLength > -1) {
        const valueLength = value.length
        if (valueLength < this.minLength) {
          this.$emit('error', this.field, this.minLength + '이상 입력해주세요.')
        }
      }
    }
  },
  methods: {
    getMinEndDate () {
      const now = utcToLocalTimezone(new Date(), this.model.timezoneName)
      if (!this.model.startDate) {
        return now
      } else {
        return parseISO(this.model.startDate)
      }
    },
    getMinEnd () {
      let minDateVal = this.getMinEndDate()
      return format(minDateVal, 'yyyy-MM-dd')
    },
    getDateStrFormat (dateType, value, timezoneName) {
      let dateValue = null

      if (value) {
        const now = utcToLocalTimezone(new Date(), timezoneName)

        if (dateType === 'startDate') {
          dateValue = addDays(startOfDay(now), 1)
        } else {
          if (this.model.startDate && parse(this.model.startDate, null, null) > parse(now, null, null)) {
            dateValue = endOfDay(addDays(this.computedStartDateStr, 6))
          } else {
            dateValue = endOfDay(addDays(now, 6))
          }
        }
        dateValue = format(dateValue, 'yyyy-MM-dd HH:mm:ss')
      }
      return dateValue
    },
    toggleDatePicker (type) {
      switch (type) {
        case 'startDate':
          if (!this.showStartDatePicker) {
            triggerClickEvent()
          }
          this.showStartDatePicker = !this.showStartDatePicker
          break
        case 'endDate':
          if (!this.showEndDatePicker) {
            triggerClickEvent()
          }
          this.showEndDatePicker = !this.showEndDatePicker
          break
      }
    },
    onCalendarDateChange (dateType, value) {
      this.setValue(value)
    },
    getTemplateList () {
      getPopupTemplateList({
        serviceType: this.$route.meta.ba ? 'ba' : 'beusable',
        status: 'publish'
      }).then(response => {
        this.ayncList = [{
          value: '',
          name: '없음',
          content: ''
        }]
        for (const item of response.result) {
          this.ayncList.push({
            value: item._id,
            name: item.title,
            content: item.content
          })
        }
      })
    },

    computeValue (result) {
      if (result === 0 || result === false || result) {
        if (this.type1 === 'text') {
          result = String(result)
          if (this.type3 === 'urlEncode') {
            result = decodeURI(result)
          } else if (this.type3 === 'json') {
            result = JSON.stringify(result)
          }
        } else if (this.type1 === 'number') {
          if (this.type3 !== 'multiple') {
            result = Number(result)
          }
        } else if (this.type1 === 'datetime') {
          const date = new Date(result)
          if (this.type2 === 'date') {
            result = format(date, 'yyyy-MM-dd')
          } else if (this.type2 === 'UTC+9') {
            result = format(date, 'yyyy-MM-dd HH:mm:ss')
          } else {
            result = format(date, 'yyyy-MM-dd HH:mm')
          }
        }
      }
      return result
    },

    setComputedValue () {
      if (this.type3 !== 'range') {
        this.computedValue = this.computeValue(this.value)
      } else {
        this.computedValue = {}
        if (this.value) {
          if (this.isRangeToday) {
            this.computedValue = {
              start: this.computeValue(this.value.start),
              end: this.computeValue(this.value.end)
            }
          }

          if (this.value.start || this.value.start === 0) {
            this.computedValue.start = this.computeValue(this.value.start)
          }
          if (this.value.end || this.value.end === 0) {
            this.computedValue.end = this.computeValue(this.value.end)
          }
        }
      }
    },

    filterValue (value) {
      if (value === 0 || value === false || value) {
        if (this.type1 === 'text') {
          value = String(value)
          if (this.type3 === 'urlEncode') {
            value = encodeURI(value)
          } else if (this.type3 === 'json') {
            value = JSON.parse(this.value)
          }
        } else if (this.type1 === 'number') {
          if (this.type3 !== 'multiple') {
            value = Number(value)
          }
          if (this.field === 'chargeJam' && value === 0) {
            value = 1
          }
        } else if (this.type1 === 'boolean') {
          if (String(value).toLowerCase() === 'false') {
            value = false
          }
          value = Boolean(value)
        } else if (this.type1 === 'datetime') {
          if (this.type2 !== 'date') {

          }
        }
      }
      return value
    },

    setValue (value) {
      this.computedValue = this.filterValue(value)
      this.$emit('input', this.computedValue)
      if (this.update) {
        let otherValue = this.computedValue

        if (this.computedOptions) {
          for (const item of this.computedOptions) {
            if (item.content && value === item.value) {
              otherValue = item.content
              break
            }
          }
        }
        this.$emit('update', this.update, otherValue)
      }
    },

    setValueMultiple (value) {
      this.computedValue = this.filterValue(value)
      if (this.computedValue.length > 0) {
        this.$emit('input', this.computedValue)
      } else {
        this.$emit('input', null)
      }

      if (this.update) {
        let otherValue = this.computedValue

        if (this.computedOptions) {
          for (const item of this.computedOptions) {
            if (item.content && value === item.value) {
              otherValue = item.content
              break
            }
          }
        }
        this.$emit('update', this.update, otherValue)
      }
    },

    setRangeValue (key, value) {
      if (!isobject(this.computedValue)) {
        this.computedValue = {}
      }
      this.$set(this.computedValue, key, this.filterValue(value))
      this.$emit('input', this.computedValue)
    },
    searchPaymentId () {
      if (this.computedValue) {
        if (this.$route.meta.ba ? 'ba' : 'beusable' === 'ba') {
          getBaPaymentInfo({
            id: this.computedValue
          }).then(response => {
            const paymentData = response.result.info
            let updateData = {
              'paymentIdFind': true,
              'baPlanNumber': paymentData.planNumber
            }
            this.$emit('set', updateData)
            this.searched = this.computedValue
            this.$emit('error', this.field, '')
          }).catch(error => {
            const message = error.response ? error.response.data.message : false
            if (isobject(message)) {
              for (const value of Object.values(message)) {
                this.$emit('error', this.field, value)
                this.$emit('set', { })
              }
            } else {
              alertError('플랜 정보를 불러오지 못 했습니다.', error)
            }
          })
        } else {
          getBeusPaymentInfo({
            id: this.computedValue
          }).then(response => {
            const paymentData = response.result.info
            let updateData = {
              'paymentIdFind': true,
              'beusPlanNumber': paymentData.planNumber
            }
            this.$emit('set', updateData)
            this.searched = this.computedValue
            this.$emit('error', this.field, '')
          }).catch(error => {
            const message = error.response ? error.response.data.message : false
            if (isobject(message)) {
              for (const value of Object.values(message)) {
                this.$emit('error', this.field, value)
                this.$emit('set', { })
              }
            } else {
              alertError('플랜 정보를 불러오지 못 했습니다.', error)
            }
          })
        }
      }
    },
    searchUser () {
      if (this.computedValue) {
        let service = this.$route.meta.ba ? 'ba' : 'beusable'
        service = this.$route.meta.jam ? 'ba' : service
        searchUser({
          userId: this.computedValue,
          type3: this.type3,
          serviceType: service
        }).then(response => {
          const userData = response.result.info
          this.$emit('set', userData)
          this.searched = this.computedValue
          this.$emit('error', this.field, '')
        }).catch(error => {
          const message = error.response ? error.response.data.message : false
          if (isobject(message)) {
            for (const value of Object.values(message)) {
              this.$emit('error', this.field, value)
              this.$emit('set', { })
            }
          } else {
            alertError('계정 정보를 불러오지 못 했습니다.', error)
          }
        })
      }
    },
    searchNotExistUser () {
      if (this.computedValue) {
        searchNotExistUser({
          userId: this.computedValue,
          type3: this.type3
        }).then(response => {
          this.$emit('set', this.field, this.computedValue)
          this.searched = this.computedValue
          this.$emit('error', this.field, '')
        }).catch(error => {
          const message = error.response ? error.response.data.message : false
          if (isobject(message)) {
            for (const value of Object.values(message)) {
              this.$emit('error', this.field, value)
            }
          } else {
            alertError('계정 정보를 불러오지 못 했습니다.', error)
          }
        })
      }
    },
    validateUrlTimezone () {
      if (this.computedValue && this.model.userId) {
        validateUrlTimezone({
          userId: this.model.userId,
          url: this.computedValue
        }).then(response => {
          this.$emit('error', this.field, null)
          this.searched = this.computedValue
          this.$emit('set', { timezoneName: response.result.timezoneName, timezone: response.result.timezone })
        }).catch(error => {
          const message = error.response ? error.response.data.message : false
          if (isobject(message)) {
            for (const value of Object.values(message)) {
              this.$emit('error', this.field, value)
            }
          } else {
            alertError('계정 정보를 불러오지 못 했습니다.', error)
          }
        })
      } else {
        if (!this.computedValue) {
          this.$emit('error', this.field, `${this.field}${isEndWithConsonant(this.field) ? '은' : '는'} 필수입니다.`)
        }
        if (!this.model.userId) {
          this.$emit('error', 'userId', `회원 아이디${isEndWithConsonant('회원 아이디') ? '은' : '는'} 필수입니다.`)
        }
      }
    },
    imageUpload () {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    imageRead (event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const base64 = btoa(e.target.result)
          this.setValue(`data:${file.type};base64,${base64}`)
        }
        reader.readAsBinaryString(file)
      }
    },
    s3Read (event) {
      const file = event.target.files[0]
      if (file) {
        EventBus.$emit('onLoading', true)
        const reader = new FileReader()
        reader.onload = (e) => {
          const base64 = btoa(e.target.result)
          this.$emit('update', 'fileName', file.name)
          this.$emit('update', 'imagePath', file.name)
          this.$emit('update', 'file', `${base64}`)
          this.$emit('update', 'type', file.type)
        }
        reader.readAsBinaryString(file)
        EventBus.$emit('onLoading', false)
      }
    }
  },
  created () {
    this.setComputedValue()
    this.$emit('input', this.computedValue)
    if ((this.type2 === 'user' || this.type2 === 'notexistuser' || this.type2 === 'tzurl') && !this.value) {
      this.searched = false
      this.$emit('error', this.field, '확인 버튼을 눌러 확인하세요.')
    }
    if (this.options === 'popupTemplateList') {
      this.getTemplateList()
    }
  },
  data () {
    return {
      computedValue: null,
      searched: true,
      ayncList: [],
      userData: null,
      showStartDatePicker: false,
      showEndDatePicker: false,
      disableStartDate: false,
      disableEndDate: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-data-input {
  &.type3range {
    .range-start::v-deep {
      .ui-input {
        padding-bottom: 0;
        .ui-input-container {
          border-bottom: none;
        }
      }
    }

    .range-end::v-deep {
      .ui-input-label {
        text-align: right;
      }
    }

    .range-end {

    }
  }
}
</style>
