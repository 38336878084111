<template>
  <div class="date_picker" @click.stop.prevent :style="getStyle">
    <div class="date_picker_content">
      <div class="date_picker_timezone--wrap">
        <div class="date_picker_timezone">
          <p class="value">UTC {{timezone}}</p>
        </div>
      </div>
      <div class="calendar clearfix">
        {{ disabled }}
        <month-calendar v-if="computedMonthStr"
                        :monthStr="computedMonthStr"
                        :targetDate="computedTargetDate"
                        :minDate="minDate"
                        :maxDate="maxDate"
                        :disabled="disabled"
                        :timezone="timezone"
                        :timezoneName="timezoneName"
                        @onMonthAdd="onMonthAdd"
                        @onDateClick="onDateClick"/>
      </div>
    </div>
    <div v-if="isFromNow !== undefined" class="date_picker_option">
      <p>
        <ui-checkbox :value="fromNow"
          @input="onIsFromNowChange($event)">{{ $t('common.instantlyStart') }}</ui-checkbox>
      </p>
    </div>
    <div v-if="isEndless !== undefined" class="date_picker_option">
      <p>
        <ui-checkbox :value="endless"
          @input="onIsEndlessChange($event)">{{ $t('common.nonStop') }}</ui-checkbox>
      </p>
    </div>
    <slot name="calendarOptions"></slot>
    <button type="button" class="btn-close"
      @click="$emit('onCloseClick')">
      <i class="icon icon--close-btn"></i>
    </button>
  </div>
</template>

<script>
import { parse, format, addMonths, addDays, parseISO } from 'date-fns'
import MonthCalendar from './MonthCalendar'
import UiCheckbox from '@/components/_ui/UiCheckbox'
import { utcToLocalTimezone } from '@/libs/utils'

export default {
  name: 'DatePicker',
  components: {
    MonthCalendar,
    UiCheckbox
  },
  props: {
    field: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    timezone: {
      type: String,
      required: false,
      default: '+09:00'
    },
    timezoneName: {
      type: String,
      required: false,
      default: 'Asia/Seoul'
    },
    value: {
      type: String,
      required: false
    },
    minDate: {
      type: Date,
      required: false
    },
    maxDate: {
      type: Date,
      required: false
    },
    isFromNow: {
      required: false
    },
    isEndless: {
      required: false
    }
  },
  data () {
    return {
      monthStr: null,
      targetDate: null,
      fromNow: this.isFromNow,
      endless: this.isEndless
    }
  },
  computed: {
    getStyle () {
      if (this.type) {
        return { transform: 'translateX(-80%) translateY(-5%)' }
      } else {
        return { transform: 'translateX(-60%) translateY(-120%)' }
      }
    },
    computedTargetDate () {
      if (this.fromNow) {
        return utcToLocalTimezone(new Date(), this.timezoneName)
      } else if (this.endless) {
        return null
      } else {
        return this.targetDate
      }
    },
    computedMonthStr () {
      if (this.fromNow || this.endless) {
        return format(new Date(), 'yyyy-MM-dd')
      }
      return this.monthStr
    },
    disabled () {
      return this.fromNow || this.endless
    }
  },
  created () {
    let date

    if (this.value) {
      date = parseISO(this.value)
      this.targetDate = date
    } else {
      date = utcToLocalTimezone(new Date(), this.timezoneName)
    }

    this.monthStr = format(date, 'yyyy-MM')
    this.closeFn = () => {
      this.$emit('onCloseClick')
    }
    document.body.addEventListener('click', this.closeFn, false)
  },
  methods: {
    onMonthAdd (months) {
      let monthDate = parseISO(this.monthStr)
      monthDate = addMonths(monthDate, months)
      this.monthStr = format(monthDate, 'yyyy-MM-dd')
    },
    onDateClick (type, date) {
      this.$emit('input', format(date, 'yyyy-MM-dd'))
      this.$emit('onCloseClick')
    },
    onIsFromNowChange (value) {
      this.fromNow = value

      if (value) {
        this.$emit('input', null)
        this.$emit('onCloseClick')
      } else {
        this.targetDate = parse(this.minDate)
      }
    },
    onIsEndlessChange (value) {
      this.endless = value

      if (value) {
        this.$emit('input', null)
        this.$emit('onCloseClick')
      } else {
        this.targetDate = parse(addDays(this.minDate, 6))
      }
    }
  },
  destroyed () {
    document.body.removeEventListener('click', this.closeFn)
  }
}
</script>

<style lang="scss" scoped="scoped">
</style>
