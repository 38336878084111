import { request } from '@/utils/api-request'

export function getBeusApplyList (params) {
  const url = '/admin/beus/abpage/list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyInfo (params) {
  const url = '/admin/beus/abpage/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyExcelMeta (force) {
  const url = '/admin/beus/abpage/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}
