<template>
  <div class="monitoring-wrap">
    <template-search-read :readApi="readApi"
                          :reload="reload"
                          @searchFilter="getSearchFilter">
    </template-search-read>
  </div>
</template>

<script>
import { getBaPaymentMonitoring } from '@/api/monitoring'

import TemplateSearchRead from '@/components/_template/SearchRead'

export default {
  name: 'MonitoringBaPayment',
  components: {
    TemplateSearchRead
  },
  methods: {
    getSearchFilter (value) {
      this.searchFilter = value
    }
  },
  data () {
    return {
      reload: 0,
      readApi: getBaPaymentMonitoring,
      searchFilter: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.monitoring-wrap {
}
</style>
