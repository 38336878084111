import { request } from '@/utils/api-request'

export function getBaPaymentHistoryList (params) {
  const url = '/admin/ba/payment/history_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaymentHistoryList (params) {
  const url = '/admin/beus/payment/history_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getJamPaymentHistoryList (params) {
  const url = '/admin/jam/payment_history/list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaymentHistoryInsertMeta (params) {
  const url = '/admin/ba/payment/make'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function insertBaPayment (params) {
  const url = '/admin/ba/payment/make_payment'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaymentHistoryInsertMeta (params) {
  const url = '/admin/beus/payment/make'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function getJamPaymentHistoryInsertMeta (params) {
  const url = '/admin/jam/payment_history/make'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function insertJamPayment (params) {
  const url = '/admin/jam/payment_history/make_payment'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function insertBeusPayment (params) {
  const url = '/admin/beus/payment/make_payment'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaymentHistoryInfo (params) {
  const url = '/admin/beus/payment/history/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaymentHistoryInfo (params) {
  const url = '/admin/ba/payment/history/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaymentHistoryExcelMeta (force) {
  const url = '/admin/beus/payment/history/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getJamPaymentHistoryExcelMeta (force) {
  const url = '/admin/jam/payment_history/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getJamPaymentHistoryInfo (params) {
  const url = '/admin/jam/payment_history/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function editJamPaymentHistoryInfo (params) {
  const url = '/admin/jam/payment_history/update'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaymentHistoryExcelMeta (force) {
  const url = '/admin/ba/payment/history/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function cancelBaPaymentHistory (params) {
  const url = '/admin/ba/payment/cancel_payment'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function cancelJamPaymentHistory (params) {
  const url = '/admin/jam/payment_history/cancel_payment'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function cancelBeusPaymentHistory (params) {
  const url = '/admin/beus/payment/cancel_payment'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getPaymentCancelMeta () {
  const url = '/admin/ba/payment/cancel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function getJamPaymentCancelMeta () {
  const url = '/admin/jam/payment_history/cancel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function getJamUseHistoryList (params) {
  const url = '/admin/jam/use_history/list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getJamUseHistoryExcelMeta (force) {
  const url = '/admin/jam/use_history/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getJamUseHistoryInfo (params) {
  const url = '/admin/jam/use_history/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function editJamUseHistoryInfo (params) {
  const url = '/admin/jam/use_history/update'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getJamUseHistoryMoveMeta (params) {
  const url = '/admin/jam/use_history/move_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function insertJamMove (params) {
  const url = '/admin/jam/use_history/move'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
