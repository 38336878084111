<template>
  <div class="form">
    <div class="form-top">
      <slot></slot>
    </div>
    <div class="form-content">
      <table v-if="meta"
             class="form-table">
        <colgroup>
          <col width="140">
          <col>
        </colgroup>
        <template v-for="[field, option] in Object.entries(meta)">
          <thead v-if="option.title"
                 :key="`form-${field}`">
            <tr>
              <td colspan="2"></td>
            </tr>
            <tr>
              <th colspan="2"
                  v-html="option.name"></th>
            </tr>
          </thead>
          <template v-else>
            <tr v-if="!option.hidden"
                :key="`form-${field}`"
                :class="{ disabled: option.static }">
              <th v-if="option.name"
                  v-html="option.name"></th>
              <td :colspan="option.name ? null : 2">
                <ui-data v-if="option.static || option.readOnly"
                         :type="option.type"
                         :value="data[option.field || field] || option.default"
                         :listData="option.options"
                         :option="option.option" />
                <ui-data-input v-else
                               :type="option.type"
                               :label="null"
                               :options="option.options"
                               :update="option.update"
                               :required="option.required"
                               :minLength="option.minLength"
                               :field="option.field || field"
                               :model="data"
                               :value="data[option.field || field] !== null ? data[option.field || field] : option.default"
                               :error="error[option.field || field]"
                               @set="sendData"
                               @error="sendError"
                               @input="$emit('input', (option.field || field), $event)"
                               @update="updateOtherField" />
              </td>
            </tr>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import UiData from '@/components/_ui/UiData'
import UiDataInput from '@/components/_ui/UiDataInput'

export default {
  name: 'UiForm',
  components: {
    UiData,
    UiDataInput
  },
  props: {
    meta: {
      type: Object,
      default: () => {
        return {
          field: {}
        }
      },
      required: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    error: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    }
  },
  methods: {
    sendData (data) {
      this.$emit('set', data)
    },
    sendError (key, message) {
      this.$emit('error', key, message)
    },
    updateOtherField (field, value) {
      this.$emit('input', field, value)
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  .form-top {
    height: 31.64px;
    padding: 20px;
    text-align: right;
    border-bottom: 1px solid $light;

    & :deep {
      .title {
        float: left;
        font-size: 20px;
        padding: 5px 0 5px 0;
        font-weight: bold;
      }
    }
  }

  .form-content {
    padding: 0 15px 19px;
    height: calc(100vh - 91.64px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      box-shadow: inset 0 0 10px 10px $gray;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $gray;
    }

    .form-table {
      width: 100%;
      table-layout: fixed;

      th {
        padding: 5px 10px;
        text-align: left;
        width: 120px;
        border-bottom: 1px solid $light;
        transition: background-color 0.3s ease;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      td {
        padding: 15px 10px 0;
        line-height: 1.4;
        border-bottom: 1px solid $light;
        background: $white;
        height: 1.4em;
        transition: background-color 0.3s ease;

        .type1image {
          display: block;
          margin: -11px -10px -10px;
          background: $dimlight;

          img {
            margin-bottom: -4px;
          }
        }

        .type2code {
          display: block;
          margin: -10px;
        }
      }

      tr.disabled {
        th,
        td {
          opacity: 0.5;
          background:transparent;
        }

        td {
          padding: 10px;
        }
      }

      tr:not(.inner):not(.disabled):hover {
        th,
        td {
          background-color: $dim;
        }
      }

      thead {
        th {
          padding: 15px;
          border-bottom: none;
          border-radius: 10px;
          background-color: $purple;
          color: $white;
          font-weight: bold;
        }

        td {
          padding: 0;
          background: transparent;
          border-bottom: none;
          height: 19px;
        }
      }
    }
  }
}
</style>
