<template>
  <div class="ui-dropdown-multiple">
    <div class="ui-dropdown-multiple-container"
         :class="{ disabled, error, required }">
      <label v-if="$slots.default"
             class="ui-dropdown-multiple-label"
             :for="uuid">
        <slot></slot>
      </label>
      <div class="ui-dropdown-multiple-wrap">
        <span v-if="type2 != 'timezoneName'">
          <span v-if="selectedValue.length > 0">
              {{ selectedValue.join(',') }}
          </span>
          <span v-else >
            click with <br>command(mac) or <br>ctrl(window)
          </span>
        </span>
        <span v-else>
          <span v-if="selectedValue.length == 1">
            {{ selectedValue.join(',') }}
          </span>
          <span v-else-if="selectedValue.length > 1">
            {{ selectedValue.length }} 개 선택됨
          </span>
          <span v-else >
            click with <br>command(mac) or <br>ctrl(window)
          </span>
          <ui-checkbox
                      :value="allSelectValue"
                      :isTimezone="true"
                      @input="allSelect"
                      style="padding-bottom: 0px">
          전체 선택
          </ui-checkbox>
        </span>

        <select v-if="type2 === 'timezoneName'" style="width: 100%; height: 250px; overflow-x: scroll;" v-model="selectedValue" multiple>
          <template v-if="type === 'group'">
            <optgroup :label="group.label" v-for="group in options" :key="group.value" v-if="group.value !== undefined">
              <option v-for="option in group.children" :value="option.value" :key="`${group.value}-${option.name}-${option.value}`">
                {{ option.name }}
              </option>
            </optgroup>
          </template>
          <template v-else>
            <option v-for="option of options" :value="option.value" :key="`${option.name}-${option.value}`">
              {{ option.name }}
            </option>
          </template>

        </select>
        <select v-else v-model="selectedValue" multiple>
          <template v-if="type === 'group'">
            <optgroup :label="group.label" v-for="group in options" :key="group.value" v-if="group.value">
              <option v-for="option in group.children" :value="option.value" :key="`${group.value}-${option.name}-${option.value}`">
                {{ option.name }}
              </option>
            </optgroup>
          </template>
          <template v-else>
            <option v-for="option of options" :value="option.value" :key="`${option.name}-${option.value}`">
              {{ option.name }}
            </option>
          </template>

        </select>
      </div>
      <div v-if="type2 != 'timezoneName'">
        <i v-if="!disabled"
            class="xi-close"
            @click="onClear"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid'
import UiCheckbox from '@/components/_ui/UiCheckbox'

export default {
  name: 'UiDropdownMultiple',
  components: {
    UiCheckbox
  },
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    model: {
      default: null,
      required: false
    },
    value: {
      default: () => [],
      required: true
    },
    options: {
      default: () => [],
      required: true
    },
    error: {
      type: String,
      default: '',
      required: false
    },
    type: {
      type: String,
      default: null,
      required: false
    },
    type2: {
      type: String,
      default: null,
      required: false
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (this.value && this.value.length > 0) {
        this.updateValue()
      } else {
        if (this.selectedValue.length !== 0) {
          this.selectedValue = []
        }
      }
    },
    selectedValue () {
      this.updateValue()
    }
  },
  methods: {
    onClear () {
      this.selectedValue = []
      this.$emit('input', this.initialValue)
    },
    updateValue () {
      this.allSelectCheck()
      if (this.selectedValue.length > 0) {
        this.$emit('input', this.selectedValue)
      } else {
        this.$emit('input', [])
      }
    },
    allSelectCheck () {
      if (this.selectedValue.length !== this.fullLength) {
        this.allSelectValue = false
      } else {
        this.allSelectValue = true
      }
    },
    allSelect () {
      this.allSelectValue = !this.allSelectValue
      if (this.allSelectValue) {
        let allList = []
        this.options.forEach(groupData => {
          groupData.children.forEach(data => {
            allList.push(data.value)
          })
        })
        this.selectedValue = allList
      } else {
        this.selectedValue = []
      }
    },
    clacGroupChildreLength () {
      let len = 0
      this.options.forEach(groupData => {
        len = len + groupData.children.length
      })
      return len
    }
  },
  data () {
    return {
      uuid: uuid.v4(),
      selectedValue: JSON.parse(JSON.stringify(this.value)),
      initialValue: [],
      allSelectValue: false,
      fullLength: this.type === 'group' ? this.clacGroupChildreLength() : this.options.length
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-dropdown-multiple {
  position: relative;
  padding-bottom: 20px;

  .ui-dropdown-multiple-container {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 2px solid;
    border-bottom-color: $gray;
    transition: border-bottom-color 0.3s ease;

    .ui-dropdown-multiple-label {
      flex-grow: 0;
      min-width: 70px;
      flex-basis: 70px;
      padding: 5px 5px 5px 7px;
      color: $black;
      opacity: 0.5;
      transition: color 0.3s ease;
    }

    .ui-dropdown-multiple-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;

      span {
        text-align: left;
        display: block;
        word-break: break-all;
      }

      select {
        margin-bottom: 5px;
      }
    }

    &.disabled {
      border-bottom-color: $white;
    }

    &.error {
      border-bottom-color: $red;
    }

    &:hover {
      .xi-close {
        opacity: 0.3;

        &:hover,
        &:active {
          opacity: 0.5;
        }
      }

      .required {
        width: 25px;
        height: 17px;
        color: $white;
      }
    }

    &.required {
      .xi-close {
        right: 20px;
      }

      &:hover {
        .xi-close {
          right: 40px;
        }
      }
    }
  }
}
</style>
