<template>
  <div class="coupon-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :reload="reload"
                          :selected="selected"
                          @select="setSelected"
                          @showDetail="showDetail">
      <ui-button :color="'green'"
                 @click="toggleInsert">
        <i class="xi-plus"></i> FAQ 추가
      </ui-button>
    </template-search-list>

    <template-insert :show="show.insert"
                     :metaApi="insertMetaApi"
                     :insertApi="insertApi"
                     @input="insertInput"
                     @reload="reloadList"
                     @saved="showDetail"
                     @close="toggleInsert">
      <h4 class="title"><i class="bi-beus"></i> FAQ 추가</h4>

      <ui-button :color="'blue'"
                 @click="togglePreview(model)">
        <i class="xi-eye"></i> 미리보기
      </ui-button>
    </template-insert>

    <template-detail :infoApi="infoApi"
                     :editApi="editApi"
                     :removeApi="removeApi"
                     :apiParam="apiParam"
                     @input="insertInput"
                     @reload="reloadList"
                     @close="setSelected">
      <ui-button :color="'blue'"
                 @click="togglePreview(model)">
        <i class="xi-eye"></i> 미리보기
      </ui-button>
    </template-detail>

    <faq-preview :data="preview"
                 @close="togglePreview(false)"/>
  </div>
</template>

<script>
import { getBeusFaqList, getBeusFaqInfo, getBeusFaqInsertMeta, insertBeusFaq, removeBeusFaq, updateBeusFaq } from '@/api/marketing'

import FaqPreview from '@/components/marketing/faq/FaqPreview'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateInsert from '@/components/_template/Insert'
import TemplateDetail from '@/components/_template/Detail'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'BeusFaqList',
  components: {
    FaqPreview,
    TemplateSearchList,
    TemplateInsert,
    TemplateDetail,
    UiButton
  },
  methods: {
    showDetail (data) {
      this.apiParam = { id: data.id || data.groupId }
      this.model = { ...data }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    insertInput (field, value) {
      this.$set(this.model, field, value)
    },
    togglePreview (value = false) {
      if (value) {
        this.preview = {
          ...value,
          serviceType: 'beusable'
        }
      } else {
        this.preview = false
      }
    },
    setSelected (row) {
      this.selected = row
    },
  },
  data () {
    return {
      reload: 0,
      selected: null,

      infoApi: getBeusFaqInfo,
      editApi: updateBeusFaq,
      removeApi: removeBeusFaq,
      insertApi: insertBeusFaq,
      insertMetaApi: getBeusFaqInsertMeta,
      apiParam: null,

      listApi: getBeusFaqList,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-publishAt',

      show: {
        insert: false
      },

      preview: false,
      model: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-list-wrap {
}
</style>
