import { mapState } from 'vuex'
import echarts from 'echarts/lib/echarts'
import merge from 'deepmerge'
import { parse, format, addDays } from 'date-fns'
import worldMap from 'echarts/map/json/world'

echarts.registerMap('world', worldMap)

const COMMON_OPTIONS = {
  meta: {},
  textStyle: {
    color: '#444',
    fontFamily: 'Roboto, "Noto Sans KR", "Noto Sans JP", sans-serif'
  },
  legend: {
    itemGap: 22,
    top: 0,
    right: 10,
    itemWidth: 8,
    itemHeight: 8,
    borderWidth: 0,
    symbolKeepAspect: true,
    textStyle: {
      padding: [
        2, 0, 0, 0
      ]
    }
  },
  xAxis: {
    axisLine: {
      lineStyle: {
        color: '#767676'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    confine: true,
    backgroundColor: '#fff',
    padding: 10,
    extraCssText: 'border-radius: 8px; box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.16);',
    textStyle: {
      color: '#666',
      fontSize: 12
    },
    axisPointer: {
      lineStyle: {
        color: 'rgba(0, 0, 0, 0.25)'
      }
    }
  }
  // animation: false
  // backgroundColor: '#fff'
}

export default {
  props: [
    'chartType',
    'chartOptions'
  ],
  computed: {
    ...mapState(['resizeTimestamp'])
  },
  watch: {
    chartOptions (options) {
      if (this.chart) {
        this.chart.clear()
        this.chart.setOption(this.getChartOptions(), true)
      }
    },
    resizeTimestamp (value) {
      if (this.chart) {
        this.chart.resize()
      }
    }
  },
  created () {
    this.chart = null
  },
  methods: {
    initChart () {
      if (!echarts) {
        throw new Error('echarts not imported')
      }
      return echarts.init(this.$el, 'light')
    },
    getDefaultOptions () {
      throw new Error('getDefaultOptions not implemented')
    },
    mergeOptions (baseOptions, chartOptions) {
      const options = merge(baseOptions, chartOptions)

      if (options.meta.isDailyChart) {
        for (const singleChart of options.series) {
          if (singleChart.type === 'line' && singleChart.data.length === 1) {
            const date = parse(singleChart.data[0][0])
            const prevDate = addDays(date, -1)
            singleChart.data.unshift([
              prevDate.getTime(),
              0
            ])
          }
        }
      }

      if (!options.legend.data) {
        options.legend.data = chartOptions.series.map(item => {
          return {
            name: item.name,
            icon: item.type === 'line' ? 'path://M 0,3 l 8 0 l 0 3 l -8 0 l 0 -3' : 'circle'
          }
        })
      }

      if (options.xAxis && options.xAxis.type === 'time') {
        if (!options.xAxis.axisLabel) {
          options.xAxis.axisLabel = {}
        }

        if (!options.xAxis.axisLabel.formatter) {
          options.xAxis.axisLabel.formatter = (value, index) => {
            return format(parse(value), 'MM-DD')
          }
        }
      }

      if (options.xAxis && options.xAxis.splitNumber) {
        const dataSize = options.series[0].data.length
        options.xAxis.splitNumber = Math.min(options.xAxis.splitNumber, dataSize - 1)
      }

      if (['pie', 'map'].includes(this.chartType)) {
        delete options.legend
        delete options.xAxis
        delete options.yAxis
      }

      return options
    },
    getChartOptions () {
      const baseOptions = merge(COMMON_OPTIONS, this.getDefaultOptions())
      return this.mergeOptions(baseOptions, this.chartOptions)
    },
    getChart () {
      return this.chart
    }
  },
  mounted () {
    setTimeout(function () {
      this.chart = this.initChart()
      this.chart.setOption(this.getChartOptions())

      window.addEventListener('resize', () => {
        if (this.chart) this.chart.resize()
      })

      this.chart.on('legendselectchanged', params => {
        const selected = params.selected
        const legendKeys = Object.keys(selected)
        const isAnimated = this.chart.getOption().animation

        if (legendKeys.length === 1) {
          this.chart.setOption({ animation: false })
          this.chart.dispatchAction({
            type: 'legendSelect',
            name: params.name
          })
          this.chart.setOption({ animation: isAnimated })
        } else {
          const offItems = Object.entries(selected).filter(item => !item[1])

          if (offItems.length === legendKeys.length) {
            for (const item of offItems) {
              this.chart.dispatchAction({
                type: 'legendSelect',
                name: item[0]
              })
            }
          }
        }
      })
    }.bind(this), 500)
  },
  destroyed () {
    if (this.chart) {
      this.chart.dispose()
      this.chart = null
    }
  }
}
