import { request } from '@/utils/api-request'

export function getBaDomainList (params) {
  const url = '/admin/ba/domain/domain_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaDomainInfo (params) {
  const url = '/admin/ba/domain/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaSitemapList (params) {
  const url = '/admin/ba/domain/sitemap_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaDomainExcelMeta (force) {
  const url = '/admin/ba/domain/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getBaSitemapExcelMeta (force) {
  const url = '/admin/ba/domain/sitemap_excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getBaSurveyList (params) {
  const url = '/admin/ba/domain/survey_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaSurveyExcelMeta (force) {
  const url = '/admin/ba/domain/survey_excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getBaApplyList (params) {
  const url = '/admin/ba/apply/apply_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaApplyInfo (params) {
  const url = '/admin/ba/apply/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaApplyExcelMeta (force) {
  const url = '/admin/ba/apply/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getBaApplyDevicePvExcelData (params) {
  const url = '/admin/ba/apply/device_pv_excel'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
