import { request } from "@/utils/api-request";

export function getLoginHistoryList(params, force = false) {
  const url = "/admin/login_history/list";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, force, "24h");
}

export function getLoginHistoryListExcelMeta(params, force = false) {
  const url = "/admin/login_history/excel_meta";
  const config = {
    method: "get",
    url,
  };
  return request(config, force, "24h");
}

export function getLoginHistoryBeusDashboard(params, force = false) {
  const url = "/admin/login_history/beus/dashboard";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, force, "24h");
}

export function getLoginHistoryBaDashboard(params, force = false) {
  const url = "/admin/login_history/ba/dashboard";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, force, "24h");
}