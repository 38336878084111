<template>
  <div class="user-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :reload="reload"
                          :selected="selected"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @showDetail="showDetail">
      <ui-button :color="'green'"
                 @click="toggleInsert">
        <i class="xi-plus"></i> 업로드
      </ui-button>
    </template-search-list>

    <template-insert :show="show.insert"
                     :metaApi="insertMetaApi"
                     :insertApi="insertApi"
                     @reload="reloadList"
                     @saved="showDetail"
                     @close="toggleInsert">
      <h4 class="title">계정 등록</h4>
    </template-insert>

    <template-detail :infoApi="infoApi"
                     :removeApi="removeApi"
                     :apiParam="apiParam"
                     @reload="reloadList"
                     @close="setSelected">
    </template-detail>
  </div>
</template>

<script>

import {
  getUserExcelMeta
} from '@/api/user'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import UiButton from '@/components/_ui/UiButton'

import { getStorageInfo, getStorageInsert, getStorageInsertMeta, getStorageList, removeStorage } from '@/api/storage'

export default {
  name: 'StorageList',
  components: {
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    UiButton
  },
  methods: {
    showDetail (data) {
      this.apiParam = { Key: data.Key }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    setSelected (row) {
      this.selected = row
    }
  },
  data () {
    return {
      reload: 0,
      selected: null,

      infoApi: getStorageInfo,
      removeApi: removeStorage,
      insertApi: getStorageInsert,
      insertMetaApi: getStorageInsertMeta,
      apiParam: null,

      listApi: getStorageList,
      excelListMetaApi: getUserExcelMeta,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-LastModified',

      show: {
        insert: false,
        change: false
      },
      searchFilter: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.user-list-wrap {
}
</style>
