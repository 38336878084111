<template>
  <div class="default-layout-sidebar">
    <div class="sidebar-logo">
      <h1>
        <i class="bi-eagle-logo"><span class="a11y-invisible">EAGLE</span></i>
        <i class="bi-eagle white"></i>
      </h1>
    </div>
    <nav class="sidebar-nav">
      <ul>
        <li>
          <router-link class="router-link"
                       :to="{ name: 'Dashboard' }">
            <span class="router-wrap">
              <span><i class="xi-dashboard"></i> Dashboard</span>
            </span>
          </router-link>
        </li>
        <li>
          <router-link class="router-link disabled"
                       :to="{ name: 'Monitoring' }">
            <span class="router-wrap">
              <span><i class="xi-apps"></i> Monitoring</span>
            </span>
          </router-link>
          <ul>
            <router-link :tag="'li'"
                         :to="{ name: 'MonitoringPayment' }">
              <router-link class="router-link"
                           :to="{ name: 'MonitoringPayment' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 플랜 생성 결과</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'MonitoringBeusPayment' }">
                  <i class="bi-beus"></i>
                </router-link>
                <router-link class="router-link"
                             :to="{ name: 'MonitoringBaPayment' }">
                <i class="bi-ba"></i>
            </router-link>
              </div>
            </router-link>
            <router-link :tag="'li'"
                         :to="{ name: 'MonitoringPaidUser' }">
              <router-link class="router-link"
                           :to="{ name: 'MonitoringPaidUser' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-check"></i> 유료 사용자 현황</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'MonitoringBeusPaidUser' }">
                  <i class="bi-beus"></i>
                </router-link>
                <router-link class="router-link"
                             :to="{ name: 'MonitoringBaPaidUser' }">
                  <i class="bi-ba"></i>
                </router-link>
              </div>
            </router-link>
            <router-link :tag="'li'"
                         :to="{ name: 'MonitoringPaymentError' }">
              <router-link class="router-link"
                           :to="{ name: 'MonitoringPaymentError' }">
                <span class="router-wrap">
                  <span><i class="xi-error-o"></i> 플랜 생성 오류</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'MonitoringBeusPaymentError' }">
                  <i class="bi-beus"></i>
                </router-link>
                <router-link class="router-link"
                             :to="{ name: 'MonitoringBaPaymentError' }">
                  <i class="bi-ba"></i>
                </router-link>
              </div>
            </router-link>
            <router-link :tag="'li'"
                         :to="{ name: 'MonitoringCapture' }">
              <router-link class="router-link"
                           :to="{ name: 'MonitoringCapture' }">
                <span class="router-wrap">
                  <span><i class="xi-image-o"></i> 캡처</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'MonitoringBeusCapture' }">
                  <i class="bi-beus"></i>
                </router-link>
              </div>
            </router-link>
            <router-link :tag="'li'"
                         :to="{ name: 'MonitoringCode' }">
              <router-link class="router-link"
                           :to="{ name: 'MonitoringCode' }">
                <span class="router-wrap">
                  <span><i class="xi-image-o"></i> Code</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'MonitoringSSBeusCode' }">
                  <i class="bi-samsung"></i>
                </router-link>
              </div>
            </router-link>
          </ul>
        </li>
        <router-link :tag="'li'"
                     :to="{ name: 'Service' }">
          <router-link class="router-link"
                       :to="{ name: 'Service' }">
            <span class="router-wrap">
              <span><i class="xi-battery-80"></i> Service</span>
            </span>
          </router-link>
          <div class="menu-services">
            <router-link class="router-link"
                         :to="{ name: 'ServiceSsbeusApply' }">
              <i class="bi-samsung"></i>
            </router-link>
            <router-link class="router-link"
                         :to="{ name: 'ServiceBeusApply' }">
              <i class="bi-beus"></i>
            </router-link>
            <router-link class="router-link"
                         :to="{ name: 'ServiceBaDomain' }"
                         :class="{ 'router-link-active router-link-exact-active': $route.name === 'ServiceBaSitemap' }">
              <i class="bi-ba"></i>
            </router-link>
          </div>
          <ul>
            <li>
              <router-link :tag="'li'"
                           :to="{ name: 'ServiceBeusApplyAB' }">
                <router-link class="router-link"
                             :to="{ name: 'ServiceBeusApplyAB' }">
                <span class="router-wrap">
                  <span class="">
                    <i class="xi-font"></i>
                    <i class="xi-bold"></i>
                    A/B Test
                  </span>
                </span>
                </router-link>
                <div class="menu-services">
                  <router-link
                    class="router-link"
                    :to="{ name: 'ServiceBeusApplyAB' }"
                  >
                    <i class="bi-beus"></i>
                  </router-link>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :tag="'li'"
                           :to="{ name: 'ServiceBaApply' }">
                <router-link class="router-link"
                             :to="{ name: 'ServiceBaApply' }">
                  <span class="router-wrap">
                    <span class="">
                      <i class="xi-image-o"></i>
                      Heatmap Light
                    </span>
                  </span>
                </router-link>
                <div class="menu-services">
                  <router-link
                    class="router-link"
                    :to="{ name: 'ServiceBaApply' }"
                  >
                    <i class="bi-ba"></i>
                  </router-link>
                </div>
              </router-link>
            </li>
          </ul>
        </router-link>
        <router-link :tag="'li'" :to="{ name: 'Account' }">
          <router-link class="router-link" :to="{ name: 'Account' }">
            <span class="router-wrap">
              <span><i class="xi-users"></i> Account</span>
            </span>
          </router-link>
          <div class="menu-services">
            <router-link
              class="router-link"
              :to="{ name: 'ServiceSsbeusAccount' }"
            >
              <i class="bi-samsung"></i>
            </router-link>
            <router-link
              class="router-link"
              :to="{ name: 'ServiceBeusAccount' }"
            >
              <i class="bi-beus"></i>
            </router-link>
          </div>
          <ul>
            <li>
              <router-link :tag="'li'"
                           :to="{ name: 'ServiceBeusUnAuthAccount' }">
                <router-link class="router-link"
                             :to="{ name: 'ServiceBeusUnAuthAccount' }">
                <span class="router-wrap">
                  <span class=""><i class="xi-mail"></i>미인증 계정</span>
                </span>
                </router-link>
                <div class="menu-services">
                  <router-link
                    class="router-link"
                    :to="{ name: 'ServiceSsbeusUnAuthAccount' }"
                  >
                    <i class="bi-samsung"></i>
                  </router-link>
                  <router-link
                    class="router-link"
                    :to="{ name: 'ServiceBeusUnAuthAccount' }"
                  >
                    <i class="bi-beus"></i>
                  </router-link>
                </div>
              </router-link>
            </li>
          </ul>
        </router-link>
        <router-link :tag="'li'"
                     :to="{ name: 'Jam' }">
          <router-link class="router-link"
                       :to="{ name: 'Jam' }">
            <span class="router-wrap">
              <span><i class="bi-jam"></i> Jam</span>
            </span>
          </router-link>
        </router-link>
        <router-link :tag="'li'"
                     :to="{ name: 'Mine' }">
          <router-link class="router-link"
                       :to="{ name: 'Mine' }">
            <span class="router-wrap">
              <span><i class="xi-paper"></i> Mine</span>
            </span>
          </router-link>
          <div class="menu-services">
            <router-link class="router-link"
                         :to="{ name: 'MineBa' }">
              <i class="bi-ba"></i>
            </router-link>
          </div>
        </router-link>
        <router-link :tag="'li'"
                     :to="{ name: 'Plan' }">
          <router-link class="router-link"
                       :to="{ name: 'Plan' }">
            <span class="router-wrap">
              <span><i class="xi-plug"></i> Plan</span>
            </span>
          </router-link>
          <div class="menu-services">
            <router-link class="router-link"
                         :to="{ name: 'PlanBeus' }">
              <i class="bi-beus"></i>
            </router-link>
            <router-link class="router-link"
                         :to="{ name: 'PlanBa' }">
              <i class="bi-ba"></i>
            </router-link>
          </div>
        </router-link>
        <router-link :tag="'li'"
                     :to="{ name: 'Payment' }">
          <router-link class="router-link"
                       :to="{ name: 'Payment' }">
            <span class="router-wrap">
              <span><i class="xi-money"></i> Payment</span>
            </span>
          </router-link>
          <div class="menu-services">
            <router-link class="router-link"
                          :to="{ name: 'PaymentJam' }">
              <i class="bi-jam"></i>
            </router-link>
            <router-link class="router-link"
                         :to="{ name: 'PaymentBeus' }">
              <i class="bi-beus"></i>
            </router-link>
            <router-link class="router-link"
                         :to="{ name: 'PaymentBa' }">
              <i class="bi-ba"></i>
            </router-link>
          </div>
        </router-link>
        <router-link :tag="'li'"
                     :to="{ name: 'Credit' }">
          <router-link class="router-link"
                       :to="{ name: 'Credit' }">
            <span class="router-wrap">
              <span><i class="xi-list"></i> Credit</span>
            </span>
          </router-link>
          <div class="menu-services">
            <router-link class="router-link"
                         :to="{ name: 'CreditBeus' }">
              <i class="bi-beus"></i>
            </router-link>
            <router-link class="router-link"
                         :to="{ name: 'CreditBa' }">
              <i class="bi-ba"></i>
            </router-link>
          </div>
        </router-link>
                <li>
          <router-link class="router-link disabled"
                     :to="{ name: 'LoginHistory' }">
            <span class="router-wrap">
              <span><i class="xi-users"></i> Login History</span>
            </span>
          </router-link>
          <ul>
            <li>
              <router-link class="router-link"
                           :to="{ name: 'LoginHistoryList' }">
                <span class="router-wrap">
                  <span><i class="xi-list"></i> 방문 이력</span>
                </span>
              </router-link>
            </li>
            <router-link :tag="'li'"
                         :to="{ name: 'loginHistoryDashboard' }">
              <router-link class="router-link"
                           :to="{ name: 'loginHistoryDashboard' }">
                <span class="router-wrap">
                  <span><i class="xi-calendar-list"></i> 로그인 이력</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'LoginHistoryBeusDashboard' }">
                  <i class="bi-beus"></i>
                </router-link>
                <router-link class="router-link"
                             :to="{ name: 'LoginHistoryBaDashboard' }">
                  <i class="bi-ba"></i>
                </router-link>
              </div>
            </router-link>
          </ul>
        </li>
        <li>
          <router-link class="router-link disabled"
                         :to="{ name: 'Contact' }">
            <span class="router-wrap">
              <span><i class="xi-forum"></i> Contact</span>
            </span>
          </router-link>
          <ul>
            <li>
              <router-link class="router-link"
                           :to="{ name: 'ContactEmail' }">
                <span class="router-wrap">
                  <span><i class="xi-mail"></i> eDM</span>
                </span>
              </router-link>
            </li>
            <li>
              <router-link class="router-link"
                           :to="{ name: 'ContactUs' }">
                <span class="router-wrap">
                  <span><i class="xi-forum"></i> ContactUs 대화 이력</span>
                </span>
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link class="router-link disabled"
                     :to="{ name: 'Marketing' }">
            <span class="router-wrap">
              <span><i class="xi-pacman"></i> Marketing</span>
            </span>
          </router-link>
          <ul>
            <router-link :tag="'li'"
                         :to="{ name: 'MarketingCoupon' }">
              <router-link class="router-link"
                           :to="{ name: 'MarketingCoupon' }">
                <span class="router-wrap">
                  <span><i class="xi-coupon"></i> Coupon</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'MarketingCoupon' }">
                  <i class="bi-beus"></i>
                </router-link>
              </div>
            </router-link>
            <li>
              <router-link class="router-link"
                           :to="{ name: 'MarketingEvent' }">
                <span class="router-wrap">
                  <span><i class="xi-business"></i> Event</span>
                </span>
              </router-link>
            </li>
            <router-link :tag="'li'"
                         :to="{ name: 'MarketingPopup' }">
              <router-link class="router-link"
                           :to="{ name: 'MarketingPopup' }">
                <span class="router-wrap">
                  <span><i class="xi-browser-text"></i> Popup</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'MarketingPopupBeus' }">
                  <i class="bi-beus"></i>
                </router-link>
                <router-link class="router-link"
                             :to="{ name: 'MarketingPopupBa' }">
                  <i class="bi-ba"></i>
                </router-link>
              </div>
            </router-link>
            <router-link :tag="'li'"
                         :to="{ name: 'MarketingFaq' }">
              <router-link class="router-link"
                           :to="{ name: 'MarketingFaq' }" >
                <span class="router-wrap">
                  <span><i class="xi-school"></i> Faq</span>
                </span>
              </router-link>
              <div class="menu-services">
                <router-link class="router-link"
                             :to="{ name: 'MarketingFaqBeus' }">
                  <i class="bi-beus"></i>
                </router-link>
                <router-link class="router-link"
                             :to="{ name: 'MarketingFaqBa' }">
                  <i class="bi-ba"></i>
                </router-link>
              </div>
            </router-link>
          </ul>
        </li>
        <li>
          <router-link class="router-link disabled"
                       :to="{ name: 'Tool' }">
            <span class="router-wrap">
              <span><i class="xi-forum"></i> Tool</span>
            </span>
          </router-link>
          <ul>
            <li>
              <router-link class="router-link"
                           :to="{ name: 'ToolGetAid' }">
                <span class="router-wrap">
                  <span><i class="xi-check-circle-o"></i> 페이지 등록 확인</span>
                </span>
              </router-link>
            </li>
          </ul>
        </li>
        <router-link :tag="'li'"
                     :to="{ name: 'RawData' }">
          <router-link class="router-link"
                       :to="{ name: 'RawData' }">
                <span class="router-wrap">
                  <span><i class="xi-package"></i> Raw Data</span>
                </span>
          </router-link>
          <div class="menu-services">
            <router-link class="router-link"
                         :to="{ name: 'RawDataBeus' }">
              <i class="bi-beus"></i>
            </router-link>
            <router-link class="router-link"
                         :to="{ name: 'RawDataBa' }">
              <i class="bi-ba"></i>
            </router-link>
          </div>
        </router-link>
        <li>
          <router-link class="router-link"
                       :to="{ name: 'Storage' }">
            <span class="router-wrap">
              <span><i class="xi-package"></i> Storage</span>
            </span>
          </router-link>
        </li>
        <li v-if="isDev">
          <router-link class="router-link disabled"
                       :to="{ name: 'DevOnly' }">
            <span class="router-wrap">
              <span><i class="xi-connectdevelop"></i> DEV</span>
            </span>
          </router-link>
          <ul>
            <li>
              <router-link class="router-link"
                           :to="{ name: 'JenkinsList' }">
                <span class="router-wrap">
                  <span><i class="xi-list"></i> Jenkins List</span>
                </span>
              </router-link>
            </li>

          </ul>
        </li>
      </ul>
    </nav>

    <div class="current-user">
      <button @click="onSignOut">
        <span class="user-id-title">현재 로그인 중</span>
        <span class="user-id">{{ userName }}</span>
        <i class="xi-log-out">
          <span class="a11y-invisible">로그아웃</span>
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import { removeAuthToken } from '@/utils/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultSidebar',
  data () {
    return {
      isDev: false
    }
  },
  created () {
    if (this.userData.isDev) {
      this.isDev = true
    }
  },
  methods: {
    onSignOut () {
      removeAuthToken()
      this.$router.push({ name: 'SignIn' })
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    userName () {
      if (this.userData) {
        return this.userData.userId.split('@')[0]
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.default-layout-sidebar {
  display: flex;
  flex-direction: column;
  background-image: $black-gradient;
  background-size: 280px auto;
  background-attachment: fixed;

  .sidebar-logo {
    flex-grow: 0;

    h1 {
      position: relative;
      color: $white;
      font-weight: bold;
      font-size: 30px;
      padding: 21px 11px 10px;

      .bi-eagle-logo {
        transition: opacity 0.3s ease;
      }

      .bi-eagle {
        position: absolute;
        top: 29px;
        left: 10px;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }
  }

  .sidebar-nav {
    flex-grow: 1;
    overflow: auto;
    direction: rtl;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      border-right: none;
      box-shadow: inset 0 0 10px 10px $black;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $dark;
    }

    & > ul {
      overflow: hidden;
      padding: 15px 0;
      margin-left: -3px;
      direction: ltr;
    }

    .router-link {
      position: relative;
      color: $white;
      display: block;
      transition: color 0.3s ease;

      &.disabled {
        pointer-events: none;
        cursor: default;
        color: $light;

        i {
          color: $light;
        }
      }

      i {
        color: $white;
        margin-left: -6px;
        transition: margin-left 0.3s ease,
                    color 0.3s ease;
      }

      .router-wrap {
        display: block;
        padding: 10px 13px;

        span {
          position: relative;
          display: block;
          z-index: 3;
          transition: padding-left 0.3s ease,
                      color 0.3s ease;

          &.small {
            font-size: 12px;
          }

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: -5px;
            width: 7px;
            height: 7px;
            color: $blue;
            background: $blue;
            border-radius: 5px;
            opacity: 0;
            transform: translateY(-50%);
            transition: opacity 0.3s ease;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: -10%;
          right: -100%;
          width: 100%;
          height: 120%;
          z-index: 2;
          background: $white;
          border-radius: 15px 0 0 15px;
          box-shadow: 0 23px 15px -12px rgba(0, 0, 0, 0.08);
          transition: right 0.3s ease;
        }
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        right: -10px;
        width: 10px;
        height: 10px;
        border-right: 10px solid $white;
        border-radius: 0 0 20px 0;
        background-image: $black-gradient;
        background-size: 280px auto;
        background-attachment: fixed;
        transition: right 0.17s ease;
        transition-delay: 0.1s;
        z-index: 1;
      }

      &::before {
        top: calc(-10% - 10px);
        border-bottom: 10px solid $white;
        border-radius: 0 0 20px 0;
      }

      &::after {
        top: calc(110% - 10px);
        border-top: 10px solid $white;
        border-radius: 0 20px 0 0;
      }

      &.router-link-active {
        color: $black;

        .router-wrap {
          &::before {
            right: 0;
          }

          i {
            margin-left: 0;
            color: $blue;
          }
        }

        &::before,
        &::after {
          right: 0;
          transition-delay: 0s;
        }
      }

      &.router-link-exact-active {
        .router-wrap {
          span {
            padding-left: 7px;

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    ul {
      margin: 0 0 10px 10px;
      font-size: 15px;

      li {
        position: relative;

        .menu-services {
          position: absolute;
          z-index: 10;
          top: 4px;
          right: 14px;

          & > a {
            display: inline-block;
            margin: 4px;

            i {
              padding: 6px;
              border-radius: 12px;
              background-size: calc(100% - 12px);
              background-position: center;
              background-color: transparent;
              transition: background-color 0.2s ease;

              &.bi-jam {
                background-image: url('~@/assets/icon--jam2.svg');
              }
              &.bi-beus {
                background-image: url('~@/assets/icon--beus-white.svg');
              }

              &.bi-ba {
                background-image: url('~@/assets/icon--ba-white.svg');
              }

              &.bi-samsung {
                background-image: url('~@/assets/icon--samsung-white.svg');
              }
            }

            &.router-link-active {
              i {
                &.bi-jam {
                  background-color: $red;
                  background-image: url('~@/assets/icon--jam2.svg');
                }
                &.bi-beus {
                  background-color: $red;
                  background-image: url('~@/assets/icon--beus-white.svg') !important;
                }

                &.bi-ba {
                  background-color: $red;
                  background-image: url('~@/assets/icon--ba-white.svg') !important;
                }

                &.bi-samsung {
                  background-color: $black;
                  background-image: url('~@/assets/icon--samsung-white.svg') !important;
                }
              }
            }

            &::before,
            &::after {
              display: none;
            }
          }
        }

        &.router-link-active > .menu-services {
          a {
            i {
              &.bi-jam {
                background-image: url('~@/assets/icon--jam2.svg');
              }
              &.bi-beus {
                background-image: url('~@/assets/icon--beus.svg');
              }

              &.bi-ba {
                background-image: url('~@/assets/icon--ba.svg');
              }

              &.bi-samsung {
                background-image: url('~@/assets/icon--samsung.svg');
              }
            }
          }
        }
      }

      ul {
        position: relative;
        padding-left: 5px;

        &::before {
          content: "";
          position: absolute;
          top: 7px;
          left: 3px;
          width: 3px;
          height: calc(100% - 14px);
          background: $white;
          border-radius: 3px;
          opacity: 0.5;
        }

        .router-link {
          &.router-link-active {
            .router-wrap {
              &::before {
                right: 5px;
              }
            }
          }
        }
      }
    }
  }

  .current-user {
    flex-grow: 0;

    button {
      width: calc(100% - 26px);
      background: rgba(255, 255, 255, 0.3);
      border-radius: 13px;
      margin: 0 18px 8px 8px;
      padding: 10px 10px 10px 15px;
      text-align: left;

      .user-id-title {
        display: block;
        font-size: 13px;
        color: $black;
        margin-bottom: 3px;
      }

      .user-id {
        font-weight: bold;
        color: $dim;
      }

      .xi-log-out {
        float: right;
        margin: 3px 0;
        color: $white;
        transition: color 0.3s ease;
      }

      &:hover,
      &.active {

        .xi-log-out {
          color: $red;
        }
      }
    }
  }
}
</style>
