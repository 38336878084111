<template>
  <div class="ui-navigation">
    <div class="page-buttons">
      <button class="arrow"
              :disabled="pageNum === navMin"
              @click="goToPage(navMin)">
        <i class="xi-angle-left-min"></i>
      </button>

      <button class="arrow"
              :disabled="pageNum === navMin"
              @click="goToPage(pageNum - 1)">
        <i class="xi-angle-left"></i>
      </button>

      <button v-for="i of navArray"
              :key="`nav${i}`"
              :class="{ active: pageNum === i }"
              @click="goToPage(i)">
        {{ i }}
      </button>

      <button class="arrow"
              :disabled="pageNum === navMax"
              @click="goToPage(pageNum + 1)">
        <i class="xi-angle-right"></i>
      </button>

      <button class="arrow"
              :disabled="pageNum === navMax"
              @click="goToPage(navMax)">
        <i class="xi-angle-right-min"></i>
      </button>
    </div>
    <div class="page-size">
      <button v-for="no in [10, 30, 50]"
              :class="{ active: pageSize === no }"
              :key="`${no}PerPage`"
              @click="setItemsPerPage(no)">
        {{ no }}
      </button>
      <span>개씩</span>

      <span v-if="totalCount"
            class="total-count">
        모두 <em>{{ totalCount }}</em>개
      </span>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: 'UiNavigation',
  props: {
    pageNum: {
      type: Number,
      default: 0,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10,
      required: true
    },
    totalCount: {
      type: Number,
      default: 0,
      required: true
    },
    navSize: {
      type: Number,
      default: 10
    }
  },
  computed: {
    navArray () {
      return new Array(Math.max(this.navMin, this.navEnd - this.navStart + 1))
        .fill(this.navStart).map((n, i) => n + i)
    },
    navStart () {
      return Math.max(this.pageNum - (this.navSize - 1 - Math.min(Math.floor(this.navSize / 2),
        this.navMax - this.pageNum)), this.navMin)
    },
    navEnd () {
      return Math.min(this.pageNum + Math.max(Math.floor(this.navSize / 2),
        this.navSize - this.pageNum), this.navMax)
    },
    navMin () {
      return 1
    },
    navMax () {
      return Math.floor((this.totalCount - 1) / this.pageSize) + 1
    }
  },
  methods: {
    goToPage (no) {
      this.$emit('goToPage', no)
    },
    setItemsPerPage (no) {
      const currentPageNum = this.pageNum
      const currentPageSize = this.pageSize
      const totalCount = this.totalCount
      const offsetCount = (this.navMax === this.navMin || currentPageNum === this.navMax)
        ? totalCount : currentPageNum * currentPageSize
      const num = Math.ceil(offsetCount / no)
      this.$emit('setItemsPerPage', num, no)
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-navigation {
  .page-buttons {
    float: left;

    button {
      min-width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      color: $black;
      border-radius: 20px;
      letter-spacing: -1px;
      padding: 0 4px;

      &:disabled {
        cursor: default;
        color: $light;
      }

      &.arrow {
        margin: 0 -5px;
      }

      &.active {
        background-color: $green;
        color: $white;
      }
    }
  }

  .page-size {
    float: right;

    button {
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      color: $black;
      border-radius: 20px;
      letter-spacing: -1px;

      &.active {
        background-color: $purple;
        color: $white;
      }
    }

    .total-count {
      border-left: 1px solid $light;
      padding-left: 7px;
      margin-left: 5px;
      text-align: right;

      em {
        font-weight: bold;
        font-style: italic;
        font-size: 18px;
        color: $purple;
        margin-right: 3px;
      }
    }
  }
}
</style>
