<template>
  <header class="default-layout-header">
    <div class="header-title">
      <span v-if="service === 'Beusable'"
            class="service beusable">
        <i class="bi-beus white"></i> Beusable
      </span>
      <span v-else-if="service === 'BA'"
            class="service beusable">
        <i class="bi-ba white"></i> BA
      </span>
      <span v-else-if="service === 'JAM'"
            class="service beusable">
        <i class="bi-jam"></i> JAM
      </span>
      <span v-else-if="service === 'Samsung Beusable'"
            class="service samsung">
        <i class="bi-samsung-logo white"></i>
        <span class="line"></span>
        <i class="bi-beus white"></i>
      </span>
      <slot></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'DefaultLayoutHeader',
  props: {
    service: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.default-layout-header {
  .header-title {
    font-size: 30px;
    padding: 20px;
    color: $black;
    border-bottom: 1px solid $dimlight;
    height: 31.65px;

    .service {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      background-color: $red;
      color: $white;
      padding: 5px 10px;
      border-radius: 10px;
      margin-top: -7px;
      margin-left: -50px;
      transition: margin-left 0.3s ease;

      .line {
        width: 1px;
        height: 15px;
        margin: 3px 5px 0;
        background-color: $dimlight;
        display: inline-block;
        vertical-align: top;
      }

      &.samsung {
        background-image: none;
        background-color: $black;
      }
    }
  }
}
</style>
