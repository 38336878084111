<template>
  <div class="chart line_chart"></div>
</template>

<script>
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/dataZoom'
import ChartMixin from './ChartMixin'

export default {
  name: 'BarChart',
  mixins: [
    ChartMixin
  ],
  methods: {
    getDefaultOptions () {
      return {
        grid: {
          containLabel: true,
          right: 20,
          left: 5,
          top: 30,
          bottom: 0
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          shadowStyle: {
            color: 'rgba(0, 0, 0, 0.06)'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {},
          offset: 4,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.line_chart {
  position: relative;
  width: 00px;
  height: 400px;
}
</style>
