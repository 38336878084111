<template>
  <div class="detail">
    <div class="detail-top">
      <slot></slot>
    </div>
    <div class="detail-content">
      <table class="detail-table">
        <colgroup>
          <col width="140">
          <col>
        </colgroup>
        <template v-if="!cancelMode">
          <template v-for="[field, option] in Object.entries(meta.field)">
            <thead v-if="option.title"
                  :key="`detail-${field}`">
              <tr>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th colspan="2"
                    v-html="option.name"></th>
              </tr>
            </thead>
            <thead v-else-if="option.subTitle"
                  :key="`detail-${field}`">
              <tr>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th class="subtitle" colspan="2"
                    v-html="option.name"></th>
              </tr>
            </thead>
            <template v-else>
              <tr :key="`detail-${field}`"
                  :class="{ inner: ['list', 'tab'].includes(option.type.split('|')[0]),
                            disabled: editMode && !option.editable,
                            editing: editMode && option.editable }">
                <th v-if="option.name"
                    v-html="option.name"></th>
                <td :colspan="option.name ? null : 2">
                  <ui-data-input v-if="editMode && option.editable"
                                :type="option.type"
                                :label="null"
                                :options="option.options"
                                :update="option.update"
                                :required="option.required"
                                :field="option.field || field"
                                :model="data"
                                :value="data[option.field || field]"
                                :error="error[option.field || field]"
                                @error="sendError"
                                @input="$emit('input', (option.field || field), $event)" />
                  <ui-data v-else
                          :type="option.type"
                          :value="data[option.field || field]"
                          :option="option.option"
                          :listData="option.options"
                          :sortBy="tableSortBy[option.field || field] || ''"
                          :name="field"
                          :fieldName="field"
                          :model="data"
                          :timezoneName="data.timezoneName ? data.timezoneName : null"
                          @setTableData="(...args) => { setTableData((option.field || field), ...args) }"
                          @changeTableSort="(...args) => { changeTableSort((option.field || field), ...args) }"
                          @button="button" />
                </td>
              </tr>
            </template>
          </template>
        </template>
      <template v-else>
        <template v-for="[field, option] in Object.entries(cancelMeta.field)">
          <thead v-if="option.title"
                  :key="`detail-${field}`">
            <tr>
              <td colspan="2"></td>
            </tr>
            <tr>
              <th colspan="2"
                  v-html="option.name"></th>
            </tr>
          </thead>
          <template v-else>
              <tr :key="`detail-${field}`"
                  :class="{ inner: ['list', 'tab'].includes(option.type.split('|')[0]),
                            disabled: cancelMode && !option.editable,
                            editing: cancelMode && option.editable }">
                <th v-if="option.name"
                    v-html="option.name"></th>
                <td :colspan="option.name ? null : 2">
                  <ui-data-input v-if="cancelMode && option.editable"
                                :type="option.type"
                                :label="null"
                                :options="option.options"
                                :update="option.update"
                                :required="option.required"
                                :field="option.field || field"
                                :cancelMode="cancelMode"
                                :model="data"
                                :value="getValue(option.field || field)"
                                :error="error[option.field || field]"
                                @error="sendError"
                                @input="$emit('input', (option.field || field), $event)" />
                  <ui-data v-else
                          :type="option.type"
                          :value="data[option.field || field]"
                          :option="option.option"
                          :listData="option.options"
                          :sortBy="tableSortBy[option.field || field] || ''"
                          :name="field"
                          :model="data"
                          @setTableData="(...args) => { setTableData((option.field || field), ...args) }"
                          @changeTableSort="(...args) => { changeTableSort((option.field || field), ...args) }"
                          @button="button" />
                </td>
              </tr>
            </template>
        </template>
      </template>
      </table>
    </div>
  </div>
</template>

<script>
import { hasKey } from '@/utils/tools'
import { PAYMENT_CARD } from '@/utils/constants'

import UiData from '@/components/_ui/UiData'
import UiDataInput from '@/components/_ui/UiDataInput'

export default {
  name: 'UiDetail',
  components: {
    UiData,
    UiDataInput
  },
  props: {
    meta: {
      type: Object,
      default: () => {
        return {
          field: {}
        }
      },
      validator: (value) => {
        return hasKey(value, ['field'])
      },
      required: false
    },
    cancelMeta: {
      type: Object,
      default: () => {
        return {
          field: {}
        }
      },
      validator: (value) => {
        return hasKey(value, ['field'])
      },
      required: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    error: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    editMode: {
      type: Boolean,
      default: false,
      required: false
    },
    cancelMode: {
      type: Boolean,
      default: false,
      required: false
    },
    tableSortBy: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    service: {
      default: false
    }
  },
  methods: {
    sendData (data) {
      this.$emit('set', data)
    },
    sendError (key, message) {
      if (key !== 'hostUserId') {
        this.$emit('error', key, message)
      }
    },
    setTableData (table, field, idx, data) {
      this.$emit('setTableData', table, field, idx, data)
    },
    changeTableSort (table, sort) {
      this.$emit('changeTableSort', table, sort)
    },
    button (value, data) {
      if (value === 'jamCharge') {
        data = { userId: this.data.userId, userNum: this.data.userNum }
      } else if (value === 'beusJamUse' || value === 'baJamUse') {
        data = { userId: this.data.userId, userNum: this.data.userNum, cardNumber: 'jam' }
      } else if (value === 'baApplyExcel') {
        data = { aid: this.data.aid }
      } else if (value === 'domainBaApply') {
        data = { parentSid: this.data.parentSid }
      }
      this.$emit('button', value, data)
    },
    getValue (field) {
      if (this.cancelMeta && this.cancelMode) {
        if (this.cancelMeta.field[field].default) {
          return this.cancelMeta.field[field].default
        } else if (field === 'price') {
          if (this.service === 'jam') {
            if (this.data.paymentState !== PAYMENT_CARD) {
              return 0
            } else {
              return this.data[field]              
            }
          } else {
            return this.data.midPriceSum
          }
        } else if (field === 'jamChargeCount') {
            return this.data.remainCount > 0 ? this.data.remainCount : 0
        } else if (field === 'jiraIssueNumber' || field === 'productName') {
          this.data[field] = ''
          return this.data[field]
        } else {
          return this.data[field]
        }
      } else {
        return this.data[field]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail-top {
    height: 31.64px;
    padding: 20px;
    text-align: right;
    border-bottom: 1px solid $light;

    & :deep {
      .title {
        float: left;
        font-size: 20px;
        padding: 5px 0 5px 0;
        font-weight: bold;
      }
    }
  }

  .detail-content {
    padding: 0 15px 19px;
    height: calc(100vh - 91.64px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      box-shadow: inset 0 0 10px 10px $gray;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $gray;
    }

    .detail-table {
      width: 100%;
      table-layout: fixed;

      th {
        padding: 5px 10px;
        text-align: left;
        width: 120px;
        border-bottom: 1px solid $light;
        transition: background-color 0.3s ease;
      }

      td {
        padding: 10px;
        line-height: 1.4;
        border-bottom: 1px solid $light;
        background: $white;
        height: 1.4em;
        transition: background-color 0.3s ease;

        .type1image {
          display: block;
          margin: -11px -10px -10px;
          background: $dimlight;

          img {
            margin-bottom: -4px;
          }
        }

        .type2code {
          display: block;
          margin: -10px;
        }
      }

      tr.disabled {
        th,
        td {
          opacity: 0.5;
          background:transparent;
        }
      }

      tr.editing {
        td {
          padding: 15px 10px 0;
        }
      }

      tr:not(.inner):not(.disabled):hover {
        th,
        td {
          background-color: $dim;
        }
      }

      thead {
        th {
          padding: 15px;
          border-bottom: none;
          border-radius: 10px;
          color: $white;
          font-weight: bold;
          background-image: $black-gradient;
          
          &.subtitle {
            padding: 10px;
            background-image: none;
            color: $black;
          }
        }

        td {
          padding: 0;
          background: transparent;
          border-bottom: none;
          height: 19px;
        }
      }
    }
  }
}
</style>
