import { request } from "@/utils/api-request";

export function getBaAiReportList(params) {
  const url = "/admin/ba/mine/list";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, true);
}

export function getBaAiReportInfo(params) {
  const url = "/admin/ba/mine/info";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, true);
}
