<template>
  <div class="ui-button"
       :class="{ full }"
       :style="{ width }">
    <div class="ui-button-container"
         :class="[color, { disabled }]">
      <button :disabled="disabled"
              @click="onClick">
        <slot></slot>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    color: {
      type: String,
      default: 'blue',
      validator: function (value) {
        return ['red', 'yellow', 'orange', 'green', 'blue', 'purple', 'pink', 'gray', 'darkgreen'].includes(value)
      },
      required: false
    },
    width: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    full: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    onClick (e) {
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-button {
  display: inline-block;
  vertical-align: top;
  margin-left: 6px;

  &:first-child {
    margin-left: 0;
  }

  &.full {
    display: block;
  }

  .ui-button-container {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    button {
      position: relative;
      padding: 4px 12px 6px;
      color: $white;
      background-color: $gray;
      border-radius: 20px;
      text-align: center;
      width: 100%;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background-color: $black;
        content: "";
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover {
        &::after {
          opacity: 0.2;
        }
      }

      &:active {
        &::after {
          opacity: 0.4;
        }
      }
    }

    &.disabled {
      button {
        opacity: 0.2;
        background-image: none !important;
        background-color: $gray !important;
        cursor: default;

        &::after {
          display: none;
        }
      }
    }

    &.red {
      button {
        background-color: $red;
      }
    }

    &.yellow {
      button {
        background-color: $orange;
      }
    }

    &.green {
      button {
        background-color: $green;
      }
    }

    &.blue {
      button {
        background-color: $blue;
      }
    }

    &.purple {
      button {
        background-color: $purple;
      }
    }

    &.orange {
      button {
        background-color: $orange;
      }
    }

    &.pink {
      button {
        background-color: $pink;
      }
    }

    &.gray {
      button {
        background-color: $gray;
      }
    }

    &.darkgreen {
      button {
        background-color: $darkgreen;
      }
    }
  }
}
</style>
