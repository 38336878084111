export default function getDialogData () {
  return {
    timezone: {
      timezoneChange: {
        type: 'confirm',
        iconClass: 'icon--modification',
        title: 'timezone.timezoneChange.title',
        description: 'timezone.timezoneChange.desc'
      }
    }
  }
}
