<template>
  <div class="template-search-list"
       :class="{ searching: show.search }">
    <div class="top-area">
      <slot></slot>
      <ui-button :color="'blue'"
                 @click="toggleSearch">
        <i class="xi-search"></i> 검색
      </ui-button>
    </div>

    <div class="container">
      <div class="table-area" :style="{right: defaultSize + 'px'}">
        <ui-table-list v-if="columnData && listData"
                       :columnData="columnData"
                       :listData="listData"
                       :navigation="navigationOption"
                       :sortBy="sortBy"
                       :columnFixed="columnFixed"
                       :buttonOption="buttonOption"
                       :selected="selected"
                       @set="setTableData"
                       @button="button"
                       @buttonAction="buttonAction"
                       @goPage="goPage"
                       @changePageSize="changePageSize"
                       @changeSort="changeSort" />
      </div>

      <div class="search-area" :style="{width: defaultSize + 'px'}">
      <div id="drag" @mousedown="isResizing = true" ></div>
        <ui-filter v-if="searchOption"
                   :searchOption="searchOption"
                   :searchFilter="searchFilter.current"
                   :isRangeToday="isRangeToday"
                   :rangeTodayField="rangeTodayField"
                   :itsEmailList="checkItsEmailList()"
                   @updateFilter="updateFilter"
                   @search="search" />
      </div>
    </div>

  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'

import { alertError } from '@/utils/tools'

import UiFilter from '@/components/_ui/UiFilter'
import UiTableList from '@/components/_ui/UiTableList'
import UiButton from '@/components/_ui/UiButton'
import { format } from 'date-fns'

export default {
  name: 'TemplateSearchList',
  components: {
    UiFilter,
    UiTableList,
    UiButton
  },
  props: {
    listApi: {},
    defaultSortBy: {
      type: String,
      default: '-regDate'
    },
    buttonOption: {
      type: Array,
      default () {
        return []
      }
    },
    initialSearchFilter: {
      type: Object,
      required: false
    },
    reload: {
      required: false
    },
    excelDownload: {
      required: false,
      default: false
    },
    isRangeToday: {
      required: false,
      default: false
    },
    rangeTodayField: {
      required: false,
      default: false
    },
    menu: {
      type: String,
      required: false
    },
    selected: {
      type: Number,
      required: false
    }
  },
  created () {
    document.addEventListener('mousemove', this.setResizing)
    document.addEventListener('mouseup', () => {
      this.isResizing = false
    })
    this.initFilter()
    this.loadData(true)
  },
  watch: {
    reload () {
      this.loadData()
    }
  },
  methods: {
    initFilter () {
      if (this.initialSearchFilter) {
        for (const [key, value] of Object.entries((this.initialSearchFilter))) {
          this.searchFilter.current[key] = value
          this.searchFilter.previous[key] = value
        }
      }
    },
    loadData (initial = false) {
      EventBus.$emit('onLoading', true)

      this.listApi({
        pageNum: this.navigationOption.pageNum,
        pageSize: this.navigationOption.pageSize,
        sortBy: this.sortBy || '-regDate',
        searchFrom: this.searchFilter.previous
      }, true).then(response => {
        if (typeof response.result !== 'object' || response.message) {
          alertError('목록을 불러오지 못 했습니다.', typeof response.result !== 'object' ? response.result : response.message)
        } else {
          this.listData = response.result.list
          this.columnData = response.result.meta.column

          this.$emit('listCount', response.result.count)

          Object.entries(this.columnData).forEach(([key, value], index) => {
            if (value.columnFixed) {
              this.columnFixed = index
            }
          })

          if (this.isRangeToday && this.rangeTodayField) {
            if (!this.searchFilter.previous[this.rangeTodayField] ||
              ((!this.searchFilter.previous[this.rangeTodayField].start || this.searchFilter.previous[this.rangeTodayField].start === '') &&
              (!this.searchFilter.previous[this.rangeTodayField].end || this.searchFilter.previous[this.rangeTodayField].end === ''))
            ) {
              this.searchFilter.current[this.rangeTodayField] = {
                start: format(new Date(), 'yyyy-MM-dd'),
                end: format(new Date(), 'yyyy-MM-dd')
              }
            }
          }

          this.searchOption = response.result.meta.searchOption
          this.navigationOption.totalCount = response.result.count
          this.$emit('searchFilter', this.searchFilter.previous)
        }
      }).catch(error => {
        if (error.response) {
          alertError('목록을 불러오지 못 했습니다.', typeof error.response.data !== 'object' ? error.response.data : error.response.data.message)
        } else {
          alertError('목록을 불러오지 못 했습니다.', error)
        }
      }).finally(() => {
        EventBus.$emit('onLoading', false)
      })
    },

    goPage (no) {
      this.navigationOption.pageNum = no
      this.loadData()
    },
    changeSort (key) {
      this.sortBy = key
      this.navigationOption.pageNum = 1
      this.loadData()
    },
    changePageSize (pageNum, pageSize) {
      this.navigationOption.pagesize = pageSize
      this.navigationOption.pageNum = pageNum
      this.loadData()
    },
    button (value, data) {
      this.$emit('button', value, data)
    },

    buttonAction (event, params, row = null) {
      if (event === 'showDetail') {
        this.$emit('select', row)
      }
      this.$emit(event, params)
    },

    toggleSearch () {
      this.show.search = !this.show.search
    },

    updateFilter (filter, isAll = false) {
      if (isAll) {
        this.searchFilter.current = {}
        this.searchFilter.previous = {}
        this.initFilter()
      } else {
        this.searchFilter.current = filter
      }
    },
    search () {
      this.sortBy = this.defaultSortBy
      this.navigationOption = {
        pageNum: 1,
        pageSize: 50,
        totalCount: 0
      }
      this.searchFilter.previous = { ...this.searchFilter.current }

      this.loadData()
    },
    setResizing (e, a) {
      if (!this.isResizing) {
        return false
      } else {
        const container = document.querySelector('.template-search-list')
        const offsetRight = container.clientWidth - (e.clientX - container.offsetLeft - 200)
        this.defaultSize = offsetRight
      }
    },
    setTableData (idx, field, data) {
      this.listData[idx][field] = data
    },
    checkItsEmailList () {
      if (this.initialSearchFilter &&
          this.initialSearchFilter.insertedAt &&
          this.initialSearchFilter.insertedAt.itsEmailList) {
        return true
      } else {
        return false
      }
    }
  },
  data () {
    return {
      loading: false,
      columnFixed: null,
      columnData: null,
      listData: null,
      sortBy: this.defaultSortBy,
      navigationOption: {
        pageNum: 1,
        pageSize: 50,
        totalCount: 0
      },

      searchOption: null,
      searchFilter: {
        previous: {},
        current: {}
      },

      selectedUserNum: null,
      show: {
        search: true,
        userCreate: false,
        userDetail: false
      },
      isResizing: false,
      defaultSize: this.menu === 'beusApply' || this.menu === 'baDomain' ? 350 : 250
    }
  }
}
</script>

<style lang="scss" scoped>
.template-search-list {
  .top-area {
    z-index: 20;
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .container {
    position: relative;
    width: 100%;
    height: calc(100vh - 71.64px);
    -webkit-touch-callout: none;

    .table-area {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 250px;
      transition: margin-right 0.3s ease;
    }

    .search-area {
      z-index: 30;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 250px;
      height: calc(100vh - 71.64px);
      background-color: $dimlight;
      transition: right 0.3s ease;

      #drag {
        position: absolute;
        left: -4px;
        top: 0;
        bottom: 0;
        width: 8px;
        cursor: ew-resize;
      }
    }
  }

  &.searching {
    .table-area {
      //margin-right: 250px;
    }

    .search-area {
      right: 0;
    }
  }
}

</style>
