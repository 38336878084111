<template>
  <div class="search-option-wrap">
    <div class="search-title">
      <h2>검색
        <ui-button :color="'gray'"
                   title="초기화"
                   @click="resetFilter">
          <i class="xi-refresh"></i>
        </ui-button>
      </h2>
    </div>
    <div class="search-table">
      <div v-for="[key, option] in Object.entries(searchOption)"
           v-if="!option.hidden"
           class="search-option"
           :key="key">
        <ui-data-input :type="option.type"
                       :label="option.name"
                       :options="option.options"
                       :update="option.update"
                       :required="option.required"
                       :field="key"
                       :model="model"
                       :value="model[key]"
                       :isRangeToday="isRangeToday"
                       :disabled="disabled[key] || false"
                       :itsEmailList="itsEmailList"
                       @input="changeFilter((option.key || key), $event)"
                       @enter="onSearch" />
      </div>
    </div>
    <div class="search-button">
      <ui-button :disabled="isRequiredEmpty"
                 :full="true"
                 @click="onSearch">
        검색
      </ui-button>
    </div>
  </div>
</template>

<script>
import isobject from 'isobject'

import { isIf, isEmptyValue } from '@/utils/tools'

import UiButton from '@/components/_ui/UiButton'
import UiDataInput from '@/components/_ui/UiDataInput'
import { format } from 'date-fns'

export default {
  name: 'UiFilter',
  components: {
    UiButton,
    UiDataInput
  },
  props: {
    searchOption: {
      type: Object,
      required: true
    },
    searchFilter: {
      type: Object,
      required: true
    },
    isRangeToday: {
      required: false,
      default: false
    },
    rangeTodayField: {
      required: false,
      default: false
    },
    itsEmailList: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isRequiredEmpty () {
      for (const [key, option] of Object.entries(this.searchOption)) {
        if (option.required && (this.model[key] === {} || this.model[key] === null || isEmptyValue(this.model[key]))) {
          return true
        }
      }
      return false
    }
  },

  created () {
    this.model = { ...this.searchFilter }
    this.setDefaultValue()
    this.setDisabled()
  },
  methods: {
    setDefaultValue () {
      for (const [key, val] of Object.entries(this.searchOption)) {
        if (val.default !== undefined) {
          this.changeFilter(key, val.default)
        }
      }
    },
    changeFilter (title, value) {
      if (isobject(value)) {
        for (const [key, val] of Object.entries(value)) {
          if (val === '' || isEmptyValue(val)) {
            delete value[key]
          }
        }
      }

      if (value === '' || isEmptyValue(value)) {
        this.$delete(this.model, title)
      } else {
        this.$set(this.model, title, value)
      }
      this.setDisabled()
      this.$emit('updateFilter', this.model, false)
    },
    setDisabled () {
      for (const option of Object.values(this.searchOption)) {
        if (option.if) {
          const ifOption = {
            target: option.if[0],
            option: option.if[1],
            value: option.if[2]
          }
          const splitedType = option.type.split('|')
          const type3 = splitedType[2]
          const previous = this.disabled[option.key]
          this.disabled[option.key] = isIf(this.model[ifOption.target], ifOption.option, ifOption.value)
          if (this.disabled[option.key] && this.disabled[option.key] !== previous) {
            if (type3 === 'range') {
              this.changeFilter(option.key, {})
            } else {
              this.changeFilter(option.key, null)
            }
          }
        }
      }
    },
    onSearch () {
      this.$emit('search')
    },
    resetFilter () {
      this.model = {}
      if (this.isRangeToday && this.rangeTodayField) {
        this.$set(this.model, this.rangeTodayField, {
          start: format(new Date(), 'yyyy-MM-dd'),
          end: format(new Date(), 'yyyy-MM-dd')
        })
      }
      this.setDisabled()
      this.$emit('updateFilter', this.model, true)
    }
  },
  data () {
    return {
      model: {},
      disabled: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.search-option-wrap {
  position: relative;
  height: 100%;

  .search-title {
    h2 {
      padding: 10px 10px 10px 15px;
      font-size: 18px;
      line-height: 34px;
      font-weight: bold;
    }

    .ui-button {
      float: right;
    }
  }

  .search-table {
    overflow: auto;
    height: calc(100% - 114px);
    padding: 0 10px 60px;

    .search-option {
      & ::v-deep label {
        padding-left: 0;
        padding-right: 0;
        min-width: 85px;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      box-shadow: inset 0 0 10px 10px $gray;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $gray;
    }
  }

  .search-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: calc(100% - 20px);
  }
}
</style>
