<template>
  <div class="month_calendar">
    <div class="month_control">
      <button v-if="uiType !== 'right'" type="button" class="prev"
        :class="{ disabled: !enablePrevMonth }"
        @click="addMonthStr(-1)">
        <i class="icon--calendar-month"></i>
      </button>
      <p class="month_label">{{ monthLabel }}</p>
      <button v-if="uiType !== 'left'" type="button" class="next"
        :class="{ disabled: !enableNextMonth }"
        @click="addMonthStr(1)">
        <i class="icon--calendar-month"></i>
      </button>
    </div>
    <table class="date_grid">
      <th v-for="dayName in dayNames" :key="dayName">
        <span>{{ dayName }}</span>
      </th>
      <tr v-for="(week, weekIndex) in dateList" :key="weekIndex">
        <td v-for="(item, index) in week" :key="index"
          :class="item.cssClass"
          @click="onDateClick(item)"
          @mouseenter="onDateMouseEnter(item)">
          <span>{{ item.date.getDate() }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {
  // parse,
  parseISO,
  format,
  startOfMonth,
  lastDayOfMonth,
  addDays,
  // addMonths,
  isBefore,
  isSameDay,
  startOfDay,
  endOfDay
} from 'date-fns'
import { partition } from '@/libs/utils'
import { DEFAULT_TIMEZONE, DEFAULT_TIMEZONE_NAME } from '@/utils/constants'

const DAYS_IN_WEEK = 7
const DAY_OF_SATURDAY = 6
// todo: i18n check
const dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

export default {
  name: 'MonthCalendar',
  props: {
    uiType: {
      type: String,
      default: 'single'
    },
    monthStr: {
      type: String,
      required: true
    },
    targetDate: {
      type: Date,
      required: false
    },
    minDate: {
      type: Date,
      required: false
    },
    maxDate: {
      type: Date,
      required: false
    },
    previewEndDate: {
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    timezone: {
      type: String,
      default: DEFAULT_TIMEZONE
    },
    timezoneName: {
      type: String,
      default: DEFAULT_TIMEZONE_NAME
    }
  },
  data () {
    return {}
  },
  computed: {
    computedTargetDate () {
      if (this.targetDate) {
        return this.targetDate
      } else {
        return null
      }
    },
    monthLabel () {
      return format(this.monthDate, 'MMM yyyy')
    },
    monthDate () {
      let date = parseISO(this.monthStr)
      return date
      // return utcToLocalTimezone(date, this.timezoneName)
    },
    dateList () {
      let items = []
      const startDate = startOfMonth(this.monthDate)
      const endDate = lastDayOfMonth(this.monthDate)
      const startDayOfWeek = startDate.getDay()
      const endDayOfWeek = endDate.getDay()

      const startDateOffset = -startDayOfWeek
      let endDateOffset = endDate.getDate() - 1 + (DAY_OF_SATURDAY - endDayOfWeek)

      if (this.uiType !== 'single') {
        const dateSize = endDateOffset - startDateOffset + 1
        const weekSize = dateSize / DAYS_IN_WEEK
        const WEEKS_TO_DISPLAY = 6

        if (weekSize < WEEKS_TO_DISPLAY) {
          const daysToAdd = (WEEKS_TO_DISPLAY - weekSize) * DAYS_IN_WEEK
          endDateOffset += daysToAdd
        }
      }

      for (let i = startDateOffset; i <= endDateOffset; i++) {
        const date = addDays(startDate, i)
        items.push({
          date,
          cssClass: this.getDateClass(date)
        })
      }

      items = partition(items, DAYS_IN_WEEK)
      return items
    },
    enableNextMonth () {
      if (this.disabled) {
        return false
      }

      let result = true

      if (this.maxDate) {
        const endDate = lastDayOfMonth(this.monthDate)
        result = isBefore(endDate, this.maxDate)
      }
      return result
    },
    enablePrevMonth () {
      if (this.disabled) {
        return false
      }

      let result = true

      if (this.minDate) {
        const startDate = startOfMonth(this.monthDate)
        result = isBefore(this.minDate, startDate)
      }
      return result
    }
  },
  created () {
    this.dayNames = dayNames
  },
  methods: {
    getDateClass (date) {
      let isInMonth = date.getMonth() === this.monthDate.getMonth()
      let isToday = false
      let isDisabled = false
      let isInRange = false
      let isStartDate = false
      let isEndDate = false
      let isTargetDate = false
      let isMonthStartDay = false
      let isMonthLastDay = false

      if (isSameDay(date, new Date())) {
        isToday = true
      }

      if (this.computedTargetDate && isSameDay(date, this.computedTargetDate)) {
        isTargetDate = true
      }

      if (this.minDate && isBefore(date, startOfDay(this.minDate))) {
        isDisabled = true
      }

      if (this.maxDate && isBefore(endOfDay(this.maxDate), date)) {
        isDisabled = true
      }

      if (isSameDay(date, startOfMonth(this.monthDate))) {
        isMonthStartDay = true
      }

      if (isSameDay(date, lastDayOfMonth(this.monthDate))) {
        isMonthLastDay = true
      }

      let isActive = isStartDate || isEndDate

      return {
        off: !isInMonth || isDisabled || this.disabled,
        today: isToday,
        available: !isDisabled && !this.disabled,
        disabled: isDisabled || this.disabled,
        active: isActive,
        target: isTargetDate,
        'in-range': isInRange,
        'start-date': isStartDate,
        'end-date': isEndDate,
        'month-start-day': isMonthStartDay,
        'month-last-day': isMonthLastDay
      }
    },
    addMonthStr (months) {
      this.$emit('onMonthAdd', months)
    },
    onDateClick (item) {
      if (item.cssClass.disabled) {
        return
      }
      this.$emit('onDateClick', this.uiType, item.date)
    },
    onDateMouseEnter (item) {
      if (this.uiType === 'single' || item.cssClass.disabled) {
        return
      }
      if (this.dateRange.startDate && !this.dateRange.endDate &&
        isBefore(this.dateRange.startDate, item.date)) {
        this.$emit('onRangePreviewChange', this.uiType, item.date)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
