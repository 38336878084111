import { request } from '@/utils/api-request'

export function requestCapture (params) {
  const url = '/admin/capture/recapture'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyList (params) {
  const url = '/admin/beus/apply/apply_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyInsertMeta (force) {
  const url = '/admin/beus/apply/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function getBeusApplyBulkInsertMeta (force) {
  const url = '/admin/beus/apply/insert/bulk'
  const config = {
    method: 'get',
    url
  }
  return request(config, true)
}

export function insertBeusApply (params) {
  const url = '/admin/beus/apply/insert'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function insertBeusApplyBulk (params) {
  const url = '/admin/beus/apply/insert/bulk'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyInfo (params) {
  const url = '/admin/beus/apply/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function deleteBeusApplyHtml (params) {
  const url = '/admin/beus/apply/reset_html'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusToolApplyList (params) {
  const url = '/admin/beus/apply/tool_apply_list_by_url'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyExcelMeta (force) {
  const url = '/admin/beus/apply/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function validateUrlTimezone (params) {
  const url = '/admin/beus/apply/validate_timezone'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
