import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import { getBeusPlanList, getBaPlanList, filterPlanList, filterPlanListByGroup } from '@/api/plan'
import { checkAuth } from '@/utils/auth'
import { alertError } from '@/utils/tools'

import Blank from '@/components/Blank'
import SignIn from '@/components/SignIn'
import Dashboard from '@/components/dashboard/Dashboard'

import BeusMonitoringPayment from '@/components/monitoring/beus/Payment'
import BaMonitoringPayment from '@/components/monitoring/ba/Payment'
import BeusMonitoringPaidUser from '@/components/monitoring/beus/PaidUser'
import BaMonitoringPaidUser from '@/components/monitoring/ba/PaidUser'
import BeusMonitoringPaymentError from '@/components/monitoring/beus/PaymentError'
import BaMonitoringPaymentError from '@/components/monitoring/ba/PaymentError'
import BeusMonitoringCapture from '@/components/monitoring/beus/Capture'
import SSBeusMonitoringCode from '@/components/monitoring/ssbeus/Code'

import BeusUserList from '@/components/user/beus/UserList'
import SsbeusUserList from '@/components/user/ssbeus/UserList'

import BeusUnauthList from '@/components/user/beus/UnauthList'
import SsbeusUnauthList from '@/components/user/ssbeus/UnauthList'

import BeusApplyList from '@/components/service/beus/ApplyList'
import BeusApplyListAB from '@/components/service/beus/ApplyListAB'
import BaApplyList from '@/components/service/ba/BaApplyList'
import BaDomainList from '@/components/service/ba/DomainList'
import BaSitemapList from '@/components/service/ba/SitemapList'
import BaSurveyList from '@/components/service/ba/SurveyList'
import SsbeusApplyList from '@/components/service/ssbeus/ApplyList'

import BeusPlanList from '@/components/plan/beus/PlanList'
import BaPlanList from '@/components/plan/ba/PlanList'
import BeusPaymentList from '@/components/payment/beus/PaymentList'
import BaPaymentList from '@/components/payment/ba/PaymentList'
import JamPaymentList from '@/components/payment/jam/PaymentList'
import JamList from '@/components/payment/jam/JamList'
import BaAiReportList from '@/components/mine/ba/AiReportList'

import BeusCreditList from '@/components/credit/beus/CreditList'
import BaCreditList from '@/components/credit/ba/CreditList'

import LoginHistoryList from '@/components/loginHistory/LoginHistoryList'
import BeusLoginHistoryDashboard from '@/components/loginHistory/beus/LoginHistoryDashboard'
import BaLoginHistoryDashboard from '@/components/loginHistory/ba/LoginHistoryDashboard'

import ContactEmailList from '@/components/contact/EmailList'
import ContactUsHistoryList from "@/components/contact/ContactUsHistoryList";

import BeusMarketingCouponList from '@/components/marketing/coupon/beus/CouponList'
import MarketingEventList from '@/components/marketing/EventList'
import MarketingPopupList from '@/components/marketing/popup/PopupList'
import BeusMarketingFaqList from '@/components/marketing/faq/beus/FaqList'
import BaMarketingFaqList from '@/components/marketing/faq/ba/FaqList'

import ToolGetAid from '@/components/tool/ToolGetAid'

import AWSSecurityGroup from '@/components/dev/aws/SecurityGroupList'
import BeungFirewall from '@/components/dev/beung/firewall/FirewallList'

import Dev from '@/components/dev'
import StorageList from '@/components/storage/StorageList'

import JenkinsLsit from '@/components/dev/jenkins/list'
import RawDataBaList from '@/components/dev/rawData/ba/list.vue'
import RawDataBeusList from '@/components/dev/rawData/beus/list.vue'

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "SignIn",
      component: SignIn,
      meta: {
        layout: "empty",
        guest: true,
      },
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        layout: "default",
        index: true,
        member: true,
        title: "Dashboard",
      },
      props: { isFull: false },
    },
    {
      path: "/dashboard/full",
      name: "DashboardFull",
      component: Dashboard,
      meta: {
        layout: "empty",
        index: true,
        member: true,
        title: "DashboardFull",
      },
      props: { isFull: true },
    },
    {
      path: "/monitoring",
      name: "Monitoring",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      children: [
        {
          path: "/monitoring/payment",
          name: "MonitoringPayment",
          component: Blank,
          meta: {
            layout: "default",
            member: true,
          },
          redirect: { name: "MonitoringBeusPayment" },
          children: [
            {
              path: "/monitoring/payment/beus",
              name: "MonitoringBeusPayment",
              component: BeusMonitoringPayment,
              props: true,
              meta: {
                layout: "default",
                member: true,
                title: "플랜 생성 결과",
                beus: true,
              },
            },
            {
              path: "/monitoring/payment/ba",
              name: "MonitoringBaPayment",
              component: BaMonitoringPayment,
              props: true,
              meta: {
                layout: "default",
                member: true,
                title: "플랜 생성 결과",
                ba: true,
              },
            },
          ],
        },
        {
          path: "/monitoring/paid_user",
          name: "MonitoringPaidUser",
          component: Blank,
          redirect: { name: "MonitoringBeusPaidUser" },
          children: [
            {
              path: "/monitoring/paid_user/beus",
              name: "MonitoringBeusPaidUser",
              component: BeusMonitoringPaidUser,
              meta: {
                layout: "default",
                member: true,
                title: "유료 사용자 현황",
                beus: true,
              },
            },
            {
              path: "/monitoring/paid_user/ba",
              name: "MonitoringBaPaidUser",
              component: BaMonitoringPaidUser,
              meta: {
                layout: "default",
                member: true,
                title: "유료 사용자 현황",
                ba: true,
              },
            },
          ],
        },
        {
          path: "/monitoring/payment_error",
          name: "MonitoringPaymentError",
          component: Blank,
          redirect: { name: "MonitoringBeusPaymentError" },
          children: [
            {
              path: "/monitoring/payment_error/beus",
              name: "MonitoringBeusPaymentError",
              component: BeusMonitoringPaymentError,
              meta: {
                layout: "default",
                member: true,
                title: "플랜 생성 오류",
                beus: true,
              },
            },
            {
              path: "/monitoring/payment_error/ba",
              name: "MonitoringBaPaymentError",
              component: BaMonitoringPaymentError,
              meta: {
                layout: "default",
                member: true,
                title: "플랜 생성 오류",
                ba: true,
              },
            },
          ],
        },
        {
          path: "/monitoring/capture",
          name: "MonitoringCapture",
          component: Blank,
          redirect: { name: "MonitoringBeusCapture" },
          children: [
            {
              path: "/monitoring/capture/beus",
              name: "MonitoringBeusCapture",
              component: BeusMonitoringCapture,
              meta: {
                layout: "default",
                member: true,
                title: "캡처",
                beus: true,
              },
            },
          ],
        },
        {
          path: "/monitoring/code",
          name: "MonitoringCode",
          component: Blank,
          redirect: { name: "MonitoringSSBeusCode" },
          children: [
            {
              path: "/monitoring/code/ssbeus",
              name: "MonitoringSSBeusCode",
              component: SSBeusMonitoringCode,
              meta: {
                layout: "default",
                member: true,
                title: "코드 설치 현황",
                ssbeus: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/jam",
      name: "Jam",
      component: JamList,
      meta: {
        layout: "default",
        member: true,
        title: "Jam",
      },
    },
    {
      path: "/user",
      name: "Account",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
        title: "Account",
      },
      redirect: { name: "ServiceBeusAccount" },
      children: [
        {
          path: "/account/beus",
          name: "ServiceBeusAccount",
          component: BeusUserList,
          meta: {
            layout: "default",
            member: true,
            title: "Account",
            beus: true,
          },
        },
        {
          path: "/account/ssbeus",
          name: "ServiceSsbeusAccount",
          component: SsbeusUserList,
          meta: {
            layout: "default",
            member: true,
            title: "Account",
            ssbeus: true,
          },
        },
        {
          path: "/account/unauth/beus",
          name: "ServiceBeusUnAuthAccount",
          component: BeusUnauthList,
          meta: {
            layout: "default",
            member: true,
            title: "미인증 계정",
            beus: true,
          },
        },
        {
          path: "/account/unauth/ssbeus",
          name: "ServiceSsbeusUnAuthAccount",
          component: SsbeusUnauthList,
          meta: {
            layout: "default",
            member: true,
            title: "미인증 계정",
            ssbeus: true,
          },
        },
      ],
    },
    {
      path: "/service",
      name: "Service",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      redirect: { name: "ServiceBeusApply" },
      children: [
        {
          path: "/service/beus",
          name: "ServiceBeusApply",
          component: BeusApplyList,
          meta: {
            layout: "default",
            member: true,
            title: "Apply",
            beus: true,
          },
        },
        {
          path: "/ab",
          name: "ServiceBeusApplyAB",
          component: BeusApplyListAB,
          meta: {
            layout: "default",
            member: true,
            title: "A/B Test",
            beus: true,
          },
        },
        {
          path: "/heatmapLight",
          name: "ServiceBaApply",
          component: BaApplyList,
          meta: {
            layout: "default",
            member: true,
            title: "Heatmap Light",
            ba: true,
          },
        },
        {
          path: "/service/ba/domain",
          name: "ServiceBaDomain",
          component: BaDomainList,
          meta: {
            layout: "default",
            member: true,
            title: "Domain",
            ba: true,
          },
        },
        {
          path: "/service/ba/sitemap",
          name: "ServiceBaSitemap",
          component: BaSitemapList,
          meta: {
            layout: "default",
            member: true,
            title: "Domain Sitemap",
            ba: true,
          },
          props: true,
        },
        {
          path: "/service/ba/survey",
          name: "ServiceBaSurvey",
          component: BaSurveyList,
          meta: {
            layout: "default",
            member: true,
            title: "Domain Survey",
            ba: true,
          },
          props: true,
        },
        {
          path: "/service/ssbeus",
          name: "ServiceSsbeusApply",
          component: SsbeusApplyList,
          meta: {
            layout: "default",
            member: true,
            title: "Apply",
            ssbeus: true,
          },
        },
      ],
    },
    {
      path: "/plan",
      name: "Plan",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      redirect: { name: "PlanBeus" },
      children: [
        {
          path: "/plan/beus",
          name: "PlanBeus",
          component: BeusPlanList,
          meta: {
            layout: "default",
            member: true,
            title: "Plan",
            beus: true,
          },
        },
        {
          path: "/plan/ba",
          name: "PlanBa",
          component: BaPlanList,
          meta: {
            layout: "default",
            member: true,
            title: "Plan",
            ba: true,
          },
        },
      ],
    },
    {
      path: "/payment",
      name: "Payment",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      redirect: { name: "PaymentBeus" },
      children: [
        {
          path: "/payment/jam",
          name: "PaymentJam",
          component: JamPaymentList,
          meta: {
            layout: "default",
            member: true,
            title: "Payment",
            jam: true,
          },
        },
        {
          path: "/payment/beus",
          name: "PaymentBeus",
          component: BeusPaymentList,
          meta: {
            layout: "default",
            member: true,
            title: "Payment",
            beus: true,
          },
        },
        {
          path: "/payment/ba",
          name: "PaymentBa",
          component: BaPaymentList,
          meta: {
            layout: "default",
            member: true,
            title: "Payment",
            ba: true,
          },
        },
      ],
    },
    {
      path: "/credit",
      name: "Credit",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      redirect: { name: "CreditBeus" },
      children: [
        {
          path: "/credit/beus",
          name: "CreditBeus",
          component: BeusCreditList,
          meta: {
            layout: "default",
            member: true,
            title: "Credit",
            beus: true,
          },
        },
        {
          path: "/credit/ba",
          name: "CreditBa",
          component: BaCreditList,
          meta: {
            layout: "default",
            member: true,
            title: "Credit",
            beus: true,
          },
        },
      ],
    },
    {
      path: "/mine",
      name: "Mine",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      redirect: { name: "MineBa" },
      children: [
        {
          path: "/mine/ba",
          name: "MineBa",
          component: BaAiReportList,
          meta: {
            layout: "default",
            member: true,
            title: "Mine",
            ba: true,
          },
        },
      ],
    },
    {
      path: "/login_history",
      name: "LoginHistory",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
        title: "Login History",
      },
      children: [
        {
          path: "/login_history/list",
          name: "LoginHistoryList",
          component: LoginHistoryList,
          meta: {
            layout: "default",
            member: true,
            title: "방문 이력",
          },
        },
        {
          path: "/login_history/dashboard",
          name: "loginHistoryDashboard",
          component: Blank,
          meta: {
            layout: "default",
            member: true,
          },
          redirect: { name: "LoginHistoryBeusDashboard" },
          children: [
            {
              path: "/login_history/dashboard/beus",
              name: "LoginHistoryBeusDashboard",
              component: BeusLoginHistoryDashboard,
              props: true,
              meta: {
                layout: "default",
                member: true,
                title: "로그인 이력",
                beus: true,
              },
            },
            {
              path: "/login_history/dashboard/ba",
              name: "LoginHistoryBaDashboard",
              component: BaLoginHistoryDashboard,
              props: true,
              meta: {
                layout: "default",
                member: true,
                title: "로그인 이력",
                ba: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/contact",
      name: "Contact",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      children: [
        {
          path: "/contact/email",
          name: "ContactEmail",
          component: ContactEmailList,
          meta: {
            layout: "default",
            member: true,
            title: "Email",
          },
        },
        {
          path: "/contact/contactUs",
          name: "ContactUs",
          component: ContactUsHistoryList,
          meta: {
            layout: "default",
            member: true,
            title: "ContactUs 대화이력",
          },
        },
      ],
    },
    {
      path: "/marketing",
      name: "Marketing",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      children: [
        {
          path: "/marketing/coupon",
          name: "MarketingCoupon",
          component: Blank,
          meta: {
            layout: "default",
            member: true,
          },
          redirect: { name: "MarketingCouponBeus" },
          children: [
            {
              path: "/marketing/coupon/beus",
              name: "MarketingCouponBeus",
              component: BeusMarketingCouponList,
              props: true,
              meta: {
                layout: "default",
                member: true,
                title: "Coupon",
                beus: true,
              },
            },
          ],
        },
        {
          path: "/marketing/event",
          name: "MarketingEvent",
          component: MarketingEventList,
          meta: {
            layout: "default",
            member: true,
            title: "Event",
          },
        },
        {
          path: "/marketing/popup",
          name: "MarketingPopup",
          component: Blank,
          meta: {
            layout: "default",
            member: true,
          },
          redirect: { name: "MarketingPopupBeus" },
          children: [
            {
              path: "/marketing/popup/beus",
              name: "MarketingPopupBeus",
              component: MarketingPopupList,
              props: true,
              meta: {
                layout: "default",
                member: true,
                title: "Popup",
                beus: true,
              },
            },
            {
              path: "/marketing/popup/ba",
              name: "MarketingPopupBa",
              component: MarketingPopupList,
              props: true,
              meta: {
                serviceType: "ba",
                layout: "default",
                member: true,
                title: "Popup",
                ba: true,
              },
            },
          ],
        },
        {
          path: "/marketing/faq",
          name: "MarketingFaq",
          component: Blank,
          meta: {
            layout: "default",
            member: true,
          },
          redirect: { name: "MarketingFaqBeus" },
          children: [
            {
              path: "/marketing/faq/beus",
              name: "MarketingFaqBeus",
              component: BeusMarketingFaqList,
              meta: {
                layout: "default",
                member: true,
                title: "FAQ",
                beus: true,
              },
            },
            {
              path: "/marketing/faq/ba",
              name: "MarketingFaqBa",
              component: BaMarketingFaqList,
              meta: {
                layout: "default",
                member: true,
                title: "FAQ",
                ba: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/tool",
      name: "Tool",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      children: [
        {
          path: "/tool/get_aid_by_url",
          name: "ToolGetAid",
          component: ToolGetAid,
          meta: {
            layout: "default",
            member: true,
            title: "페이지 등록 확인",
            beu: true,
          },
        },
      ],
    },
    {
      path: "/rawData",
      name: "RawData",
      component: Blank,
      meta: {
        layout: "default",
        member: true,
      },
      redirect: { name: "RawDataBa" },
      children: [
        {
          path: "/rawData/beus",
          name: "RawDataBeus",
          component: RawDataBeusList,
          props: true,
          meta: {
            layout: "default",
            member: true,
            title: "Raw Data",
            beus: true,
          },
        },
        {
          path: "/rawData/ba",
          name: "RawDataBa",
          component: RawDataBaList,
          props: true,
          meta: {
            serviceType: "ba",
            layout: "default",
            member: true,
            title: "Raw Data",
            ba: true,
          },
        },
      ],
    },
    {
      path: "/storage",
      name: "Storage",
      component: StorageList,
      meta: {
        layout: "default",
        member: true,
        title: "Storage",
      },
    },
    {
      path: "/dev",
      name: "DevOnly",
      component: Dev,
      meta: {
        layout: "default",
        member: true,
        requireDev: true,
      },
      children: [
        {
          path: "/dev/jenkins",
          name: "JenkinsList",
          component: JenkinsLsit,
          meta: {
            layout: "default",
            member: true,
            title: "Jenkins List",
            beu: true,
            requireDev: true,
          },
        }
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  checkAuth().then(userData => {
    if (to.meta.guest) {
      if (userData) {
        next({ name: 'Dashboard' })
      } else {
        next()
      }
    } else if (to.meta.member) {
      if (!userData) {
        next({ name: 'SignIn' })
      } else {
        const getPlanList = [
          new Promise((resolve, reject) => {
            if (!store.state.beusPlanData) {
              getBeusPlanList().then(response => {
                store.commit('setBeusPlanData', filterPlanList(response.result, 'beusable'))
                store.commit('setBeusPlanDataByGroup', filterPlanListByGroup(response.result, 'beusable'))
                resolve()
              }).catch(error => {
                if (!error.response) {
                  alertError('API를 불러오지 못 했습니다.', error)
                } else if (error.response.status !== 401) {
                  alertError('Beusable 플랜 목록을 불러오지 못 했습니다.', error)
                }
                reject(error)
              })
            } else {
              resolve()
            }
          }),
          new Promise((resolve, reject) => {
            if (!store.state.baPlanData) {
              getBaPlanList().then(response => {
                store.commit('setBaPlanData', filterPlanList(response.result, 'ba'))
                store.commit('setBaPlanDataByGroup', filterPlanListByGroup(response.result, 'ba'))
                resolve()
              }).catch(error => {
                if (!error.response) {
                  alertError('API를 불러오지 못 했습니다.', error)
                } else if (error.response.status !== 401) {
                  alertError('BA 플랜 목록을 불러오지 못 했습니다.', error)
                }
                reject(error)
              })
            } else {
              resolve()
            }
          })
        ]
        Promise.all(getPlanList).finally(() => { next() })
      }
    } else {
      next()
    }
  }).catch(() => {
    next({ name: 'SignIn' })
  })
})

export default router
