<template>
  <ui-side-modal v-show="show"
                 class="template-detail"
                 :width="modalWidth"
                 @close="closeModal">
    <ui-detail v-if="dataOption && dataInHand"
               :meta="dataOption"
               :data="dataInHand"
               :error="error"
               :editMode="editMode"
               @set="setData"
               @error="setError" >
      <ui-button :color="'purple'" v-if="isResend" @click="onMailResend">
        <i class="xi-mail"></i>재발송
      </ui-button>
    </ui-detail>
  </ui-side-modal>
</template>

<script>
import ejs from 'ejs'
import { EventBus } from '@/utils/event-bus'
import { alertError } from '@/utils/tools'

import UiDetail from '@/components/_ui/UiDetail'
import UiButton from '@/components/_ui/UiButton'
import UiSideModal from '@/components/_ui/UiSideModal'

import i18n from '@/libs/text/mailer-i18n-messages.json'
import oldBeusableEmailText from '@/libs/text/old/beusable/email_text.json'
import oldBeusablyEmailText from '@/libs/text/old/beusably/email_text.json'
import { searchUser } from '@/api/user'
import { emailResend } from '@/api/contact'

export default {
  name: 'EmailDetail',
  components: {
    UiDetail,
    UiButton,
    UiSideModal
  },
  props: {
    infoApi: {
      required: true
    },
    editApi: {
      required: false
    },
    removeApi: {
      required: false
    },
    apiParam: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    }
  },
  computed: {
    modalWidth () {
      return 1200
    }
  },
  created () {
    if (this.apiParam) {
      this.loadData()
    }
  },
  watch: {
    apiParam (value) {
      this.editMode = false
      if (value) {
        this.loadData()
      }
    },
    editMode (value) {
      if (value) {
        this.setModel()
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    onMailResend () {
      console.log(this.dataInHand)
      EventBus.$emit('onLoading', true)
      emailResend({ data: this.dataInHand }).then(response => {
        console.log(response)
      }).catch(error => {
        alertError('메일 재발송에 실패했습니다.', error)
      }).finally(() => {
        EventBus.$emit('onLoading', false)
      })
    },
    loadData (callback = null) {
      this.error = {}
      this.show = false
      EventBus.$emit('onLoading', true)
      setTimeout(() => {
        this.infoApi(this.apiParam, true).then(response => {
          this.dataInHand = response.result.info || null
          this.dataOption = response.result.meta || null
          console.log(this.dataInHand)
          if (this.dataInHand.templateName) {
            const targetTemplateName = ['signupconfirm', 'resetpassword']
            if (targetTemplateName.includes(this.dataInHand.templateName.toLowerCase())) {
              this.isResend = true
            } else {
              this.isResend = false
            }
          }
          if (this.dataInHand.template) {
            this.setContentRendering()
          }
          this.show = true
        }).catch(error => {
          alertError('상세 데이터를 불러오지 못 했습니다.', error)
        }).finally(() => {
          if (callback) {
            callback()
          }
          EventBus.$emit('onLoading', false)
        })
      }, 300)
    },
    setContentRendering () {
      const result = this.dataInHand

      let template = null
      let layout = null
      let templateEjs = null
      let serviceType = null

      if (result.template) {
        template = result.template
        templateEjs = template.ejs
      }

      if (result.templateLayout) {
        layout = result.templateLayout
        const replaceKey = layout.replaceKey
        const layoutEjs = layout.ejs
        const tempEjs = layoutEjs.replace(replaceKey, templateEjs)
        template.ejs = tempEjs

        serviceType = result.ejs.serviceType || result.ejs.companyType || 'beusable'
        let ejsTemplateName = result.templateName
        if (result.templateName === 'planApplied') {
          ejsTemplateName = 'planUpgrade'
        }
        const emailText = i18n.messages[serviceType][ejsTemplateName]
        emailText.common = i18n.messages[serviceType].common
        result.ejs.emailText = emailText
      }

      if (result.templateName === 'oldUser' ||
        result.templateName === 'weeklyReport' ||
        result.templateName === 'planValidationCheck' ||
        result.templateName === 'longTermInactiveUser'
      ) {
        result.ejs.emailText = result.serviceType === 'beusably' ? oldBeusablyEmailText : oldBeusableEmailText
      }

      if (result.templateName === 'baPaymentSuccess') {
        result.ejs.userName = ''
        const userId = result.receiverEmail
        let userName = ''
        searchUser({
          userId: userId,
          serviceType: 'ba'
        }).then(response => {
          if (response.success) {
            const user = response.result.info
            userName = user.userName
          }
        }).catch(e => {
          userName = ''
        }).finally(() => {
          result.ejs.userName = userName
          this.setEjsRender(template, result)
        })
      } else {
        this.setEjsRender(template, result)
      }
    },
    setEjsRender (template, result) {
      let html = ''
      if (result.success && template.ejs && result.ejs) {
        html = ejs.render(template.ejs, result.ejs)
      }
      if (!result.success) {
        html = result.data
      }
      // this.dataInHand.content = html
      this.$set(this.dataInHand, 'content', html)
    },
    setModel () {
      this.model = {}
      for (const [field, option] of Object.entries(this.dataOption.field)) {
        if (option.key || option.editable) {
          this.model[option.field || field] = this.dataInHand[option.field || field]
        }
      }
    },
    setData (data) {

    },
    setError (key, message) {
      this.$set(this.error, key, message)
    },
    closeModal () {
      this.show = false
      this.$emit('close')
    } 
  },
  data () {
    return {
      dataInHand: null,
      dataOption: null,
      editMode: false,
      isResend: false,
      show: false,
      model: {
        serviceType: this.$route.meta.ba ? 'ba' : 'beusable'
      },
      error: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .remove-button {
    opacity: 0.5;
  }
}
</style>
