<template>
  <ui-side-modal v-show="showing"
                 :width="10000"
                 class="template-detail"
                 @close="closeModal">
    <ui-table-form v-if="dataOption"
                   :meta.sync="dataOptionList"
                   :data="model"
                   :error="error"
                   @set="setData"
                   @error="setError"
                   @input="input"
                   @delete="removeRow">
      <slot></slot>
      <input type="file"
             class="a11y-invisible"
             ref="file"
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
             @change="readFile">
      <ui-button :color="'purple'"
                 @click="addFile">
        <i class="xi-upload"></i> 파일 추가
      </ui-button>
      <ui-button :color="'green'"
                 @click="addRow">
        <i class="xi-plus"></i> 행 추가
      </ui-button>
      <ui-button :color="'gray'"
                 @click="save(false)">
        <i class="xi-close"></i> 취소
      </ui-button>
      <ui-button :color="'red'"
                 :disabled="model.length < 1"
                 @click="save(true)">
        <i class="xi-pen"></i> 저장
      </ui-button>
    </ui-table-form>
  </ui-side-modal>
</template>

<script>
import isobject from 'isobject'
import XLSX from 'xlsx'
import { EventBus } from '@/utils/event-bus'
import { alertError, isEndWithConsonant } from '@/utils/tools'
import { getTimezoneOffset } from 'date-fns-tz'

import UiTableForm from '@/components/_ui/UiTableForm'
import UiButton from '@/components/_ui/UiButton'
import UiSideModal from '@/components/_ui/UiSideModal'
import { create } from 'vue-modal-dialogs'
import AppDialog from '@/components/_ui/AppDialog'
import { addHours } from '@/utils/date-util'
import { isValidDomain } from '@/libs/validation-helper'
import { DEFAULT_TIMEZONE_NAME } from '@/utils/constants'
import { startOfDay } from 'date-fns'

const parser = require('tld-extract')
const openTimezone = create(AppDialog, 'customData', 'contentStyle', 'ok')

export default {
  name: 'TemplateInsertBulk',
  components: {
    UiTableForm,
    UiButton,
    UiSideModal
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    metaApi: {
      required: true
    },
    insertApi: {
      required: true
    },
    menu: {
      default: false
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.loadMeta()
      } else {
        this.showing = false
        this.model = []
      }
    }
  },
  created () {
    this.showing = this.show
  },
  methods: {
    loadMeta (callback = null) {
      this.error = {}
      this.showing = false
      EventBus.$emit('onLoading', true)
      setTimeout(() => {
        this.metaApi().then(response => {
          this.dataOption = response.result || null
          this.showing = true
          if (this.dataOption) {
            for (const [field, option] of Object.entries(this.dataOption)) {
              if (option.default) {
                for (let i = 0; i < this.model.length; i++) {
                  this.$set(this.model[i], option.field || field, option.default)
                }
              }
            }
          }
        }).catch(error => {
          alertError('메타 데이터를 불러오지 못 했습니다.', error)
        }).finally(() => {
          if (callback) {
            callback()
          }
          EventBus.$emit('onLoading', false)
        })
      }, 300)
    },
    setData (idx, data) {
      if (this.dataOption) {
        for (const [field, option] of Object.entries(this.dataOption)) {
          const key = option.field || field
          if (key.includes('.')) {
            const splitedKey = key.split('.')
            const key1 = splitedKey[0]
            const key2 = splitedKey[1]
            if (data[key1] && data[key1][key2] !== undefined) {
              this.$set(this.model[idx], key, data[key1][key2])
            } else if (option.static) {
              this.$delete(this.model[idx], key)
            }
          } else {
            if (data[key] !== undefined) {
              this.$set(this.model[idx], key, data[key])
            } else if (option.static) {
              this.$delete(this.model[idx], key)
            }
          }
        }
      }
    },
    setError (idx, key, message) {
      if (!this.error[idx]) {
        this.$set(this.error, idx, {})
      }
      this.$set(this.error[idx], key, message)
    },
    checkRequired () {
      if (this.dataOption) {
        for (const [field, option] of Object.entries(this.dataOption)) {
          const key = option.field || field
          for (let i = 0; i < this.model.length; i++) {
            if (this.dataOptionList[i][key].required && this.model[i] && !this.model[i][key] && this.model[i][key] !== 0) {
              if (!this.error[i]) {
                this.$set(this.error, i, {})
              }
              this.$set(this.error[i], key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 필수입니다.`)
            }
          }
        }
      }
      for (let i = 0; i < this.model.length; i++) {
        if (this.error[i]) {
          for (const message of Object.values(this.error[i])) {
            if (message) {
              return false
            }
          }
        }
      }
      return true
    },
    openTimezoneConfirm (result) {
      const params = {
        timezoneChangedList: result.timezone_changed_list
      }
      return openTimezone({
        type: 'alert',
        title: 'eagle.beusable.net 내용:',
        description: '입력한 URL ' + params.timezoneChangedList.length + '개가 유효한 타임존으로 변경되었습니다.',
        data: params,
        from: 'timezone'
      }, {
        padding: '50px 30px 25px'
      },
      '확인'
      ).then(result => {
        return result
      })
    },
    save (save = false) {
      let confirmed = false
      if (this.checkRequired()) {
        confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
      } else {
        if (!save) {
          confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
        }
      }

      if (confirmed) {
        if (save) {
          this.insertApi({ data: this.model }, true).then(async response => {
            if (response.result.timezone_changed_list) {
              await this.openTimezoneConfirm(response.result)
            }
            this.domainsIndex = {}
            this.indexTimezone = {}
            this.dataOptionList = []
            this.$emit('close')
            this.$emit('saved', response.result)
          }).catch(error => {
            const message = error.response ? error.response.data.message : false
            if (isobject(message)) {
              const idx = message.idx || 0
              if (!this.error[idx]) {
                this.$set(this.error, idx, {})
              }
              for (const [key, value] of Object.entries(message)) {
                if (key !== 'idx') {
                  this.$set(this.error[idx], key, value)
                }
              }
            }
            alertError('저장에 실패했습니다.', error)
          }).finally(() => {
            this.$emit('reload')
            EventBus.$emit('onLoading', false)
          })
        } else {
          this.domainsIndex = {}
          this.indexTimezone = {}
          this.$emit('close')
        }
      }
    },
    input (idx, field, value) {
      if (this.model[idx]) {
        if (this.error[idx]) {
          this.$delete(this.error[idx], field)
        }

        if (this.menu === 'apply' || this.menu === 'ssapply') {
          if (field === 'url' && value) {
            const matchType = this.model[idx].matchType
            const targetMatchType = ['contains', 'regexp', 'end']

            if (matchType && targetMatchType.includes(matchType)) {
              if (!isValidDomain(value)) {
                this.setError(idx, field, '도메인 주소만 입력해주세요')
              }
            }
          }

          if (field === 'pvLimit') {
            if (value === 0) {
              this.setError(idx, field, '0은 입력이 불가합니다.')
            }
          }

          if ((field === 'startDate' || field === 'endDate' || field === 'regDate') && value) {
            const now = new Date()
            if (this.model[idx].timezoneName !== DEFAULT_TIMEZONE_NAME) {
              const tz = getTimezoneOffset(this.model[idx].timezoneName) / 60 / 60 / 1000
              now.setHours(now.getHours() + tz - 9)
              const valueDate = new Date(value)

              if (startOfDay(valueDate) < startOfDay(now)) {
                this.setError(idx, field, '지난 날짜는 설정할 수 없습니다')
              }
            } else {
              now.setDate(now.getDate() - 1)
              if (new Date(value) < now) {
                this.setError(idx, field, '지난 날짜는 설정할 수 없습니다')
              }
            }
          }

          if (field === 'matchType' && value) {
            if (this.error[idx] && this.error[idx].url) {
              delete this.error[idx].url
            }
            const matchType = value
            const targetMatchType = ['contains', 'regexp', 'end']

            if (targetMatchType.includes(matchType)) {
              const url = this.model[idx].url
              if (url && !isValidDomain(url)) {
                this.setError(idx, 'url', '도메인 주소만 입력해주세요')
              }

              this.dataOptionList[idx].matchString.required = true
              // const temp = JSON.parse(JSON.stringify(this.dataOptionList[idx]))
              // temp.matchString.required = true
              this.$set(this.dataOptionList, idx, this.dataOptionList[idx])
            } else {
              this.dataOptionList[idx].matchString.required = false
              this.$set(this.dataOptionList, idx, this.dataOptionList[idx])
            }
          }

          if (field === 'bLogin') {
            const captureURL = this.model[idx].captureURL

            if (captureURL && value === true) {
              this.setError(idx, 'captureURL', '로그인 이후 페이지로 설정 시, 캡쳐URL 입력이 불가합니다.')
            }
          }

          if (field === 'captureURL') {
            if (value) {
              const bLogin = this.model[idx].bLogin
              if (bLogin) {
                this.setError(idx, 'captureURL', '로그인 이후 페이지로 설정 시, 캡쳐URL 입력이 불가합니다.')
              }
            }
          }

          if (field === 'timezoneName') {
            let domain = ''
            if (this.model[idx].url && value) {
              domain = parser(this.model[idx].url).domain
            }

            if (domain) {
              if (this.domainsIndex[domain]) {
                this.domainsIndex[domain].push(idx)
                this.domainsIndex[domain] = [...new Set(this.domainsIndex[domain])]
              } else {
                this.domainsIndex[domain] = [idx]
              }
              this.indexTimezone[idx] = value
            }
            if (this.domainsIndex[domain] && this.domainsIndex[domain].includes(idx)) {
              let checkDiff = false
              for (const i in this.domainsIndex[domain]) {
                if (this.indexTimezone[this.domainsIndex[domain][i]] !== value) {
                  checkDiff = true
                  break
                }
              }
              if (checkDiff) {
                for (const i in this.domainsIndex[domain]) {
                  this.setError(this.domainsIndex[domain][i], 'timezoneName', '동일한 타임존을 설정해 주세요.')
                }
              } else {
                for (const indexNum in this.domainsIndex[domain]) {
                  this.$set(this.error, this.domainsIndex[domain][indexNum], {})
                }
              }
            }
            this.$delete(this.model[idx], 'startDate')
            this.$delete(this.model[idx], 'endDate')
          }

          if (field === 'url' && value !== this.model[idx].url) {
            this.$delete(this.model[idx], 'timezoneName')
            this.$delete(this.model[idx], 'startDate')
            this.$delete(this.model[idx], 'endDate')
          }
        }
        if (this.menu === 'apply') {
          if (field === 'timezoneName') {
            let timezone = null
            let tz = getTimezoneOffset(value) / 60 / 60 / 1000
            if (tz >= 0) {
              tz = '+' + tz
            }
            timezone = tz + ':00'
            this.$set(this.model[idx], 'timezone', timezone)
          }
        }
        this.$set(this.model[idx], field, value)
      }
    },
    closeModal () {
      const confirmed = confirm('정말로 저장하지 않고 끝내시겠습니까?')
      if (confirmed) {
        this.domainsIndex = {}
        this.indexTimezone = {}
        this.$emit('close')
      }
    },
    addRow () {
      this.model.push({})
      this.dataOptionList.push(JSON.parse(JSON.stringify(this.dataOption)))
    },
    addFile () {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    readFile (event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          let data = e.target.result
          data = new Uint8Array(data)

          const validKeys = Object.keys(this.dataOption)
          const excelFile = XLSX.read(data, {
            type: 'array',
            cellDates: true
          })
          excelFile.SheetNames.forEach(sheetName => {
            if (sheetName !== 'select_option') {
              const roa = XLSX.utils.sheet_to_json(excelFile.Sheets[sheetName])
              if (roa.length > 2) {
                for (let i = 2; i < roa.length; i++) {
                  this.dataOptionList.push(JSON.parse(JSON.stringify(this.dataOption)))
                  const tempData = {}
                  for (const [k, v] of Object.entries(roa[i])) {
                    if (validKeys.includes(k)) {
                      tempData[k] = v
                      if (k === 'regDate' || k === 'startDate' || k === 'endDate') {
                        const reDate = addHours(v, 9)
                        tempData[k] = reDate
                      } else if (k === 'timezoneName' && this.menu === 'apply') {
                        tempData[k] = v.slice(9)
                      } else if (k === 'siteCode') {
                        tempData[k] = v.toUpperCase()
                      }
                    }
                  }
                  this.model.push(tempData)
                }
              }
            }
          })
        }
        reader.readAsArrayBuffer(file)
      }
    },
    removeRow (idx) {
      this.$delete(this.model, idx)
      this.$delete(this.dataOptionList, idx)
      this.$delete(this.error, idx)
    }
  },
  data () {
    return {
      showing: false,
      dataOption: null,
      dataOptionList: [],
      model: [],
      error: {},
      domainsIndex: {},
      indexTimezone: {},
      confirm: false
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
}
</style>
