import { request } from '@/utils/api-request'

export function getBeusApplyList (params) {
  const url = '/admin/ssbeus/apply/apply_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyInsertMeta (force) {
  const url = '/admin/ssbeus/apply/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function insertBeusApply (params) {
  const url = '/admin/ssbeus/apply/insert'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function insertBeusApplyBulk (params) {
  const url = '/admin/ssbeus/apply/insert/bulk'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyInfo (params) {
  const url = '/admin/ssbeus/apply/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function deleteBeusApplyHtml (params) {
  const url = '/admin/ssbeus/apply/reset_html'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusApplyExcelMeta (force) {
  const url = '/admin/ssbeus/apply/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}
