<template>
  <div class="ba-apply-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :reload="reload"
                          :menu="'baApply'"
                          :selected="selected"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @showDetail="showDetail">
      <ui-button :color="'purple'"
                 @click="toggleExcel(true)">
        <i class="xi-view-list"></i> 엑셀 다운로드
      </ui-button>
    </template-search-list>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     @button="button"
                     @close="setSelected">
      <ui-button :color="'purple'"
                 :disabled="true"
                 @click="capture(true)">
        <i class="xi-camera"></i>캡쳐하기(내부)
      </ui-button>
      <ui-button :color="'green'"
                 @click="capture(false)">
        <i class="xi-camera"></i>캡쳐하기(AWS)
      </ui-button>
    </template-detail>

    <excel-column-select :excelDownload="excelDownload"
                         :metaApi="excelListMetaApi"
                         :listApi="listApi"
                         :searchFilter="searchFilter"
                         @export="excelExport"
                         @close="toggleExcel(false)"/>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { alertError } from '@/utils/tools'

import {
  requestCapture,
  deleteBeusApplyHtml
} from '@/api/beusService'

import { getBaApplyList, getBaApplyInfo, getBaApplyExcelMeta, getBaApplyDevicePvExcelData } from '@/api/baService'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import TemplateInsertBulk from '@/components/_template/InsertBulk'
import UiButton from '@/components/_ui/UiButton'
import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { STORAGE_ITEM } from '@/utils/constants'

export default {
  name: 'BaApplyList',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    TemplateInsertBulk,
    UiButton
  },
  created () {
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData) {
        this.initialSearchFilter = {}
        if (searchData.parentSid) {
          this.initialSearchFilter.parentSid = searchData.parentSid
        }
        if (searchData.userId) {
          this.initialSearchFilter.userId = searchData.userId
        }
        if (searchData.userNum) {
          this.initialSearchFilter.userNum = searchData.userNum
        }
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    capture (vip = false) {
      this.apiParam.vip = vip
      this.apiParam.serviceCode = 1
      this.apiParam.isBaApply = true
      EventBus.$emit('onLoading', true)
      requestCapture(this.apiParam).then(response => {

      }).catch(error => {
        console.error(error)
      }).finally(() => {
        EventBus.$emit('onLoading', false)
        alert('캡쳐 요청이 완료되었습니다. 잠시 후 확인해주세요.')
        this.showDetail(this.tempDetailData)
      })
    },
    // toReport (data) {
    //   window.open(`${BEUS_URL}/report/${data._id}/`)
    // },
    showDetail (data) {
      this.tempDetailData = data
      this.apiParam = { aid: data._id }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    toggleInsertBulk (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insertBulk = !this.show.insertBulk
      } else {
        this.show.insertBulk = value
      }
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol, isDevicePv = false, aid = null) {
      let fileName = 'eagle_ba_apply'
      if (isDevicePv) {
        fileName = `eagle_dailyPV_${aid}`
      }
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('service', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
      if (value === 'baApplyExcel') {
        getBaApplyDevicePvExcelData(data).then(response => {
          const list = response.result.list
          const col = response.result.meta.column.options
          let selectCol = []
          col.forEach(item => {
            selectCol.push(item.field)
          })
          this.excelExport(col, list, selectCol, true, data.aid)
        })
      } else if (value === 'deleteHtml') {
        if (confirm('삭제 하시겠습니까?')) {
          EventBus.$emit('onLoading', true)
          this.apiParam = null
          deleteBeusApplyHtml({
            aid: data.aid,
            htmlId: data._id
          }).catch(error => {
            alertError('삭제에 실패했습니다.', error)
          }).finally(() => {
            this.apiParam = { aid: data.aid }
            EventBus.$emit('onLoading', false)
          })
        }
      }
    },
    setSelected (row) {
      this.selected = row
    },
  },
  data () {
    return {
      reload: 0,
      selected: null,

      infoApi: getBaApplyInfo,
      apiParam: null,
      
      listApi: getBaApplyList,
      excelListMetaApi: getBaApplyExcelMeta,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',

      show: {
        insert: false,
        insertBulk: false
      },
      excelDownload: false,
      searchFilter: {},
      initialSearchFilter: null,

      tempDetailData: null
    }
  }
}
</script>

<style lang="scss" scoped>
.ba-apply-list-wrap {
}
</style>
