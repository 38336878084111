<template>
  <div class="monitoring-wrap">
    <template-search-read :readApi="readApi"
                          :reload="reload"
                          @button="button"
                          @searchFilter="getSearchFilter">
    </template-search-read>

    <email-detail
      :apiParam="apiParam"
      :infoApi="infoApi"
      :editApi="editApi"
      @button="button"
      @close="close"
    >

    </email-detail>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { alertError } from '@/utils/tools'

import { getBaEmailTemplateInfo, getBaPaidUserMonitoring, updateBaPaidUser } from '@/api/monitoring'

import TemplateSearchRead from '@/components/_template/SearchRead'
import EmailDetail from './EmailDetail'

export default {
  name: 'MonitoringBaPaidUser',
  components: {
    EmailDetail,
    TemplateSearchRead
  },
  methods: {
    getSearchFilter (value) {
      this.searchFilter = value
    },
    close () {
      EventBus.$emit('onLoading', false)
    },
    button (value, data) {
      this.apiParam = null
      if (value === 'save') {
        EventBus.$emit('onLoading', true)
        updateBaPaidUser({
          aid: data.aid,
          memo: data.memo
        }).then(response => {
          alert('메모가 저장되었습니다.')
          this.reload++
        }).catch(error => {
          alertError('저장에 실패했습니다.', error)
        }).finally(() => {
          EventBus.$emit('onLoading', false)
        })
      } else if (value === 'adminPlanUpgrade' ||
        value === 'adminPvEndAlarm' ||
        value === 'adminPvAlarm' ||
        value === 'adminApplyNotInstall' ||
        value === 'adminBaPlanUpgrade' ||
        value === 'adminBaPvEndAlarm' ||
        value === 'adminBaPvAlarm' ||
        value === 'adminBaCodeNotInstall'
      ) {
        const params = JSON.parse(JSON.stringify(data))
        params.templateName = value
        this.apiParam = params
      }
    }
  },
  data () {
    return {
      infoApi: getBaEmailTemplateInfo,
      editApi: true,
      reload: 0,
      readApi: getBaPaidUserMonitoring,
      searchFilter: {},
      apiParam: null
    }
  }
}
</script>

<style lang="scss" scoped>
.monitoring-wrap {
}
</style>
