var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('div',{staticClass:"detail-top"},[_vm._t("default")],2),_c('div',{staticClass:"detail-content"},[_c('table',{staticClass:"detail-table"},[_vm._m(0),(!_vm.cancelMode)?[_vm._l((Object.entries(_vm.meta.field)),function([field, option]){return [(option.title)?_c('thead',{key:`detail-${field}`},[_vm._m(1,true),_c('tr',[_c('th',{attrs:{"colspan":"2"},domProps:{"innerHTML":_vm._s(option.name)}})])]):(option.subTitle)?_c('thead',{key:`detail-${field}`},[_vm._m(2,true),_c('tr',[_c('th',{staticClass:"subtitle",attrs:{"colspan":"2"},domProps:{"innerHTML":_vm._s(option.name)}})])]):[_c('tr',{key:`detail-${field}`,class:{ inner: ['list', 'tab'].includes(option.type.split('|')[0]),
                          disabled: _vm.editMode && !option.editable,
                          editing: _vm.editMode && option.editable }},[(option.name)?_c('th',{domProps:{"innerHTML":_vm._s(option.name)}}):_vm._e(),_c('td',{attrs:{"colspan":option.name ? null : 2}},[(_vm.editMode && option.editable)?_c('ui-data-input',{attrs:{"type":option.type,"label":null,"options":option.options,"update":option.update,"required":option.required,"field":option.field || field,"model":_vm.data,"value":_vm.data[option.field || field],"error":_vm.error[option.field || field]},on:{"error":_vm.sendError,"input":function($event){_vm.$emit('input', (option.field || field), $event)}}}):_c('ui-data',{attrs:{"type":option.type,"value":_vm.data[option.field || field],"option":option.option,"listData":option.options,"sortBy":_vm.tableSortBy[option.field || field] || '',"name":field,"fieldName":field,"model":_vm.data,"timezoneName":_vm.data.timezoneName ? _vm.data.timezoneName : null},on:{"setTableData":(...args) => { _vm.setTableData((option.field || field), ...args) },"changeTableSort":(...args) => { _vm.changeTableSort((option.field || field), ...args) },"button":_vm.button}})],1)])]]})]:[_vm._l((Object.entries(_vm.cancelMeta.field)),function([field, option]){return [(option.title)?_c('thead',{key:`detail-${field}`},[_vm._m(3,true),_c('tr',[_c('th',{attrs:{"colspan":"2"},domProps:{"innerHTML":_vm._s(option.name)}})])]):[_c('tr',{key:`detail-${field}`,class:{ inner: ['list', 'tab'].includes(option.type.split('|')[0]),
                          disabled: _vm.cancelMode && !option.editable,
                          editing: _vm.cancelMode && option.editable }},[(option.name)?_c('th',{domProps:{"innerHTML":_vm._s(option.name)}}):_vm._e(),_c('td',{attrs:{"colspan":option.name ? null : 2}},[(_vm.cancelMode && option.editable)?_c('ui-data-input',{attrs:{"type":option.type,"label":null,"options":option.options,"update":option.update,"required":option.required,"field":option.field || field,"cancelMode":_vm.cancelMode,"model":_vm.data,"value":_vm.getValue(option.field || field),"error":_vm.error[option.field || field]},on:{"error":_vm.sendError,"input":function($event){_vm.$emit('input', (option.field || field), $event)}}}):_c('ui-data',{attrs:{"type":option.type,"value":_vm.data[option.field || field],"option":option.option,"listData":option.options,"sortBy":_vm.tableSortBy[option.field || field] || '',"name":field,"model":_vm.data},on:{"setTableData":(...args) => { _vm.setTableData((option.field || field), ...args) },"changeTableSort":(...args) => { _vm.changeTableSort((option.field || field), ...args) },"button":_vm.button}})],1)])]]})]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"140"}}),_c('col')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}})])
}]

export { render, staticRenderFns }