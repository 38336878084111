<template>
  <transition name="fade">
    <div class="popup-preview"
         v-show="!!data">
      <div class="popup">
        <i class="close-button xi-close"
           @click="close"></i>
        <h4 class="title">FAQ 미리보기</h4>
        <iframe v-if="data"
                id="preview"
                :src="previewSourceAddress">
        </iframe>
      </div>
    </div>
  </transition>
</template>

<script>
import { BA_HOME_URL, BEUS_HOME_URL } from '@/utils/tools'

export default {
  name: 'FaqPreview',
  props: [
    'data'
  ],
  watch: {
    data (value) {
      if (value) {
        this.setInitUrl()
        this.setPreview()
      }
    }
  },
  data () {
    return {
      previewSourceAddress: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    setInitUrl () {
      const serviceType = this.data.serviceType
      let previewUrl = BEUS_HOME_URL + `/${this.data.lang}/faq`
      if (serviceType === 'ba') {
        previewUrl = BA_HOME_URL + `/${this.data.lang}/help`
      }
      this.previewSourceAddress = previewUrl
    },
    setPreview () {
      let previewWindow = {}
      const model = this.data
      const timer = 1000

      const catchPreviewWindow = setInterval(function () {
        previewWindow = document.getElementById('preview')
        if (previewWindow) {
          clearInterval(catchPreviewWindow)
          previewWindow.contentWindow.postMessage({
            call: 'faqPreview',
            model: model
          }, '*')
        }
      }, timer)
    }
  },
  destroyed () {
    this.previewSourceAddress = ''
  }
}
</script>

<style scoped lang="scss">
.popup-preview {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 9999;
  overflow-y: hidden;

  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 30px);
    width: calc(100% - 60px);
    padding: 0 30px 30px;
    background: $black;

    .close-button {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 55;
      background-color: $dark;
      color: $white;
      border-radius: 20px;
      padding: 3px;
      cursor: pointer;
      font-size: 12px;
      transition: background-color 0.3s ease,
      color 0.3s ease;

      &:hover {
        background-color: $red;
        color: $white;
      }
    }

    .title {
      font-size: 16px;
      padding: 8px 2px;
      height: 16px;
      font-weight: bold;
      color: $white;

      i {
        margin: 1px 5px 0 0;
      }
    }

    #preview {
      width: 100%;
      height: calc(100% - 32px);
      border-radius: 10px;
      transform-origin: left top;
    }
  }

  &.fade-leave-active,
  &.fade-enter-active {
    transition: opacity 0.3s ease;
    opacity: 1;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
}
</style>
