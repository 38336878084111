import { request } from '@/utils/api-request'

export function getBaRawDataList (params) {
  const url = '/admin/rawData/journey/list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}


export function getBaRawDataInfo (params) {
  const url = '/admin/rawData/journey/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
