<template>
  <div class="tool-get-sid">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :reload="reload"
                          :selected="selected"
                          @select="setSelected"
                          @toReport="toReport"
                          @showDetail="showDetail">
    </template-search-list>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     @close="setSelected">
    </template-detail>
  </div>
</template>

<script>
import { BEUS_URL } from '@/utils/tools'
import {
  getBeusApplyInfo,
  insertBeusApply,
  insertBeusApplyBulk,
  getBeusApplyInsertMeta,
  getBeusToolApplyList
} from '@/api/beusService'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import TemplateInsertBulk from '@/components/_template/InsertBulk'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'BeusApplyList',
  components: {
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    TemplateInsertBulk,
    UiButton
  },
  methods: {
    toReport (data) {
      window.open(`${BEUS_URL}/report/${data._id}/`)
    },
    showDetail (data) {
      this.apiParam = { aid: data._id }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    toggleInsertBulk (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insertBulk = !this.show.insertBulk
      } else {
        this.show.insertBulk = value
      }
    },
    setSelected (row) {
      this.selected = row
    }
  },
  data () {
    return {
      reload: 0,
      selected: null,

      infoApi: getBeusApplyInfo,
      insertApi: insertBeusApply,
      insertBulkApi: insertBeusApplyBulk,
      insertMetaApi: getBeusApplyInsertMeta,
      apiParam: null,

      listApi: getBeusToolApplyList,
      buttonOption: [
        {
          action: 'toReport',
          columnText: '리포트',
          buttonText: '<i class="xi-document"></i>',
          if: ['state', '!=', 'error']
        },
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',

      show: {
        insert: false,
        insertBulk: false
      }
    }
  }
}
</script>
