import { request } from '@/utils/api-request'
import { PLAN_GROUP } from '@/utils/constants'

export function getBeusPlanList (params, force) {
  const url = '/admin/beus/plan/get_plan_list'
  const config = {
    method: 'get',
    skipError: true,
    url
  }
  return request(config, force, '24h')
}

export function getBaPlanList (params, force) {
  const url = '/admin/ba/plan/get_plan_list'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function filterPlanList (planList, serviceType) {
  const result = {}
  for (const plan of planList) {
    if (plan.serviceType === serviceType) {
      result[plan.planNumber] = plan
    }
  }
  return result
}

export function filterPlanListByGroup (planList, serviceType) {
  const result = JSON.parse(JSON.stringify(PLAN_GROUP))
  const temp = {}
  PLAN_GROUP.forEach((item, index) => {
    const value = item.value
    if (!temp[value]) {
      temp[value] = index
    }
  })
  for (const plan of planList) {
    if (plan.serviceType === serviceType) {
      if (!result[temp[plan.planGroup]].children) {
        result[temp[plan.planGroup]].children = []
      }
      result[temp[plan.planGroup]].children.push({
        name: plan.planName,
        value: plan.planNumber
      })
    }
  }
  return result
}

export function getBeusPaymentList (params) {
  const url = '/admin/beus/payment/payment_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaymentInfo (params) {
  const url = '/admin/beus/payment/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function insertBeusPayment (params) {
  const url = '/admin/beus/payment/upsert_payment'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaymentInsertMeta (params, force = false) {
  const url = '/admin/beus/payment/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function removeBeusPayment (params) {
  const url = '/admin/beus/payment/delete'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateBeusPayment (params) {
  const url = '/admin/beus/payment/update'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPaymentExcelMeta (force) {
  const url = '/admin/beus/payment/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getBaPaymentList (params) {
  const url = '/admin/ba/payment/payment_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaymentInfo (params) {
  const url = '/admin/ba/payment/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function insertBaPayment (params) {
  const url = '/admin/ba/payment/upsert_payment'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaymentInsertMeta (params, force = false) {
  const url = '/admin/ba/payment/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function removeBaPayment (params) {
  const url = '/admin/ba/payment/delete'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateBaPayment (params) {
  const url = '/admin/ba/payment/update'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaPaymentExcelMeta (force) {
  const url = '/admin/ba/payment/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}
