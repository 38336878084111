<template>
  <ui-side-modal v-show="show"
                 class="template-detail"
                 :width="modalWidth"
                 @close="closeModal">
    <ui-detail v-if="dataOption && dataInHand"
               :meta="dataOption"
               :data="dataInHand"
               :error="error"
               :editMode="editMode"
               :cancelMode="cancelMode"
               :cancelMeta="cancelMeta"
               :tableSortBy="tableSortBy"
               :service="service"
               @metaUpdate="setMeta"
               @error="setError"
               @input="input"
               @changeTableSort="changeTableSort"
               @button="button">
      <slot></slot>
      <template v-if="canRemove">
        <ui-button class="remove-button"
                   :color="'gray'"
                   @click="remove">
          <i class="xi-trash"></i> 삭제
        </ui-button>
      </template>
      <template v-if="canCancel">
        <template v-if="cancelMode">
          <h4 class="title"> 결제 취소 하기</h4>
          <ui-button :color="'red'"
                     @click="save(false)">
            <i class="xi-close"></i> 취소
          </ui-button>
          <ui-button :color="'blue'"
                     @click="save(true)">
            <i class="xi-pen"></i> 저장
          </ui-button>
        </template>
      </template>
      <template v-if="canComplete">
        <ui-button class="remove-button"
                  :color="'purple'"
                  :disabled="completeButtonCheck"
                  @click="complete">
          <i class="xi-mail"></i> 응대 완료
        </ui-button>
      </template>
      <template v-if="cancelMode === false && canCancel">
        <template v-if="cancelButtonCheck">
          <ui-button :color="'red'"
                      @click="cancel">
            <i class="xi-pen"></i> 결제 취소하기
          </ui-button>
        </template>
        <template v-else>
          <ui-button :color="'red'"
                      :disabled=true>
            <i class="xi-pen"></i> 결제 취소하기
          </ui-button>
        </template>
      </template>
      <template v-if="canEdit">
        <template v-if="editMode">
          <ui-button :color="'gray'"
                     @click="save(false)">
            <i class="xi-close"></i> 취소
          </ui-button>
          <ui-button :color="'red'"
                     @click="save(true)">
            <i class="xi-pen"></i> 저장
          </ui-button>
        </template>
        <template v-else>
          <ui-button v-if="!cancelMode"
                     :color="'yellow'"
                     @click="edit">
            <i class="xi-pen"></i> 수정
          </ui-button>
        </template>
      </template>
    </ui-detail>
  </ui-side-modal>
</template>

<script>
import isobject from 'isobject'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { alertError, isEndWithConsonant, encrypt } from '@/utils/tools'

import UiDetail from '@/components/_ui/UiDetail'
import UiButton from '@/components/_ui/UiButton'
import UiSideModal from '@/components/_ui/UiSideModal'

import { PAYMENT_CARD, JAM_CHARGE, PAYMENT_STATUS_WAITING } from '@/utils/constants'

export default {
  name: 'TemplateDetail',
  components: {
    UiDetail,
    UiButton,
    UiSideModal
  },
  props: {
    infoApi: {
      required: true
    },
    cancelMetaApi: {
      required: false
    },
    cancelApi: {
      required: false
    },
    editApi: {
      required: false
    },
    removeApi: {
      required: false
    },
    service: {
      default: false
    },
    apiParam: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    }
  },
  computed: {
    modalWidth () {
      if (this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if (option.type === 'editor' || option.type === 'editor4') {
            return 1200
          }
        }
      }
      return null
    },
    canEdit () {
      if (this.editApi && this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if (option.editable) {
            return true
          }
        }
      }
      return false
    },
    canCancel () {
      if (this.cancelApi && this.dataOption) {
        return true
      }
      return false
    },
    cancelButtonCheck() {
      const price = this.dataInHand.price
      const total = this.dataInHand.midPriceSum
      const remainCount = this.dataInHand.remainCount
      const paymentState = this.dataInHand.paymentState
      const jamState = this.dataInHand.jamState
      if (this.service === "jam") {
        if (jamState !== JAM_CHARGE) {
          return false
        }
        if (paymentState === PAYMENT_CARD) {
          if ( total > 0 || remainCount > 0 ) {
            return true
          } else {
            return false
          }
        } else {
          if (remainCount > 0) {
            return true
          }
          return false
        }
      } else {
        const paymentPriceSum = this.dataInHand.paymentPriceSum
        if (price > 0 && paymentPriceSum > 0 && total > 0) {
          return true
        } else {
          return false
        }
      }
    },
    completeButtonCheck () {
      if (this.dataInHand.solved === undefined) {
        return true
      } else {
        return this.dataInHand.solved;
      }
    },
    canRemove () {
      if (this.removeApi && this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if (option.key && option.deletable) {
            return true
          }
        }
      }
      return false
    },
    canComplete () {
      if (this.editApi && this.dataOption) {
        for (const option of Object.values(this.dataOption.field)) {
          if (option.completable) {
            return true
          }
        }
      }
      return false
    },
    ...mapGetters([
      'userData'
    ])
  },
  created () {
    if (this.apiParam) {
      this.loadData()
    }
  },
  watch: {
    apiParam (value) {
      this.editMode = false
      if (value) {
        if (this.cancelMode) {
          this.loadData(this.cancel)
        } else {
          this.loadData()
        }
      }
    },
    editMode (value) {
      if (value) {
        this.setModel()
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    userName () {
      if (this.userData) {
        return this.userData
      }
      return ''
    },
    setMeta (value) {
      this.dataOption = value
      this.$forceUpdate()
    },
    loadData (callback = null) {
      this.error = {}
      this.show = false
      EventBus.$emit('onLoading', true)
      setTimeout(() => {
        if (this.cancelMetaApi) {
          this.cancelMetaApi(true).then(response => {
            this.cancelMeta = response.result || null
          })
          console.log(this.cancelMeta)
        }
        this.infoApi(this.apiParam, true).then(response => {
          this.dataInHand = response.result.info || null
          this.dataOption = response.result.meta || null
          this.show = true
        }).catch(error => {
          alertError('상세 데이터를 불러오지 못 했습니다.', error)
        }).finally(() => {
          if (callback) {
            callback()
          }
          EventBus.$emit('onLoading', false)
        })
      }, 300)
    },
    setModel () {
      this.model = {}
      for (const [field, option] of Object.entries(this.dataOption.field)) {
        if (option.key || option.editable) {
          this.model[option.field || field] = this.dataInHand[option.field || field]
        }
      }
    },
    cancel () {
      this.cancelMode = !this.cancelMode
    },
    edit () {
      this.editMode = !this.editMode
    },
    setError (key, message) {
      this.$set(this.error, key, message)
    },
    checkRequired () {
      if (this.cancelMode) {
        if (this.cancelMeta) {
          for (const [field, option] of Object.entries(this.cancelMeta.field)) {
            const key = option.field || field
            if (key === 'price') {
              if (this.model[key] > this.dataInHand.midPriceSum) {
                this.$set(this.error, key, `취소 가능 금액 이상으로 취소 할수 없습니다.`)
              }
              if (this.service === 'jam' && this.dataInHand.paymentState !== PAYMENT_CARD && this.model[key] !== 0) {
                this.$set(this.error, key, `취소 가능 금액 이상으로 취소 할수 없습니다.`)
              }
            }
            if (key === 'jamChargeCount' && this.model[key] > this.dataInHand.remainCount) {
              if (this.model[key] !== 0) this.$set(this.error, key, `보유한 JAM 개수 이상으로 취소 할 수 없습니다.`)
            }
            if (option.required && !this.model[key] && this.model[key] !== 0) {
              this.$set(this.error, key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 필수입니다.`)
            }
          }
        }
      } else {
        if (this.dataOption) {
          for (const [field, option] of Object.entries(this.dataOption.field)) {
            const key = option.field || field
            if (option.required && !this.model[key] && this.model[key] !== 0) {
              this.$set(this.error, key, `${option.name}${isEndWithConsonant(option.name) ? '은' : '는'} 필수입니다.`)
            }
            if (key === 'useStartDate' || key === 'useEndDate') {
              if (this.dataInHand.paymentStatus === PAYMENT_STATUS_WAITING) {
                const currentDate = new Date().toISOString().slice(0,10)
                if (currentDate >= this.model[key]) {
                  this.$set(this.error, key, `오늘 이전 날짜로 설정할 수 없습니다.`)
                }
              }
              const startDate = this.model['useStartDate'] || this.dataInHand.useStartDate
              if (startDate > this.model['useEndDate']) {
                this.$set(this.error, 'useEndDate', `플랜 시작일보다 빠른 날짜로 설정할 수 없습니다. `)
              }
            }
          }
        }
      }
      for (const [field, message] of Object.entries(this.error)) {
        if (message) {
          if (message === '확인 버튼을 눌러 확인하세요.') {
            this.model[field] = ''
          } else {
            return false
          }
        }
      }
      for (const message of Object.values(this.error)) {
        if (message) {
          return false
        }
      }
      return true
    },
    remove () {
      const confirmed = confirm('정말로 삭제하시겠습니까?')
      if (confirmed) {
        EventBus.$emit('onLoading', true)
        this.removeApi(this.dataInHand, true).then(() => {
          this.show = false
        }).catch(error => {
          alertError('삭제에 실패했습니다.', error)
        }).finally(() => {
          this.$emit('reload')
          EventBus.$emit('onLoading', false)
        })
      }
    },
    complete () {
      const confirmed = confirm('상태를 변경하시겠습니까?')
      if (confirmed) {
        EventBus.$emit('onLoading', true)
        this.editApi({id: this.apiParam.id, solved: true}).then(() => {
          this.loadData()
        }).catch(error => {
          alertError('상태 변경에 실패했습니다.', error)
        }).finally(() => {
          this.$emit('reload')
          EventBus.$emit('onLoading', false)
        })
      }
    },
    save (save = false) {
      let confirmed = false
      if (this.checkRequired()) {
        confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
      } else {
        if (!save) {
          confirmed = confirm(save ? '정말로 저장하시겠습니까?' : '정말로 저장하지 않고 끝내시겠습니까?')
        }
      }
      if (confirmed) {
        if (save) {
          EventBus.$emit('onLoading', true)
          this.model.serviceType = this.$route.meta.ba ? 'ba' : 'beusable'
          this.model.id = this.apiParam.id
          if (this.cancelMode) {
            if (this.service === 'jam') {
              this.model.serviceType = this.service
              this.model.jamCount = this.dataInHand.jamChargeCount
            }
            this.model.userId = this.userData.userId
            this.model.password = encrypt(this.model.password, this.model.userId)
            this.cancelApi(this.model, true).then(() => {
              this.loadData(this.cancel)
              this.setModel()
            }).catch(error => {
              const message = error.response ? error.response.data.message : false
              if (isobject(message)) {
                for (const [key, value] of Object.entries(message)) {
                  this.$set(this.error, key, value)
                }
              } else {
                alertError('저장에 실패했습니다.', error)
              }
            }).finally(() => {
              this.$emit('reload')
              EventBus.$emit('onLoading', false)
            })
          } else if (this.editMode) {
            if (this.service === 'jamUse') {
              this.model.dataType = this.dataInHand.dataType
            }
            if (this.service === 'contactUsHistory') {
              this.model.adminStatusManager = this.userData.userId
            }
            this.editApi(this.model, true).then(() => {
              this.loadData(this.edit)
            }).catch(error => {
              alertError('저장에 실패했습니다.', error)
            }).finally(() => {
              this.$emit('reload')
              EventBus.$emit('onLoading', false)
            })
          }
        } else {
          if (this.editMode) {
            this.loadData(this.edit)
          } else if (this.cancelMode) {
            this.loadData(this.cancel)
            this.setModel()
          }
        }
      }
    },
    changeValue (field, value) {
      this.dataInHand[field] = value
      this.model[field] = value
      this.$emit('input', field, value)
    },
    input (field, value) {
      const paymentState = this.dataInHand.paymentState;
      if (this.service === 'jam' && paymentState === PAYMENT_CARD) {
        if (field === "jamChargeCount" && this.model['price'] !== undefined) {
          this.changeValue("price", value * 3300)
        }
        if (field === "price" && this.model['price'] === undefined) {
          this.changeValue("price", this.dataInHand['midPriceSum'])
          this.$delete(this.error, field)
          return
        }
      }
      if (field === 'paymentType') {
        const isChange = this.model[field] !== value
        if (value === 'wire transfer' || value === 'card') {
          if (this.dataOption.field.payment && isChange) {
            this.dataOption.field.payment.editable = true
            this.dataOption.field.payment.required = true
            this.changeValue('payment', '')
          }
        } else {
          if (this.dataOption.field.payment && isChange) {
            this.dataOption.field.payment.editable = false
            this.dataOption.field.payment.required = false
            this.changeValue('payment', 0)
          }
        }
      }

      this.$delete(this.error, field)
      this.model[field] = value
      this.$emit('input', field, value)
    },
    changeTableSort (field, sort) {
      this.tableSortBy[field] = sort
      const desc = sort.indexOf('-') === 0
      sort = sort.replace('-', '').replace('+', '')
      this.dataInHand[field].sort((a, b) => {
        if (desc) {
          return a[sort] > b[sort] ? -1 : 1
        } else {
          return a[sort] < b[sort] ? -1 : 1
        }
      })
    },
    button (value, data) {
      this.$emit('button', value, data)
    },
    closeModal () {
      let confirmed = true
      if (this.editMode || this.cancelMode) {
        confirmed = confirm('정말로 저장하지 않고 끝내시겠습니까?')
      }
      if (confirmed) {
        if (this.cancelMode) {
          this.loadData(this.cancel)
        }
        this.setModel()
        this.show = false
        this.$emit('close')
      }
    }
  },
  data () {
    return {
      dataInHand: null,
      dataOption: null,
      editMode: false,
      cancelMode: false,
      cancelMeta: null,
      show: false,
      model: {
        serviceType: this.$route.meta.ba ? 'ba' : 'beusable'
      },
      error: {},
      tableSortBy: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .remove-button {
    opacity: 0.5;
  }
}
</style>
