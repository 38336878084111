import store from '@/store'
import { clearAllApiCache } from '@/utils/api-request'
import router from '@/router'
import { STORAGE_KEY } from '@/utils/constants'

const ACCESS_TOKEN = 'access_token'

export function setAuthToken (token) {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token))
  } else {
    removeAuthToken()
  }
}

export function getAuthToken () {
  return localStorage.getItem(ACCESS_TOKEN) || null
}

export function removeAuthToken () {
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(STORAGE_KEY.devTimestamp)
}

export function checkAuth () {
  return new Promise(resolve => {
    const authToken = getAuthToken()

    if (authToken) {
      const token = JSON.parse(authToken)

      if (!token.accessToken) {
        removeAuthToken()
        store.commit('setUserData', null)
        return resolve(null)
      } else {
        const identity = parseJwt(token.accessToken).identity
        const userData = {
          userId: identity.user_id,
          userNum: identity.user_num,
          isDev: identity.is_dev
        }
        store.commit('setUserData', userData)
        return resolve(userData)
      }
    }
    return resolve(null)
  })
}

export function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export function signOut (redirect = true) {
  removeAuthToken()
  clearAllApiCache()
  if (redirect) {
    router.push({ name: 'SignIn' })
  }
}
