import { request } from '@/utils/api-request'

export function getHeatmapPlanData (params) {
  const url = '/admin/dashboard/get_heatmap_plan'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getJourneyPlanData (params) {
  const url = '/admin/dashboard/get_journey_plan'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getDashboardPlanData (params) {
  const url = '/admin/dashboard/get_chart_data'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
