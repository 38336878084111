<template>
  <div class="coupon-list-wrap">
    <template-search-list :listApi="listApi"
                          :initialSearchFilter="initialSearchFilter"
                          :defaultSortBy="defaultSortBy"
                          :reload="reload"
                          @searchFilter="getSearchFilter">
      <ui-button :color="'purple'"
                 @click="toggleExcel(true)">
        <i class="xi-view-list"></i> 엑셀 다운로드
      </ui-button>
    </template-search-list>

    <excel-column-select :excelDownload="excelDownload"
                         :metaApi="excelListMetaApi"
                         :listApi="listApi"
                         :searchFilter="searchFilter"
                         @export="excelExport"
                         @close="toggleExcel(false)"/>
  </div>
</template>

<script>
import { getEventExcelMeta, getEventList } from '@/api/marketing'

import TemplateSearchList from '@/components/_template/SearchList'
import UiButton from '@/components/_ui/UiButton'
import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { EventBus } from '@/utils/event-bus'

export default {
  name: 'EventList',
  components: {
    TemplateSearchList,
    ExcelColumnSelect,
    UiButton
  },
  data () {
    return {
      reload: 0,
      listApi: getEventList,
      excelListMetaApi: getEventExcelMeta,
      defaultSortBy: '-date',
      searchFilter: {},
      initialSearchFilter: null,
      excelDownload: false
    }
  },
  methods: {
    reloadList () {
      this.reload++
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_event'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('service', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.event-list-wrap {
}
</style>
