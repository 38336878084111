<template>
  <div>
    <password-checker v-if="isShowPasswordChecker" @check="checkDevAuth"/>
    <router-view v-else/>
  </div>
</template>

<script>
import PasswordChecker from '@/components/dev/PasswordChecker'
import { mapMutations, mapState } from 'vuex'
import { STORAGE_KEY } from '@/utils/constants'
import { isBefore } from 'date-fns'
import { EventBus } from '@/utils/event-bus'
export default {
  name: 'Dev',
  components: { PasswordChecker },
  data () {
    return {
      isShowPasswordChecker: true
    }
  },
  computed: {
    ...mapState([
      'userData',
      'devTimestamp'
    ])
  },
  created () {
    EventBus.$emit('onLoading', false)
    this.checkDevAuth()
  },
  methods: {
    ...mapMutations([
      'setDevTimestamp'
    ]),
    checkDevAuth (route) {
      const targetRoute = route || this.$route
      this.isShowPasswordChecker = true
      const isTargetPage = targetRoute.meta.requireDev

      if (!isTargetPage) {
        this.isShowPasswordChecker = false
        return
      }

      let expireTimestamp = this.devTimestamp

      if (!expireTimestamp) {
        expireTimestamp = localStorage.getItem(STORAGE_KEY.devTimestamp)
        this.setDevTimestamp(expireTimestamp)
      }

      if (isTargetPage && expireTimestamp) {
        const expireDate = new Date(new Date().setTime(expireTimestamp))
        const now = new Date()

        if (isBefore(now, expireDate)) {
          this.isShowPasswordChecker = false
        } else {
          this.setDevTimestamp(null)
        }
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.checkDevAuth(to)
    next()
  }
}
</script>

<style scoped>

</style>
