import { request } from '@/utils/api-request'

export function getJenkinsList (params) {
  const url = '/admin/dev/jenkins/get_job_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getJenkinsJobDetail (params) {
  const url = '/admin/dev/jenkins/get_job'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
