<template>
  <div class="ba-domain-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :menu="'baDomain'"
                          :selected="selected"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @toReport="toReport"
                          @showDetail="showDetail"
                          @searchSiteMap="searchSiteMap"
                          @searchSurvey="searchSurvey"
    >
      <ui-button :color="'purple'"
                 @click="toggleExcel(true)">
        <i class="xi-view-list"></i> 엑셀 다운로드
      </ui-button>
    </template-search-list>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     @button="button"
                     @close="setSelected">
    </template-detail>

    <excel-column-select :excelDownload="excelDownload"
                         :metaApi="excelListMetaApi"
                         :listApi="listApi"
                         :searchFilter="searchFilter"
                         @export="excelExport"
                         @close="toggleExcel(false)"/>
  </div>
</template>

<script>
import { BA_URL } from '@/utils/tools'
import { EventBus } from '@/utils/event-bus'
import { getBaDomainList, getBaDomainInfo, getBaDomainExcelMeta } from '@/api/baService'

import UiButton from '@/components/_ui/UiButton'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'

import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { STORAGE_ITEM } from '@/utils/constants'

export default {
  name: 'BaDomainList',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    UiButton
  },
  created () {
    // console.log('eventbus: ', EventBus.$on('searchData', this.apiParam))
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.userId) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.userId = searchData.userId
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    button (value, data) {
      const nameMap = {
        domainBaApply: 'ServiceBaApply'
      }
      const apiParam = data
      localStorage.setItem(STORAGE_ITEM.searchData, JSON.stringify(apiParam))
      const route = this.$router.resolve({ name: nameMap[value], params: apiParam })
      window.open(route.href, '_blank')
    },
    toReport (data) {
      window.open(`${BA_URL}?sid=${data.orgSid}`)
    },
    searchSiteMap (data) {
      const searchFilter = {}
      searchFilter.parentSid = data.orgSid
      searchFilter.userId = data.userId
      this.$router.push({ name: 'ServiceBaSitemap', params: { initialSearchFilter: searchFilter } })
    },
    searchSurvey (data) {
      const searchFilter = {}
      searchFilter.parentSid = data.orgSid
      searchFilter.userId = data.userId
      this.$router.push({ name: 'ServiceBaSurvey', params: { initialSearchFilter: searchFilter } })
    },
    showDetail (data) {
      this.apiParam = { sid: data.orgSid }
    },
    openCreateModal () {
      this.show.domainCreate = true
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_ba_domain'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('service', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    setSelected (row) {
      this.selected = row
    },
  },
  data () {
    return {
      selected: null,

      infoApi: getBaDomainInfo,
      apiParam: null,

      listApi: getBaDomainList,
      excelListMetaApi: getBaDomainExcelMeta,
      buttonOption: [
        {
          action: 'toReport',
          columnText: '리포트',
          buttonText: '<i class="xi-document"></i>',
          if: ['state', '!=', 'error']
        },
        {
          action: 'searchSiteMap',
          columnText: '사잍맵',
          buttonText: '<i class="xi-sitemap"></i>',
          if: ['sitemapCount', '>', 0]
        },
        {
          action: 'searchSurvey',
          columnText: '서베이',
          buttonText: '<i class="xi-sitemap"></i>',
          if: ['surveyCount', '>', 0]
        },
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',
      excelDownload: false,
      searchFilter: {},
      initialSearchFilter: null
    }
  }
}
</script>

<style lang="scss" scoped>
.ba-domain-list-wrap {
}
</style>
