<template>
  <router-view/>
</template>

<script>
export default {
  name: 'EmptyLayout'
}
</script>

<style lang="scss">
@import '@/styles/app.scss';
</style>
