import { request } from '@/utils/api-request'

export function getBeusCreditList (params, force = false) {
  const url = '/admin/beus/credit/list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, force, '24h')
}

export function getBeusCreditListExcelMeta (params, force = false) {
  const url = '/admin/beus/credit/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getBeusCreditInsertMeta (params, force = false) {
  const url = '/admin/beus/credit/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function insertBeusCredit (params) {
  const url = '/admin/beus/credit/insert'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaCreditInsertMeta (params, force = false) {
  const url = '/admin/ba/credit/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function insertBaCredit (params) {
  const url = '/admin/ba/credit/insert'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaCreditList (params, force = false) {
  const url = '/admin/ba/credit/list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, force, '24h')
}

export function getBaCreditListExcelMeta (params, force = false) {
  const url = '/admin/ba/credit/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}
