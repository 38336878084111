<template>
  <div class="dev-jenkins-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :reload="reload"
                          @searchFilter="getSearchFilter"
                          @showDetail="showDetail">
    </template-search-list>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     >
    </template-detail>
  </div>
</template>

<script>

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import UiButton from '@/components/_ui/UiButton'

import { STORAGE_ITEM } from '@/utils/constants'
import { getJenkinsJobDetail, getJenkinsList } from '@/api/dev'

export default {
  name: 'JenkinsList',
  components: {
    TemplateSearchList,
    TemplateDetail,
    UiButton
  },
  created () {
    // console.log('eventbus: ', EventBus.$on('searchData', this.apiParam))
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.userId) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.userId = searchData.userId
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    showDetail (data) {
      console.log(data)
      this.tempDetailData = data
      this.apiParam = { aid: data._id }
    },
    reloadList () {
      this.reload++
    },
    getSearchFilter (value) {
      this.searchFilter = value
    }
  },
  data () {
    return {
      reload: 0,

      apiParam: null,

      listApi: getJenkinsList,
      infoApi: getJenkinsJobDetail,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',
      searchFilter: {},
      initialSearchFilter: null,
      tempDetailData: null
    }
  }
}
</script>

<style lang="scss" scoped>
.dev-jenkins-list-wrap {
}
</style>
