<template>
  <div class="contactus-list-wrap">
    <template-search-list
      :listApi="listApi"
      :buttonOption="buttonOption"
      :defaultSortBy="defaultSortBy"
      :reload="reload"
      :initialSearchFilter="initialSearchFilter"
      :selected="selected"
      @select="setSelected"
      @button="button"
      @showDetail="showDetail"
    >
    </template-search-list>
    <template-detail
      :infoApi="infoApi"
      :editApi="editApi"
      :apiParam="apiParam"
      :service="'contactUsHistory'"
      @reload="reloadList"
      @close="setSelected"
    >
    </template-detail>
  </div>
</template>

<script>
import {
  getContactUsHistoryDetail,
  getContactUsHistoryList,
  updateContactusHistory,
} from "@/api/contact";
import TemplateDetail from "@/components/_template/Detail";
import TemplateSearchList from "@/components/_template/SearchList";
import UiButton from "@/components/_ui/UiButton";
import { EventBus } from "@/utils/event-bus";
import { alertError } from "@/utils/tools";

export default {
  name: "ContactUsHistoryList",
  components: {
    TemplateSearchList,
    TemplateDetail,
    UiButton,
  },
  methods: {
    showDetail(data) {
      this.apiParam = { id: data.sessionKey };
    },
    reloadList () {
      this.reload++
    },
    button(value, data) {
      if (value === "save") {
        EventBus.$emit("onLoading", true);
        updateContactusHistory({
          id: data.sessionKey,
          adminMemo: data.adminMemo,
        })
          .then((response) => {
            alert("메모가 저장되었습니다.");
            this.reload++;
          })
          .catch((error) => {
            alertError("저장에 실패했습니다.", error);
          })
          .finally(() => {
            EventBus.$emit("onLoading", false);
          });
      }
    },
    setSelected(row) {
      this.selected = row;
    },
  },
  data() {
    return {
      reload: 0,
      selected: null,

      apiParam: null,
      infoApi: getContactUsHistoryDetail,
      editApi: updateContactusHistory,
      listApi: getContactUsHistoryList,
      buttonOption: [
        {
          action: "showDetail",
          columnText: "자세히",
          buttonText: '<i class="xi-arrow-right"></i>',
        },
      ],
      defaultSortBy: "",
      initialSearchFilter: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.contactus-list-wrap {
}
</style>
