<template>
  <div class="beus-apply-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :reload="reload"
                          :menu="'beusApply'"
                          :selected="selected"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @toReport="toReport"
                          @showDetail="showDetail">
      <ui-button :color="'green'"
                 @click="toggleInsert">
        <i class="xi-plus"></i> 등록
      </ui-button>
      <ui-button :color="'green'"
                 @click="toggleInsertBulk">
        <i class="xi-plus"></i> 대량 등록
      </ui-button>
      <ui-button :color="'purple'"
                 @click="toggleExcel(true)">
        <i class="xi-view-list"></i> 엑셀 다운로드
      </ui-button>
    </template-search-list>

    <template-insert :show="show.insert"
                     :metaApi="insertMetaApi"
                     :insertApi="insertApi"
                     @reload="reloadList"
                     @saved="showDetail"
                     @close="toggleInsert" :menu="'apply'">
      <h4 class="title">페이지 등록</h4>
    </template-insert>

    <template-insert-bulk :show="show.insertBulk"
                          :metaApi="bulkInsertMetaApi"
                          :insertApi="insertBulkApi"
                          @reload="reloadList"
                          @close="toggleInsertBulk" :menu="'apply'">
      <h4 class="title">페이지 대량 등록</h4>

      <a :href="templateFileJa" download="eagle-apply-bulk-insert-ja.xlsx">
        <ui-button :color="'purple'">
          <i class="xi-file-text"></i> テンプレート
        </ui-button>
      </a>

      <a :href="templateFile" download="eagle-apply-bulk-insert.xlsx">
        <ui-button :color="'purple'">
          <i class="xi-file-text"></i> 템플릿
        </ui-button>
      </a>
    </template-insert-bulk>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     @button="button"
                     @close="setSelected">
      <ui-button :color="'purple'"
                 @click="capture(true)">
        <i class="xi-camera"></i>캡쳐하기(내부)
      </ui-button>
      <ui-button :color="'green'"
                 @click="capture(false)">
        <i class="xi-camera"></i>캡쳐하기(AWS)
      </ui-button>
    </template-detail>

    <excel-column-select :excelDownload="excelDownload"
                         :metaApi="excelListMetaApi"
                         :listApi="listApi"
                         :searchFilter="searchFilter"
                         @export="excelExport"
                         @close="toggleExcel(false)"/>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { BEUS_URL, alertError } from '@/utils/tools'

import {
  getBeusApplyList,
  getBeusApplyInfo,
  insertBeusApply,
  insertBeusApplyBulk,
  getBeusApplyInsertMeta,
  getBeusApplyBulkInsertMeta,
  requestCapture,
  deleteBeusApplyHtml,
  getBeusApplyExcelMeta
} from '@/api/beusService'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import TemplateInsertBulk from '@/components/_template/InsertBulk'
import UiButton from '@/components/_ui/UiButton'
import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'

import TemplateFile from '@/assets/eagle-apply-bulk-insert.xlsx'
import TemplateFileJa from '@/assets/eagle-apply-bulk-insert-ja.xlsx'
import { STORAGE_ITEM } from '@/utils/constants'

export default {
  name: 'BeusApplyList',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    TemplateInsertBulk,
    UiButton
  },
  created () {
    // console.log('eventbus: ', EventBus.$on('searchData', this.apiParam))
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.userId) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.userId = searchData.userId
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    capture (vip = false) {
      this.apiParam.vip = vip
      this.apiParam.serviceCode = 1
      EventBus.$emit('onLoading', true)
      requestCapture(this.apiParam).then(response => {

      }).catch(error => {
        console.error(error)
      }).finally(() => {
        EventBus.$emit('onLoading', false)
        alert('캡쳐 요청이 완료되었습니다. 잠시 후 확인해주세요.')
        this.showDetail(this.tempDetailData)
      })
    },
    toReport (data) {
      window.open(`${BEUS_URL}/report/${data._id}/`)
    },
    showDetail (data) {
      this.tempDetailData = data
      this.apiParam = { aid: data._id }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    toggleInsertBulk (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insertBulk = !this.show.insertBulk
      } else {
        this.show.insertBulk = value
      }
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_apply'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('service', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
      if (value === 'userBeusApply') {
        localStorage.setItem(STORAGE_ITEM.searchData, JSON.stringify(this.apiParam))
        const route = this.$router.resolve({ name: 'ServiceBeusApplyAB' })
        window.open(route.href, '_blank')
      } else if (value === 'deleteHtml') {
        if (confirm('삭제 하시겠습니까?')) {
          EventBus.$emit('onLoading', true)
          this.apiParam = null
          deleteBeusApplyHtml({
            aid: data.aid,
            htmlId: data._id
          }).catch(error => {
            alertError('삭제에 실패했습니다.', error)
          }).finally(() => {
            this.apiParam = { aid: data.aid }
            EventBus.$emit('onLoading', false)
          })
        }
      }
    },
    setSelected (row) {
      this.selected = row
    },
  },
  data () {
    return {
      reload: 0,
      selected: null,

      infoApi: getBeusApplyInfo,
      insertApi: insertBeusApply,
      insertBulkApi: insertBeusApplyBulk,
      insertMetaApi: getBeusApplyInsertMeta,
      bulkInsertMetaApi: getBeusApplyBulkInsertMeta,
      apiParam: null,
      
      listApi: getBeusApplyList,
      excelListMetaApi: getBeusApplyExcelMeta,
      buttonOption: [
        {
          action: 'toReport',
          columnText: '리포트',
          buttonText: '<i class="xi-document"></i>',
          if: ['state', '!=', 'error']
        },
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',

      show: {
        insert: false,
        insertBulk: false
      },
      excelDownload: false,
      searchFilter: {},
      initialSearchFilter: null,

      templateFile: TemplateFile,
      templateFileJa: TemplateFileJa,
      tempDetailData: null
    }
  }
}
</script>

<style lang="scss" scoped>
.beus-apply-list-wrap {
}
</style>
