<template>
  <div class="chart_container" :style="chartStyle">
    <component :is="chartComponent"
      :chartType="chartType"
      :chartOptions="chartOptions"
      :style="chartStyle" />
  </div>
</template>

<script>
import LineChart from './LineChart'
import BarChart from './BarChart'
import BarLineChart from './BarLineChart'
import ScatterChart from './ScatterChart'
import PieChart from './PieChart'
import MapChart from './MapChart'

export default {
  name: 'AppChart',
  components: {
    LineChart,
    BarChart,
    BarLineChart,
    ScatterChart,
    PieChart,
    MapChart
  },
  props: {
    chartType: {
      type: String,
      required: true
    },
    chartOptions: {
      type: Object
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: [Number, String]
  },
  computed: {
    chartComponent () {
      switch (this.chartType) {
        case 'line':
          return 'LineChart'
        case 'bar':
          return 'BarChart'
        case 'barLine':
          return 'BarLineChart'
        case 'scatter':
          return 'ScatterChart'
        case 'pie':
          return 'PieChart'
        case 'map':
          return 'MapChart'
        default:
          throw new Error(`invalid chartType: ${this.chartType}`)
      }
    },
    chartStyle () {
      let width = this.width
      let height = this.height

      if (typeof width === 'number') {
        width += 'px'
      }
      if (typeof height === 'number') {
        height += 'px'
      }
      return {
        width,
        height
      }
    }
  }
}
</script>

<style lang="scss">
</style>
