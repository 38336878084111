<template>
  <div>
      <div class="select-active"
          @click="selectboxOn = !selectboxOn"
          :class="{ active: selectboxOn }">컬럼 선택 <i class="xi-arrow-down"></i></div>
      <div class="select-check"
      :class="{ active: selectboxOn }">
          <template v-for="(option,index) in initialOptions">
            <div class="ui-checkbox" v-bind:key="index">
              {{option.name}}
              <div class="ui-checkbox-container"
                  :class="{ disabled, error, required }">
                <label v-if="$slots.default"
                      class="ui-checkbox-label"
                      :for="uuid">
                  <slot></slot>
                </label>
                <div class="ui-checkbox-wrap">
                  <label>
                    <input type="checkbox"
                          class="a11y-invisible"
                          :placeholder="placeholder"
                          :disabled="option.disabled"
                          :value="option.value"
                          @change="onInput(index)"
                          @click="onFocus" />
                    <i v-if="option.value"
                      class="xi-toggle-on"></i>
                    <i v-else
                      class="xi-toggle-off"></i>
                    <span v-if="option.value"
                          class="status">ON</span>
                    <span v-else
                          class="status">OFF</span>
                  </label>
                </div>
                <span v-if="required"
                      class="required">
                  필수
                </span>
              </div>
              <div v-if="error"
                  class="ui-checkbox-error"
                  v-html="error">
              </div>
            </div>
          </template>
      </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid'

export default {
  name: 'UiSelectCheck',
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      default: '',
      required: true
    },
    options: {
      default: '',
      required: false
    },
    error: {
      type: String,
      default: '',
      required: false
    }
  },
  methods: {
    onFocus () {
      this.$emit('focus')
    },
    onInput (index) {
      this.initialOptions[index].value = !this.initialOptions[index].value
      this.$emit('input', this.initialOptions)
    },
    onClear () {
      this.$emit('input', this.initialValue)
    },
    selectCheck () {
      this.selectboxOn = true
    }
  },
  data () {
    return {
      uuid: uuid.v4(),
      initialValue: this.value,
      initialOptions: this.options,
      selectboxOn: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .select-active {
    display: block;
    position: relative;
    width: 100%;
    height: 45px;
    line-height: 45px;
    border: 1px solid #aaa;
    margin-top: 15px;
    box-sizing: border-box;
    padding-left: 15px;
    cursor: pointer;
    .xi-arrow-down {
      position: absolute;
      right: 15px;
      top: 40%;
      display: inline-block;
    }
    &.active {
      .xi-arrow-down {
        transform:rotate(180deg);
      }
    }
  }

  .select-check {
    width: calc(100% - 2px);
    height: 0px;
    overflow-y: scroll;
    transition: all 0.1s ease;
    border: none;
    &.active {
      margin-top: -1px;
      max-height: 325px;
      height: auto;
      border: 1px solid #ddd;
    }
  }

.ui-checkbox {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 15px;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }

  .ui-checkbox-container {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    // border-bottom: 2px solid;
    // border-bottom-color: $gray;
    // transition: border-bottom-color 0.3s ease;

    .ui-checkbox-label {
      flex-grow: 0;
      min-width: 70px;
      flex-basis: 70px;
      padding: 5px 0 5px 7px;
      color: $black;
      opacity: 0.5;
      transition: color 0.3s ease;
    }

    .ui-checkbox-wrap {
      flex-grow: 1;
      padding: 0;
      label {
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 100%;
        height: 40px;
        line-height: 2.5;
        text-align: right;
        padding: 0px 15px 0;
        box-sizing: border-box;
        cursor: pointer;
      }
      span.status {
        display: inline-block;
        margin: 7px 0 0 -3px;
        font-size: 10px;
        cursor: pointer;
        color: $gray;
      }

      i {
        cursor: pointer;
        vertical-align: middle;
        line-height: 24px;
        font-size: 28px;
        color: $dark;

        &.xi-toggle-off {
          color: $gray;
        }
      }
    }

    .required {
      position: absolute;
      top: 50%;
      right: 7px;
      display: block;
      width: 1px;
      height: 5px;
      border-radius: 20px;
      background-color: $orange;
      color: transparent;
      line-height: 17px;
      padding: 0 2px;
      overflow: hidden;
      font-size: 11px;
      transform: translateY(-50%);
      text-align: center;
      letter-spacing: -1px;
      transition: width 0.3s ease,
                  height 0.3s ease,
                  color 0.3s ease;
    }

    .xi-close {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $black;
      font-size: 12px;
      cursor: pointer;
      opacity: 0;
      transition: color 0.3s ease,
                  right 0.3s ease,
                  opacity 0.3s ease;
    }

    &.disabled {
      border-bottom-color: $white;
    }

    &.error {
      border-bottom-color: $red;
    }

    &:hover {
      border-bottom-color: $blue;

      .ui-checkbox-label {
        color: $blue;
        opacity: 1;
      }

      .xi-close {
        opacity: 0.3;

        &:hover,
        &:active {
          opacity: 0.5;
        }
      }

      .required {
        width: 25px;
        height: 17px;
        color: $white;
      }
    }

    &.required {
      .xi-close {
        right: 20px;
      }

      &:hover {
        .xi-close {
          right: 40px;
        }
      }
    }
  }

  .ui-checkbox-error {
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 12px;
    color: $red;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
