<template>
  <div class="monitoring-wrap">
    <template-search-read
      :readApi="readApi"
      :reload="reload"
      @searchFilter="getSearchFilter"
    >
    </template-search-read>
  </div>
</template>

<script>
import { getLoginHistoryBeusDashboard } from "@/api/loginHistory";

import TemplateSearchRead from "@/components/_template/SearchRead";

export default {
  name: "BeusLoginHistoryDashboard",
  components: {
    TemplateSearchRead,
  },
  methods: {
    getSearchFilter(value) {
      this.searchFilter = value;
    },
  },
  data() {
    return {
      reload: 0,
      readApi: getLoginHistoryBeusDashboard,
      searchFilter: {},
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
