<template>
  <div class="payment-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :reload="reload"
                          :selected="selected"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @showDetail="showDetail">
      <ui-button :color="'green'"
                 @click="toggleInsert">
        <i class="xi-plus"></i> 플랜 적용
      </ui-button>
      <ui-button :color="'green'"
                 @click="toggleInsertPv">
        <i class="xi-battery-o"></i> PV 충전
      </ui-button>
      <ui-button :color="'purple'"
                 @click="toggleExcel(true)">
        <i class="xi-view-list"></i> 엑셀 다운로드
      </ui-button>
    </template-search-list>

    <template-insert :show="show.insert"
                     :metaApi="insertMetaApi"
                     :insertApi="insertApi"
                     :service="'beusable'"
                     @reload="reloadList"
                     @saved="reloadList"
                     @close="toggleInsert" :menu="'plan'">
      <h4 class="title"><i class="bi-beus"></i> 플랜 적용</h4>
    </template-insert>

    <template-insert :show="show.insertPv"
                     :metaApi="insertPvMetaApi"
                     :insertApi="insertPvApi"
                     :menu="'chargePV'"
                     @reload="reloadList"
                     @saved="showDetail"
                     @close="toggleInsertPv">
      <h4 class="title"><i class="bi-beus"></i> PV 충전</h4>
    </template-insert>

    <template-detail :infoApi="infoApi"
                     :editApi="editApi"
                     :removeApi="removeApi"
                     :service="'beusable'"
                     :apiParam="apiParam"
                     @reload="reloadList"
                     @close="setSelected">
    </template-detail>

    <excel-column-select :excelDownload="excelDownload"
                         :metaApi="excelListMetaApi"
                         :listApi="listApi"
                         :searchFilter="searchFilter"
                         @export="excelExport"
                         @close="toggleExcel(false)"/>
  </div>
</template>

<script>

import { getBeusPaymentList, getBeusPaymentInfo, updateBeusPayment, insertBeusPayment, getBeusPaymentInsertMeta, removeBeusPayment, getBeusPaymentExcelMeta } from '@/api/plan'
import { getBeusCreditInsertMeta, insertBeusCredit } from '@/api/credit'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import TemplateInsert from '@/components/_template/Insert'
import UiButton from '@/components/_ui/UiButton'
import { EventBus } from '@/utils/event-bus'

import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'
import { mapGetters } from 'vuex'
import { STORAGE_ITEM } from '@/utils/constants'

export default {
  name: 'BeusPlanList',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    TemplateInsert,
    UiButton
  },
  computed: {
    ...mapGetters([
      'searchData'
    ])
  },
  created () {
    // console.log('eventbus: ', EventBus.$on('searchData', this.apiParam))
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.userId) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.userId = searchData.userId
        this.initialSearchFilter.userIdExact = true
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    showDetail (data) {
      this.apiParam = { id: data._id }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    toggleInsertPv (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insertPv = !this.show.insertPv
      } else {
        this.show.insertPv = value
      }
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_beus_plan'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('planBeus', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    setSelected (row) {
      this.selected = row
    },
  },
  data () {
    return {
      reload: 0,
      selected: null,

      infoApi: getBeusPaymentInfo,
      editApi: updateBeusPayment,
      removeApi: removeBeusPayment,
      insertApi: insertBeusPayment,
      insertMetaApi: getBeusPaymentInsertMeta,
      insertPvApi: insertBeusCredit,
      insertPvMetaApi: getBeusCreditInsertMeta,
      apiParam: null,
      
      listApi: getBeusPaymentList,
      excelListMetaApi: getBeusPaymentExcelMeta,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',

      show: {
        insert: false,
        insertPv: false
      },
      excelDownload: false,
      searchFilter: {},
      initialSearchFilter: null
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-list-wrap {
}
</style>
