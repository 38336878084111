<template>
  <div class="template-search-read"
       :class="{ searching: show.search }">
    <div class="top-area">
      <slot></slot>
      <ui-button :color="'blue'"
                 @click="toggleSearch">
        <i class="xi-search"></i> 검색
      </ui-button>
    </div>

    <div class="detail-area">
      <ui-detail v-if="fieldOption && infoData"
                 :meta="fieldOption"
                 :data="infoData"
                 :tableSortBy="tableSortBy"
                 @setTableData="setTableData"
                 @button="button"
                 @changeTableSort="changeTableSort">
      </ui-detail>
    </div>

    <div class="search-area">
      <ui-filter v-if="searchOption"
                 :searchOption="searchOption"
                 :searchFilter="searchFilter.current"
                 @updateFilter="updateFilter"
                 @search="search" />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'

import { alertError } from '@/utils/tools'
import { getMemoryList } from '@/api/monitoring'

import UiFilter from '@/components/_ui/UiFilter'
import UiDetail from '@/components/_ui/UiDetail'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'TemplateSearchRead',
  components: {
    UiFilter,
    UiDetail,
    UiButton
  },
  props: {
    readApi: {},
    defaultSortBy: {
      type: String,
      default: '-regDate'
    },
    buttonOption: {
      type: Array,
      default () {
        return []
      }
    },
    initialSearchFilter: {
      type: Object,
      required: false
    },
    reload: {
      required: false
    },
    excelDownload: {
      required: false,
      default: false
    },
    service: {
      required: false
    }
  },
  created () {
    if (this.initialSearchFilter) {
      for (const [key, value] of Object.entries((this.initialSearchFilter))) {
        this.searchFilter.current[key] = value
        this.searchFilter.previous[key] = value
      }
    }

    this.loadData(true)
  },
  watch: {
    reload () {
      this.loadData()
    }
  },
  methods: {
    checkRequired () {
      if (this.service === 'loginHistory') {
        if (!this.searchFilter.previous['loginDate'] || !this.searchFilter.previous['loginDate']['start'] || !this.searchFilter.previous['loginDate']['end']) {
          alert('시작일과 종료일을 지정해주세요', 'error')
          return false
        } else {
          const startDate = new Date(this.searchFilter.previous['loginDate']['start'])
          const endDate = new Date(this.searchFilter.previous['loginDate']['end'])
          const timeDiff = endDate - startDate
          const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
          if (dayDiff >= 7) {
            alert('일주일 이상으로 설정 불가합니다')
            return false
          }
          return true
        }
      } else {
        return true
      }
    },
    loadData () {
      if (!this.checkRequired()) return
      EventBus.$emit('onLoading', true)
      this.readApi({
        searchFrom: this.searchFilter.previous
      }, true).then(response => {
        if (typeof response.result !== 'object' || response.message) {
          alertError('정보를 불러오지 못 했습니다.', typeof response.result !== 'object' ? response.result : response.message)
        } else {
          this.infoData = response.result.info
          this.fieldOption = { field: response.result.meta.field }
          this.searchOption = response.result.meta.searchOption
          this.$emit('searchFilter', this.searchFilter.previous)
        }
      }).catch(error => {
        if (error.response) {
          alertError('정보를 불러오지 못 했습니다.', typeof error.response.data !== 'object' ? error.response.data : error.response.data.message)
        } else {
          alertError('정보를 불러오지 못 했습니다.', error)
        }
      }).finally(() => {
        EventBus.$emit('onLoading', false)
      })
    },

    toggleSearch () {
      this.show.search = !this.show.search
    },

    updateFilter (filter) {
      this.searchFilter.current = filter
    },
    search () {
      this.sortBy = this.defaultSortBy
      this.navigationOption = {
        pageNum: 1,
        pageSize: 50,
        totalCount: 0
      }
      this.searchFilter.previous = { ...this.searchFilter.current }

      this.loadData()
    },
    setTableData (field, idx, column, data) {
      this.infoData[field][idx][column] = data
    },
    button (value, data) {
      this.$emit('button', value, data)
    },
    changeTableSort (field, sort) {
      this.tableSortBy[field] = sort
      if (field === 'memoryList') {
        EventBus.$emit('onLoading', true)
        const params = { sort: sort }
        getMemoryList(params).then(resp => {
          this.infoData.memoryList = resp.result.list
          EventBus.$emit('onLoading', false)
        })
      } else {
        const desc = sort.indexOf('-') === 0
        sort = sort.replace('-', '').replace('+', '')
        this.infoData[field].sort((a, b) => {
          if (desc) {
            return a[sort] > b[sort] ? -1 : 1
          } else {
            return a[sort] < b[sort] ? -1 : 1
          }
        })
      }
    }
  },
  data () {
    return {
      loading: false,

      infoData: null,

      searchOption: null,
      searchFilter: {
        previous: {},
        current: {}
      },

      fieldOption: null,

      tableSortBy: {},

      show: {
        search: true,
        userCreate: false,
        userDetail: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.template-search-read {
  .top-area {
    z-index: 20;
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .detail-area {
    margin-right: 0;
    transition: margin-right 0.3s ease;

    & :deep {
      .detail-top {
        display: none;
      }

      .ui-data.list {
        margin: 0 -10px;
      }
    }
  }

  .search-area {
    z-index: 30;
    position: fixed;
    bottom: 0;
    right: -260px;
    width: 250px;
    height: calc(100vh - 71.64px);
    margin-left: 200px;
    background-color: $dimlight;
    transition: right 0.3s ease;
  }

  &.searching {
    .detail-area {
      margin-right: 250px;
    }

    .search-area {
      right: 0;
    }
  }
}
</style>
