<template>
  <div class="monitoring-wrap">
    <template-search-read :readApi="readApi"
                          :reload="reload"
                          @button="button"
                          @searchFilter="getSearchFilter">
    </template-search-read>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { alertError } from '@/utils/tools'

import { getBeusPaymentErrorMonitoring, deleteBeusPaymentDateError, deleteBeusPaymentLastStatusError } from '@/api/monitoring'

import TemplateSearchRead from '@/components/_template/SearchRead'

export default {
  name: 'MonitoringBeusPaymentError',
  components: {
    TemplateSearchRead
  },
  methods: {
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
      if (value === 'deleteDateError') {
        if (confirm('완료 처리를 하시겠습니까?')) {
          EventBus.$emit('onLoading', true)
          deleteBeusPaymentDateError({
            id: data.id
          }).catch(error => {
            alertError('삭제에 실패했습니다.', error)
          }).finally(() => {
            EventBus.$emit('onLoading', false)
          })
        }
      } else if (value === 'deleteLastStatusError') {
        if (confirm('완료 처리를 하시겠습니까?')) {
          EventBus.$emit('onLoading', true)
          deleteBeusPaymentLastStatusError({
            id: data.id
          }).catch(error => {
            alertError('삭제에 실패했습니다.', error)
          }).finally(() => {
            EventBus.$emit('onLoading', false)
          })
        }
      }
    }
  },
  data () {
    return {
      reload: 0,
      readApi: getBeusPaymentErrorMonitoring,
      searchFilter: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.monitoring-wrap {
}
</style>
