<template>
  <div class="app_dialog" :class="{ dimmed, fixed }">
    <div class="dialog_content" :style="contentStyle">
      <p class="title" :class="dialogData.titleClass" v-if="dialogData.title" v-html="dialogData.title"></p>
      <p class="description" v-if="dialogData.description" v-html="dialogData.description"></p>

      <div v-if="dialogData.from && dialogData.from === 'timezone'" class="timezone-area">
        <ul class="url">
          <li v-for="item in dialogData.data.timezoneChangedList" :key="dialogData.data.timezoneChangedList.indexOf(item)">
            <i class="icon icon--link-small"></i>
            <span class="inputUrl">{{ item.url }}</span>
            <span class="timezoneName">{{ item.timezoneName }}</span>
          </li>
        </ul>
        <ul>

        </ul>
      </div>

      <p class="action">
        <template v-if="dialogData.type === 'alert'">
          <ui-button @click="onCloseClick">{{ ok }}</ui-button>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import getDialogData from '@/libs/text/dialog-data'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'AppDialog',
  components: {
    UiButton
  },
  props: {
    className: {
      type: String,
      required: false
    },
    dialogType: {
      type: String,
      required: false
    },
    customData: {
      type: Object,
      required: false
    },
    yes: {
      type: String,
      default () {
        return 'common.confirm'
      }
    },
    no: {
      type: String,
      default () {
        return 'common.no'
      }
    },
    ok: {
      type: String,
      default () {
        return 'common.ok'
      }
    },
    cancel: {
      type: String,
      default () {
        return 'common.cancel'
      }
    },
    yesValue: {
      default: true
    },
    noValue: {
      default: false
    },
    dimmed: {
      type: Boolean,
      default: true
    },
    fixed: {
      type: Boolean,
      default: true
    },
    contentStyle: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      rawData: null
    }
  },
  computed: {
    containerClass () {
      if (this.customData.from) {
        return this.customData.from
      } else {
        return ''
      }
    },
    dialogData () {
      if (this.customData) {
        return this.customData
      }

      const paths = this.dialogType.split('.')
      let data = this.rawData

      for (const path of paths) {
        if (!data[path]) {
          throw new Error(`invalid dialogType: ${this.dialogType}`)
        } else {
          data = data[path]
        }
      }
      return data
    }
  },
  created () {
    this.rawData = getDialogData()

    if (document.activeElement) {
      document.activeElement.blur()
    }
  },
  methods: {
    onCloseClick (value) {
      if (this.$close) {
        // called by vue-modal-dialogs
        this.$close(value)
      } else {
        // called by normal component
        this.$emit('onCloseClick', value)
      }
    }
  }
}
</script>

<style lang="scss">
.app_dialog {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  &.fixed {
    position: fixed;
    z-index: 999;
  }

  &.dimmed {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .icon--close14{
    position: absolute;
    top: 12px;
    right: 12px;
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
  }

  .dialog_content {
    pointer-events: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 530px;
    box-sizing: border-box;
    padding: 50px 40px 25px 40px;
    border-radius: 14px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    text-align: center;

    .timezone-area {
      width: 440px;
      max-height: 440px;
      overflow-y: scroll;
      padding: 20px;
      padding-top: 0px;
      border-radius: 6px;
      border: solid 1px #d7d7d7;
      background-color: #f4f4f4;
      margin: -15px 0px 40px;
      font-size: 14px;
      line-height: 1.43;

      ul.main {
        border-bottom: 1px dashed #bbb;
        padding-bottom: 14.5px;

        & li {
          text-align: left;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }
          & > .timezone-title {
            min-width: 100px;
            display: inline-block;
            overflow: hidden;
            color: #666;
          }

          & > .timezone-value {
            display: inline-block;
            color: #2f2f2f;
            width: calc(100% - 100px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      ul.url {
        margin-top: 16.5px;
        & li {
          text-align: left;
          margin-bottom: 5px;
          border-bottom: 1px solid;
          display: flex;
          padding-bottom: 10px;

          &:last-child {
            margin-bottom: 0px;
          }

          & i {
            top:-1px;
            position: relative;
            margin: 0 5px 0 0;
          }

          & > span {
            display: inline-block;
            color: #2f2f2f;
            width: calc(100% - 90px);
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .timezoneName {
            text-align: right;
          }
          .inputUrl {
            white-space: pre-line;
            word-wrap: break-word;
          }
        }
      }

    }

    .icon {
      margin-bottom: 13px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      color: #444;
      margin-bottom: 10px;
      line-height: 1.2;

      // &.warning {
      //   color: $brandColor;
      // }
    }

    .description {
      font-size: 14px;
      color: #2f2f2f;
      margin-bottom: 40px;
      line-height: 1.43;
      white-space: pre-line;

      ul {
        display: inline-block;
        text-align: left;

        li {
          position: relative;
          margin: 10px 0 10px 10px;

          &::before {
            position: absolute;
            top: -1px;
            left: -10px;
            content: "-"
          }
        }
      }

      .highlight {
        display: inline-block;
        border-top: 1px solid #d8d8d8;
        margin-top: 25px;
        padding-top: 18px;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.46;
        color: #ec0047;
      }
    }

    .action {
      button {
        margin: 0 3px;
      }
    }
  }
}
</style>
