<template>
  <div class="chart line_chart"></div>
</template>

<script>
import 'echarts/lib/chart/scatter'
import 'echarts/lib/component/tooltip'
import ChartMixin from './ChartMixin'

export default {
  name: 'ScatterChart',
  mixins: [
    ChartMixin
  ],
  methods: {
    getDefaultOptions () {
      return {
        grid: {
          containLabel: true,
          right: 20,
          left: 5,
          top: 30,
          bottom: 0
        },
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          },
          axisLine: {
            lineStyle: {
              color: '#767676'
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.line_chart {
  position: relative;
  width: 600px;
  height: 400px;
}
</style>
