<template>
  <div class="user-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :reload="reload"
                          :selected="selected"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @showDetail="showDetail">
    </template-search-list>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     @reload="reloadList"
                     @close="setSelected">
    </template-detail>
  </div>
</template>

<script>

import {
  getUnauthUserListSs, getUnauthUserInfoSs
} from '@/api/user'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'UserList',
  components: {
    TemplateSearchList,
    TemplateDetail,
    UiButton
  },
  methods: {
    showDetail (data) {
      this.apiParam = { userNum: data.userNum, userId: data.userId }
    },
    reloadList () {
      this.reload++
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    setSelected (row) {
      this.selected = row
    },
  },
  data () {
    return {
      reload: 0,
      selected: null,
      
      infoApi: getUnauthUserInfoSs,
      apiParam: null,
      listApi: getUnauthUserListSs,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',
      searchFilter: {}
    }
  }
}
</script>

<style lang="scss" scoped>

.user-list-wrap {
  ::v-deep .template-search-list {
    & > .search-area {
      width: 318px;
    }
  }

  ::v-deep .ui-input-wrap {
    padding-right: 20px !important;
  }

  ::v-deep .table-area {
    margin-right: 70px !important;
  }

  ::v-deep .searching {
    & > .table-area {
      margin-right: 350px !important;
    }
  }
}
</style>
