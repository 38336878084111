import { unified } from "unified";
import remarkGfm from "remark-gfm";
import markdown from "remark-parse";
import remark2rehype from "remark-rehype";
import html from "rehype-stringify";
import rehypeExternalLinks from "rehype-external-links";
import { visit } from "unist-util-visit";

const addImgInlineStyles = () => {
  return (tree) => {
    visit(tree, "element", (node) => {
      if (node.tagName === "img") {
        node.properties.style =
          "width: 80%; max-width: 700px; height: auto; padding: 20px 0;";
      }
      if (node.tagName === "ol" || node.tagName === "ul") {
        node.properties.style = "list-style-type: decimal; padding: inherit;";
      }
      if (node.tagName === "ul") {
        node.properties.style = "list-style-type: disc; padding-left: 20px;";
      }
    });
  };
};

export const convertMarkdownToHtml = (markDownText) => {
  const htmlText = unified()
    .use(markdown)
    .use(remarkGfm)
    .use(remark2rehype)
    .use(rehypeExternalLinks, { rel: ["nofollow"], target: "_blank" })
    .use(addImgInlineStyles)
    .use(html)
    .processSync(markDownText);
  return htmlText;
};
