import { request } from '@/utils/api-request'

export function getEventList (params) {
  const url = '/admin/event/list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getEventExcelMeta (force) {
  const url = '/admin/event/excel_meta'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getBeusCouponList (params) {
  const url = '/admin/beus/coupon/coupon_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusCouponInfo (params) {
  const url = '/admin/beus/coupon/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusCouponInsertMeta (force) {
  const url = '/admin/beus/coupon/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function insertBeusCoupon (params) {
  const url = '/admin/beus/coupon/create_coupon'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateBeusCoupon (params) {
  const url = '/admin/beus/coupon/modify_coupon'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removeBeusCoupon (params) {
  const url = '/admin/beus/coupon/remove_coupon'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getPopupList (params) {
  const url = '/admin/notice/get_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getPopupInfo (params) {
  const url = '/admin/notice/info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusPopupInsertMeta (force) {
  const url = '/admin/notice/beus/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function getBaPopupInsertMeta (force) {
  const url = '/admin/notice/ba/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function upsertPopup (params) {
  const url = '/admin/notice/upsert'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removePopup (params) {
  const url = '/admin/notice/delete'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getPopupTemplateList (params) {
  const url = '/admin/notice/template/get'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function insertPopupTemplate (params) {
  const url = '/admin/notice/template/add'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removePopupTemplate (params) {
  const url = '/admin/notice/template/delete'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusFaqList (params) {
  const url = '/admin/beus/faq/get_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusFaqInfo (params) {
  const url = '/admin/beus/faq/get_info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBeusFaqInsertMeta (force) {
  const url = '/admin/beus/faq/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function insertBeusFaq (params) {
  const url = '/admin/beus/faq/create'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateBeusFaq (params) {
  const url = '/admin/beus/faq/modify'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removeBeusFaq (params) {
  const url = '/admin/beus/faq/remove'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaFaqList (params) {
  const url = '/admin/ba/faq/get_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaFaqInfo (params) {
  const url = '/admin/ba/faq/get_info'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getBaFaqInsertMeta (force) {
  const url = '/admin/ba/faq/insert'
  const config = {
    method: 'get',
    url
  }
  return request(config, force, '24h')
}

export function insertBaFaq (params) {
  const url = '/admin/ba/faq/create'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function updateBaFaq (params) {
  const url = '/admin/ba/faq/modify'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function removeBaFaq (params) {
  const url = '/admin/ba/faq/remove'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}
