<template>
  <div class="monitoring-wrap">
    <template-search-read :readApi="readApi"
                          :reload="reload"
                          @button="button"
                          @searchFilter="getSearchFilter">
    </template-search-read>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { alertError } from '@/utils/tools'

import { getCaptureMonitoring, updateCaptureMemo } from '@/api/monitoring'

import TemplateSearchRead from '@/components/_template/SearchRead'
import { requestCapture } from '@/api/beusService'
import { BEUS_URL, SSBEUS_URL } from '@/utils/tools'
import {SERVICE_CODE_SAMSUNG, SERVICE_CODE_BEUSABLE} from "@/utils/constants";

export default {
  name: 'MonitoringCapture',
  components: {
    TemplateSearchRead
  },
  methods: {
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
      if (value === 'reCapture') {
        if (confirm('재촬영 하시겠습니까?')) {
          EventBus.$emit('onLoading', true)
          const serviceCode = data.serviceName === 'Samsung' ? SERVICE_CODE_SAMSUNG : SERVICE_CODE_BEUSABLE
          requestCapture({ aid: data.aid, vip: false, serviceCode: serviceCode }, true).then(response => {
            console.log(response)
          }).catch(error => {
            console.error(error)
          }).finally(() => {
            EventBus.$emit('onLoading', false)
            alert('캡쳐 요청이 완료되었습니다. 잠시 후 확인해주세요.')
          })
        }
      } else if (value === 'report') {
        window.open(`${data.serviceName === 'Samsung' ? SSBEUS_URL : BEUS_URL}/report/${data.aid}/`)
      } else if (value === 'save') {
        EventBus.$emit('onLoading', true)
        updateCaptureMemo({
          aid: data.aid,
          captureMemo: data.captureMemo,
        }).then(response => {
          alert('메모가 저장되었습니다.')
          this.reload++
        }).catch(error => {
          alertError('저장에 실패했습니다.', error)
        }).finally(() => {
          EventBus.$emit('onLoading', false)
        })
      }
    }
  },
  data () {
    return {
      reload: 0,
      readApi: getCaptureMonitoring,
      searchFilter: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.monitoring-wrap {
}
</style>
