<template>
  <div class="chart pie_chart"></div>
</template>

<script>
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import ChartMixin from './ChartMixin'

export default {
  name: 'PieChart',
  mixins: [
    ChartMixin
  ],
  methods: {
    getDefaultOptions () {
      return {
        legend: {
          show: false
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }
      }
    }
  }
}
</script>

<style lang="scss">
.line_chart {
  position: relative;
  width: 600px;
  height: 400px;
}
</style>
