<template>
  <div v-if="type1 === 'list'"
       class="ui-data list">
    <ui-table-list v-if="option && value && value.length"
                   :inside="true"
                   :columnData="option"
                   :columnFixed="columnFixed"
                   :listData="value"
                   :sortBy="sortBy"
                   :canHide="name === 'runningList'"
                   @set="setTableData"
                   @changeSort="changeTableSort"
                   @button="button" />
  </div>
  <div v-else-if="type1 === 'tab'">
    <ui-tab v-if="option && value"
            :tab="option"
            :data="value"
            @button="button" />
  </div>
  <div v-else-if="type1 === 'editor4'">
    <ui-editor-4 :value="value"
                 :readonly="true" />
  </div>
  <div v-else-if="type1 === 'editor'">
    <ui-editor-5 :value="value"
                 :readonly="true"  />
  </div>
  <div v-else-if="type1 === 'multiline'"
       class="ui-multiline">
    <div v-for="[field, line] in Object.entries(option)"
         class="ui-multiline-line"
         :key="`data-${field}`">
      <ui-data :type="line.type"
               :value="value[line.field]"
               :option="line.option"
               :listData="line.options"
               :sortBy="line.sortBy"
               @button="button" />
    </div>
  </div>
  <div v-else-if="type1 === 'button'">
    <ui-button :color="option.color" @click="$emit('button', option.value)">
      {{option.label}}
    </ui-button>
  </div>
  <div v-else-if="type1 === 'messages'">
    <div v-for="lala in value" class="ui-messagebox">
      <ui-data v-for="[field, line] in Object.entries(option)"
        class=""
        :key="`data-${field}`" :type="line.type"
        :value="lala[line.field]"
        :option="line.option"
        :listData="line.options"
        :sortBy="line.sortBy"
        @button="button" />
    </div>
  </div>
  <div v-else-if="type2==='message'" class="ui-message" v-html="computedValue" @click="copy"></div>
  <span v-else
        class="ui-data"
        :class="[type1 ? `type1${type1}` : null,
                 type2 ? `type2${type2}` : null,
                 type3 ? `type3${type3}` : null,
                 type4 ? `type3${type4}` : null,
                 align ? align: null
                 ]"
        v-html="computedValue"
        :title="[type1 != 'html' && type2 !='imageupload' ? value: '']"
        ref="dataUi"
        @click="copy"></span>
</template>

<script>
import { format } from 'date-fns'
import { utcToZonedTime, getTimezoneOffset } from 'date-fns-tz'

import { mapGetters } from 'vuex'
import { toastWarning, thousand } from '@/utils/tools'
import {convertMarkdownToHtml} from '@/utils/parse'

import UiEditor4 from '@/components/_ui/UiEditor4'
import UiEditor5 from '@/components/_ui/UiEditor5'
import UiButton from '@/components/_ui/UiButton'
import {
  CX_HEATMAP,
  DEFAULT_TIMEZONE_NAME, JAM_CHARGE, JAM_EXPIRED, JAM_REFUND,
  JAM_TYPE_CHARGE, JAM_TYPE_EXTINCTION,
  JAM_TYPE_MOVE,
  JAM_TYPE_USE, MOVE_TO_BASKET, MOVE_TO_MY_JAM,
  POSITION_BA_APPLY_BASKET,
  POSITION_MY_JAM, PV_CHARGE, JAM_SERVICE_BA, JAM_SERVICE_COMMON, BUY_REPORT_PREFIX, BUY_REPORT_INDEPTH, BUY_REPORT_PERIOD,
  CANCEL_REPORT_INDEPTH, CANCEL_REPORT_PERIOD, CANCEL_REPORT_PREFIX, CANCEL_REPORT_SEGMENT, BUY_REPORT_SEGMENT
} from '@/utils/constants'

const entityMap = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;', '`': '&#x60;', '=': '&#x3D;' }

export default {
  name: 'UiData',
  components: {
    UiButton,
    UiEditor4,
    UiEditor5,
    UiTableList: () => import('@/components/_ui/UiTableList'),
    UiTab: () => import('@/components/_ui/UiTab'),
    UiData: () => import('@/components/_ui/UiData')
  },
  props: {
    type: {
      type: String,
      default: 'text',
      required: false
    },
    value: {
      required: false
    },
    option: {
      required: false
    },
    listData: {
      required: false
    },
    sortBy: {
      required: false
    },
    align: {
      required: false
    },
    name: {
      required: false
    },
    timezoneName: {
      required: false
    },
    fieldName: {
      required: false
    },
    model: {
      required: false
    }
  },
  data () {
    return {
      columnFixed: -1
    }
  },
  created () {
    if (this.type === 'list' && this.option) {
      Object.entries(this.option).forEach(([key, value], index) => {
        if (value.columnFixed) {
          this.columnFixed = index
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'beusPlanData',
      'baPlanData'
    ]),

    splitedType () {
      return this.type.split('|')
    },
    type1 () {
      return this.splitedType[0] || null
    },
    type2 () {
      return this.splitedType[1] || null
    },
    type3 () {
      return this.splitedType[2] || null
    },
    type4 () {
      return this.splitedType[3] || null
    },
    computedValue () {
      let result = this.value
      if (result === 0 || result === false || result) {
        if (this.type1 === 'text') {
          if (this.type3 === 'urlEncode') {
            result = decodeURI(result)
          } else if (this.type3 === 'json') {
            result = JSON.stringify(result, null, 4)
          } else {
            if (this.fieldName === 'useState') {
              switch (result) {
                case 'use':
                  result = `${this.model.jamUseCount} JAM 사용`
                  break
                case 'notUse':
                  result = '미사용'
                  break
                case 'empty':
                  result = '사용완료'
                  break
                case 'expired':
                  result = `${this.model.remainCount} JAM 유효기간 만료`
                  break
              }
            }
            result = String(result).replace(/[&<>"'`=]/g, (s) => entityMap[s])
          }
          if (this.type2 === 'email') {
            result = `${result}`
          } else if (this.type2 === 'password') {
            result = `<i class="xi-key"></i> ${result}`
          } else if (this.type2 === 'tel') {
            const tel = result.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
            result = `${tel}`
          } else if (this.type2 === 'url') {
            result = `${result}`
          } else if (this.type2 === 'permission') {
            if (result === 'true') {
              result = '<span>Local Manager</span>'
            } else if (result === 'false') {
              result = '<span>Viewer</span>'
            }
          } else if (this.type2 === 'codeList') {
            result = `<span class="code-list">${result}</span>`
          } else if (this.type2 === 'card') {
          } else if (this.type2 === 'version') {
            result = `<span">v. ${result}</span>`
          } else if (this.type2 === 'service') {
            if (result === 'beusable') {
              result = '<i class="bi-beus"></i> Beusable'
            } else if (result === 'ba') {
              result = '<i class="bi-ba"></i> BA'
            } else if (result === 'admin') {
              result = '<i class="bi-eagle"></i> ADMIN'
            }
          } else if (this.type2 === 'jamState') {
            if (result === JAM_REFUND) {
              result = '<span>잼 환불</span>'
            } else if (result === JAM_CHARGE) {
              result = '<span>잼 충전</span>'
            } else if (result === JAM_EXPIRED) {
              result = '<span>유효기간 만료</span>'
            } else if (result === CX_HEATMAP) {
              result = `<span>CX 히트맵 분석 (${thousand(this.model.jam * 1000)}PV)</span>`
            } else if (result === MOVE_TO_BASKET) {
              result = '<span>히트맵 바구니로 이동</span>'
            } else if (result === MOVE_TO_MY_JAM) {
              result = '<span>나의 JAM으로 이동</span>'
            } else if (result === PV_CHARGE) {
              result = `<span>PV 충전 (${thousand(this.model.jam * 10000)}PV)</span>`
            } else if (result === BUY_REPORT_PREFIX) {
              result = '<span>CX 리포트 (Prefix) 구매</span>'
            } else if (result === BUY_REPORT_INDEPTH) {
              result = '<span>CX 리포트 (In-depth) 구매</span>'
            } else if (result === BUY_REPORT_PERIOD) {
              result = '<span>CX 리포트 (기간 비교) 구매</span>'
            } else if (result === BUY_REPORT_SEGMENT) {
              result = '<span>CX 리포트 (Segments) 구매</span>'  
            } else if (result === CANCEL_REPORT_PREFIX) {
              result = '<span>CX 리포트 (Prefix) 환불</span>'
            } else if (result === CANCEL_REPORT_INDEPTH) {
              result = '<span>CX 리포트 (In-depth) 환불</span>'
            } else if (result === CANCEL_REPORT_PERIOD) {
              result = '<span>CX 리포트 (기간 비교) 환불</span>'
            } else if (result === CANCEL_REPORT_SEGMENT) {
              result = '<span>CX 리포트 (Segments) 환불</span>'
            }
          } else if (this.type2 === 'state') {
            if (result === 'unpaid') {
              result = '<span class="state red">실패</span>'
            } else if (result === 'complete') {
              result = '<span class="state blue">완료</span>'
            } else if (result === 'waiting') {
              result = '<span class="state dim">대기</span>'
            } else if (result === 'future') {
              result = ''
            } else if (result === 'exact') {
              result = '<span class="match">Exact</span>'
            } else if (result === 'simple') {
              result = '<span class="match">Simple</span>'
            } else if (result === 'start') {
              result = '<span class="match">Starts</span>'
            } else if (result === 'end') {
              result = '<span class="match">Ends</span>'
            } else if (result === 'contains') {
              result = '<span class="match">Contains</span>'
            } else if (result === 'uninstalled') {
              result = '<span class="state purple">Uninstalled</span>'
            } else if (result === 'error') {
              result = '<span class="state red">ERROR</span>'
            } else if (result === 'stop') {
              result = '<span class="state dim">STOP</span>'
            } else if (result === 'freeover') {
              result = '<span class="state dim">FREEOVER</span>'
            } else if (result === 'run') {
              result = '<span class="state blue">RUN</span>'
            } else if (result === 'scheduledRun') {
              result = '<span class="state dim">분석예약</span>'
            } else if (result === 'analyzing' || result === 'ing') {
              result = '<span class="state blue">분석 중</span>'
            } else if (result === 'tempStop') {
              result = '<span class="state dim">TempStop</span>'
            } else if (result === 'endByAnalysisDate') {
              result = '<span class="state dim">End (분석종료일)</span>'
            } else if (result === 'endByPvLimit') {
              result = '<span class="state dim">End (Pv 제한)</span>'
            } else if (result === 'stopByJamPvEmpty') {
              result = '<span class="state dim">STOP (JAM PV 제한)</span>'
            } else if (result === 'stopByUserCreditEmpty') {
              result = '<span class="state dim">STOP (유저 PV 제한)</span>'
            } else if (result === 'stopByDomainCreditEmpty') {
              result = '<span class="state dim">STOP (도메인 PV 제한)</span>'
            } else if (result === 'open') {
              result = '<span class="state blue">공개</span>'
            } else if (result === 'private') {
              result = '<span class="state red">비공개</span>'
            } else if (result === 'close') {
              result = '<span class="state dim">종료</span>'
            }
          } else if (this.type2 === 'type') {
            if (result === 'general') {
              result = '<span class="state green">일반</span>'
            } else if (result === 'lecture/fest') {
              result = '<span class="state purple">교육/행사</span>'
            } else if (result === 'event') {
              result = '<span class="state blue">이벤트</span>'
            } else if (result === 'fault') {
              result = '<span class="state red">장애공지</span>'
            } else if (result === 'etc') {
              result = '<span class="state dim">기타</span>'
            }
          } else if (this.type2 === 'location') {
            if (result === 'main') {
              result = '<i class="xi-home"></i> 홈'
            } else if (result === 'dashboard') {
              result = '<i class="xi-dashboard-o"></i> 대시보드'
            } else if (result === 'funnel') {
              result = '<i class="xi-filter"></i> 퍼널'
            }
          } else if (this.type2 === 'sender') {
            if (result === 'ai') {
              result ='<i class="bi-solomon"></i> 솔로몬'
            } else {
              result ='<i class="xi-user-o"></i> 고객'
            }
          } else if (this.type2 === 'message') {
            result = convertMarkdownToHtml(result)
          }
          else if (this.type2 === 'method') {
            if (result === 'card' || result === 'justCard') {
              result = '<span class="method card"><i class="xi-credit-card"></i> 카드</span>'
            } else if (result === 'wire transfer' || result === 'justWire') {
              result = '<span class="method wire"><i class="xi-money"></i> 무통장 입금</span>'
            } else if (result === 'coupon') {
              result = '<span class="method coupon"><i class="xi-coupon"></i> 쿠폰</span>'
            } else if (result === '4grit') {
              result = '<span class="method admin"><i class="xi-gift-o"></i> 4GRIT</span>'
            } else if (result === 'general') {
              result = '일반'
            } else if (result === 'reportCredit') {
              result = '리포트 이용권'
            } else if (result === 'admin') {
              result = '<span class="method admin"><i class="xi-briefcase"></i> 어드민</span>'
            } else if (result === 'jam') {
              result = '<span class="method"><i class="bi-jam"></i> Jam</span>'
            }
          } else if (this.type2 === 'lang') {
            if (result === 'ko') {
              result = '<span class="static"><i class="bi-lang-ko"></i> KO</span>'
            } else if (result === 'en') {
              result = '<span class="static"><i class="bi-lang-en"></i> EN</span>'
            } else if (result === 'ja') {
              result = '<span class="static"><i class="bi-lang-ja"></i> JA</span>'
            }
          } else if (this.type2 === 'timezone') {
            let tz = getTimezoneOffset(result) / 60 / 60 / 1000
            if (tz >= 0) {
              tz = '+' + tz
            }
            result += ` <span class="tip timezone">UTC${tz}</span>`
          } else if (this.type2 === 'timezoneName') {
            let tz = getTimezoneOffset(result) / 60 / 60 / 1000
            if (tz >= 0) {
              tz = '+' + tz
            }
            result = `<span class="static">${tz}</span>`
          } else if (this.type2 === 'longtext') {
            result = result.replace(/\n/g, '<br>')
          } else if (this.type2 === 'static') {
            if (result === JAM_TYPE_USE) {
              result = '<span class="static">사용</span>'
            } else if (result === JAM_TYPE_CHARGE) {
              result = '<span class="static">충전</span>'
            } else if (result === JAM_TYPE_MOVE) {
              result = '<span class="static">이동</span>'
            } else if (result === JAM_TYPE_EXTINCTION) {
              result = '<span class="static">소멸</span>'
            } else if (result === JAM_SERVICE_BA) {
              result = '<span class="static">Journey</span>'
            } else if (result === JAM_SERVICE_COMMON) {
              result = '<span class="static">일반</span>'
            } else {
              result = `<span class="static">${result}</span>`
            }
          } else if (this.type2 === 'user') {

          } else if (this.type2 === 'code') {
            result = `<span class="code"><pre>${result}</pre></span>`
          } else if (this.listData) {
            for (const item of this.listData) {
              if (item.value === result) {
                result = item.name
              }
            }
          } else if (this.type2 === 'jamPaid') {
            if (this.value === JAM_REFUND) {
              result = '<span class="state red">취소</span>'
            } else {
              result = '<span class="state blue">결제</span>'
            }
          } else if (this.type2 === 'reportPaid') {
            if (result === 'true') {
              result = '<span>유료</span>'
            } else {
              result = '<span>무료</span>'
            }
          } else if (this.type2 === 'paid') {
            if (Number(result) < 0) {
              result = '<span class="state red">취소</span>'
            } else {
              result = '<span class="state blue">결제</span>'
            }
          } else if (this.type2 === 'contactusState') {
            if (result === 'init') {
              result = '<span class="state dim">인입</span>'
            } else if (result === 'done') {
              result = '<span class="state green">완료</span>'
            } else if (result === 'checking') {
              result = '<span class="state red">확인 중</span>'
            } else if (result === 'follow up') {
              result = '<span class="state yellow">추가 정보 요청</span>'
            } else if (result === 'editing') {
              result = '<span class="state purple">수정 중</span>'
            } else if (result === 'inspecting') {
              result = '<span class="state blue">검수 중</span>'
            }
          } else if (this.type2 === 'reportType') {
            if (result.length > 0) {
              result = result.charAt(0).toUpperCase() + result.slice(1)
              if (result === 'Ranking') {
                result = `<span class="bullet blue"></span> ${result}`
              } else if (result === 'Journey') {
                result = `<span class="bullet orange"></span> ${result}`
              } else if (result === 'Trend') {
                result = `<span class="bullet green"></span> ${result}`
              } else if (result === 'Mashup') {
                result = `<span class="bullet purple"></span> ${result}`
              }
            }
          } else if (this.type2 === 'reportIdentity') {
            if (result === 'admin' || result.includes('beusable')) {
              result = `<span class="grayscale">${result}</span>`
            }
          } else {
            if (result === POSITION_MY_JAM) {
              result = '<span>나의 JAM</span>'
            } else if (result === POSITION_BA_APPLY_BASKET) {
              result = '<span>히트맵 바구니</span>'
            }
          }
        } else if (this.type1 === 'datetime') {
          if (this.type2 === 'date') {
            const formatStr = 'yyyy-MM-dd'
            if (this.type3 === 'apply') {
              result = format(utcToZonedTime(result, 'Asia/Seoul'), formatStr)
            } else {
              result = format(utcToZonedTime(result, 'Asia/Seoul'), formatStr) +
              ' <span class="tip timezone">UTC+9</span>'
            }
          } else if (this.type2.indexOf('UTC+') === 0) {
            let tz = -parseInt(this.type2.substr(3, 2))
            if (tz >= 0) {
              tz = '+' + tz
            }
            if ((this.fieldName === 'startDate' ||
              this.fieldName === 'endDate' ||
              this.fieldName === 'regDate' ||
              this.fieldName === 'installDate' ||
              this.fieldName === 'v1LastFoundDate' ||
              this.fieldName === 'v2LastFoundDate') &&
              (this.type3 === 'apply' || this.type3 === 'domain')) {
              result = format(utcToZonedTime(result, 'Etc/GMT' + tz.toString()), 'yyyy-MM-dd HH:mm:ss')
            } else if ((this.fieldName === 'startDateTz' ||
              this.fieldName === 'endDateTz' ||
              this.fieldName === 'regDateTz' ||
              this.fieldName === 'installDateTz' ||
              this.fieldName === 'v1LastFoundDateTz' ||
              this.fieldName === 'v2LastFoundDateTz') &&
              (this.type3 === 'apply' || this.type3 === 'domain')) {
              tz = getTimezoneOffset(this.timezoneName) / 60 / 60 / 1000
              if (tz >= 0) {
                tz = '+' + tz
              }
              result = result + ` <span class="tip timezone">UTC${tz}</span>`
            } else {
              result = format(utcToZonedTime(result, 'Etc/GMT' + tz.toString()), 'yyyy-MM-dd HH:mm:ss') +
              ` <span class="tip timezone">${this.type2}</span>`
            }
          }
        } else if (this.type1 === 'number') {
          if (this.type2 === 'beusPlan') {
            if (result === 'pvCharge') {
              result = '<i class="bi-beus"></i> PV 충전'
            } else {
              if (this.beusPlanData[result]) {
                result = `<span class="plan${this.beusPlanData[result].bFree ? ' free' : ''}"><i class="bi-beus white"></i>
                ${this.beusPlanData[result].planName} <span class="tip plan-number">${result}</span></span>`
              }
            }
          } else if (this.type2 === 'baPlan') {
            if (result === 'pvCharge') {
              result = '<i class="bi-ba"></i> PV 충전'
            } else {
              if (this.baPlanData[result]) {
                result = `<span class="plan${this.baPlanData[result].bFree ? ' free' : ''}"><i class="bi-ba white"></i>
                ${this.baPlanData[result].planName} <span class="tip plan-number">${result}</span></span>`
              }
            }
          } else if (this.type2 === 'count') {
            if (this.type3 === 'apply' || this.type3 === 'domain') {
              let tz
              if (this.fieldName === 'sessionPerMonth' || this.fieldName === 'pvPerMonth') {
                tz = getTimezoneOffset(DEFAULT_TIMEZONE_NAME) / 60 / 60 / 1000
              } else {
                tz = getTimezoneOffset(this.timezoneName) / 60 / 60 / 1000
              }
              if (tz >= 0) {
                tz = '+' + tz
              }
              result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ` <span class="tip timezone">UTC${tz}</span>`
            } else {
              result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          } else if (this.type2 === 'memory') {
            result = this.bytesToSize(result)
          } else if (this.type2 === 'price') {
            result = '<i class="xi-won"></i>' + result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if (this.type2 === 'percent') {
            result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%'
          } else if (this.type2 === 'timediff') {
            result = result + '초'
          } else if (this.type2 === "percentChange") {
            const precentValue = result.value.toFixed(1)
            const variance = result.variance.toFixed(1)
            if (variance < 0) {
              result = `${precentValue}% ( <span class="arrow blue">▼</span> ${Math.abs(variance)}%p )`;
            } else if (variance > 0) {
              result = `${precentValue}% ( <span class="arrow red">▲</span> ${Math.abs(variance)}%p )`;
            } else {
              result = `${precentValue}% ( ${Math.abs(variance)}%p )`;
            }
          } else if (this.listData) {
            for (const item of this.listData) {
              if (item.value === result) {
                result = item.name
              }
            }
          }
        } else if (this.type1 === 'boolean') {
          if (this.type2 === 'success') {
            if (result === true) {
              result = '<span class="bullet green"></span> 성공'
            } else if (result === false) {
              result = '<span class="bullet red"></span> 실패'
            }
          } else if (this.type2 === 'distribute') {
            if (result === true) {
              result = '<span class="bullet green"></span> 배포'
            } else if (result === false) {
              result = '<span class="bullet red"></span> 미배포'
            }
          } else if (this.type2 === 'planState') {
            if (result === true) {
              result = '<span class="bullet green"></span> 적용 중'
            } else if (result === false) {
              result = '<span class="bullet red"></span> 지난 플랜'
            }
          } else if (this.type2 === 'isadmin') {
            if (result === true) {
              result = '<span>O</span>'
            } else if (result === false) {
              result = '<span>X</span>'
            } else if (result === 'dev') {
              result = '<span>O*</span>'
            }
          } else {
            if (result === true) {
              result = '<span class="bullet green"></span> 예'
            } else if (result === false) {
              result = '<span class="bullet red"></span> 아니오'
            }
          }
        } else if (this.type1 === 'image') {
          if (this.type2 === 's3') {
            if (result) {
              const [bucketName, filePath] = result.split(':')
              result = `<img src="https://s3.ap-northeast-2.amazonaws.com/${bucketName}/${filePath}?cache=${new Date().getTime()}" alt="">`
            }
          } else if (this.type2 === 'link') {
            if (result) {
              const [bucketName, filePath] = result.split(':')

              const fileFullName = filePath.split('/')
              const length = fileFullName.length
              const fileName = fileFullName[length - 1]

              result = `<a target="_blank" href="https://s3.ap-northeast-2.amazonaws.com/${bucketName}/${filePath}?cache=${new Date().getTime()}" alt="">${fileName}</a>`
            }
          }
        } 
      }

      return result
    }
  },
  methods: {
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    },
    selectText (element) {
      let range
      if (document.selection) {
        range = document.body.createTextRange()
        range.moveToElementText(element)
        range.select()
      } else if (window.getSelection) {
        range = document.createRange()
        range.selectNode(element)
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
    },
    copy () {
      this.selectText(this.$refs.dataUi)
      document.execCommand('copy')
      toastWarning('복사했습니다')
    },
    setTableData (idx, field, data) {
      this.$emit('setTableData', idx, field, data)
    },
    button (value, data) {
      this.$emit('button', value, data)
    },
    changeTableSort (sort) {
      this.$emit('changeTableSort', sort)
    }
  }
}
</script>

<style lang="scss" scoped>
div.ui-data {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    box-shadow: inset 0 0 10px 10px $gray;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px $gray;
  }
}

.ui-multiline {
  .ui-multiline-line {
    white-space: nowrap;
    padding: 5px 0;
    border-bottom: 1px solid $dim;

    .ui-data {
      display: block;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.ui-messagebox {
  margin: 0 -10px;
  padding: 15px 10px;
  &:nth-child(2n) {
    background: $light;
  }
}

.ui-message {
    display: block;
    padding: 10px 0 10px 30px;
}

.ui-data {
  &.type1text {
    &.type2url {
      word-break: break-all;
    }
  }
  &.type1number {
    text-align: right;
  }

  &.type2imageupload {
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  &.type1datetime,
  &.type1image,
  &.type2state,
  &.type2card,
  &.type2type,
  &.type2lang {
    text-align: center;
  }

  &.type2service,
  &.type2beusPlan,
  &.type2baPlan {
    text-align: left;
  }

  &.type2count,
  &.type2price,
  &.type2percent {
    font-family: 'Noto Sans Mono', Monospaced;
    letter-spacing: -1px;
    overflow: visible !important;

    & :deep .xi-won {
      float: left;
      margin: 2.5px 2px 0 0;
    }
  }

  &.type2price {
    min-width: 83px;
  }

  &.type2code {
    margin-top: -11px !important;

    & :deep .code {
      padding-bottom: 11px;
    }
  }

  &.type2sender {
    & :deep {
      i[class^=xi-] {
        font-size: 20px;
        color: $black;
        vertical-align: bottom;
        padding-right: 5px;
      }
      i.bi-solomon {
        background-image: url('~@/assets/icon--solomon.png');
        height: 20px;
        width: 17px;
        margin: 0 5px 0 3px;
      }
    }
  }

  &.type3sendTime {
    color: gray;
    font-size: 13px;
    padding-left: 10px;
  }

  &.center {
    max-width: inherit !important;
    text-align: center;
  }

  & :deep {
    a {
      color: inherit;
      border-bottom: 1px solid $light;
      transition: border-bottom-color 0.3s ease;

      &:hover {
        border-bottom-color: $gray;
      }
    }

    .grayscale {
      color: $darkgray;
    }

    i[class^=xi-] {
      color: $purple;
    }

    img {
      max-width: 100%;
    }

    .code {
      display: block;
      overflow: auto;
      background: $darkgray;
      color: $white;
      padding: 10px 13px;
      border-radius: 10px;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border: 3px solid transparent;
        box-shadow: inset 0 0 10px 10px $gray;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px $gray;
      }

      pre {
        font-family: 'Noto Sans Mono', Monospaced;
        width: 100%;
        line-height: 1.7;
        font-size: 0.8em;
      }
    }

    .static,
    .match {
      padding: 1px 4px;
      font-family: 'Noto Sans Mono', Monospaced;
      border-radius: 3px;
      background: $dimlight;
    }

    .plan {
      display: inline-block;
      vertical-align: middle;
      background: $purple;
      color: $white;
      border-radius: 10px;
      padding: 3px 4px 3px 7px;
      line-height: 1;

      &.free {
        background: $gray;
        opacity: 0.7;
      }

      i {
        transform: translateY(-0.1em);
      }

      .tip {
        color: $gray;
        background: $white;
      }
    }

    .code-list {
      word-break: break-all;
    }

    .state {
      display: inline-block;
      vertical-align: middle;
      background: $gray;
      background-size: 100% 100%;
      color: $white;
      border-radius: 10px;
      padding: 3px 7px;
      font-wize: 0.9em;
      line-height: 1.11em;

      &.red {
        background-color: $red;
      }

      &.blue {
        background-color: $blue;
      }

      &.green {
        background-color: $green;
      }

      &.yellow {
        background-color: $orange;
      }

      &.purple {
        background-color: $purple;
      }

      &.dim {
        opacity: 0.7;
      }
    }

    .method {
      &.card {
        i {
          color: $orange;
        }
      }

      &.wire {
        i {
          color: $green;
        }
      }

      &.coupon {
        i {
          color: $pink;
        }
      }

      &.admin {
        i {
          color: $red;
        }
      }
    }

    .bullet {
      display: inline-block;
      vertical-align: middle;
      background: $gray;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      transform: translateY(-1px);

      &.green {
        background: $grass;
      }

      &.red {
        background: $red;
      }
      
      &.blue {
        background: $blue;
      }

      &.orange {
        background: $orange;
      }
      
      &.purple {
        background: $lightpurple;
      }
    }

    .arrow {
      &.red {
        color: $red;
      }
      &.blue {
        color: $blue;
      }
    }

    .tip {
      display: inline-block;
      vertical-align: middle;
      background: $gray;
      color: $white;
      border-radius: 10px;
      font-size: 70%;
      padding: 2px 4px;
      transform: translateY(-1px);
      line-height: 1;

      &.timezone {
        background: $gray;
        opacity: 0.7;
      }
    }
  }
}
</style>
