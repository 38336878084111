import { convertToTimeZone } from 'date-fns-timezone'

export function isString (value) {
  return typeof value === 'string'
}

export function partition (arr, size) {
  return arr.reduce((acc, cur, idx) => {
    if (idx % size === 0) {
      acc.push([cur])
    } else {
      acc[acc.length - 1].push(cur)
    }
    return acc
  }, [])
}

export function utcToLocalTimezone (value, timezoneName) {
  return convertToTimeZone(value, { timeZone: timezoneName })
}

export function triggerClickEvent () {
  let event

  if (typeof Event === 'function') {
    event = new Event('click')
  } else {
    event = document.createEvent('Event')
    event.initEvent('click', false, true)
  }
  document.body.dispatchEvent(event)
}
