<template>
  <div class="ui-editor"
       :class="{ readonly }">
    <ckeditor5 :editor="editor"
               :config="editorConfig"
               v-model="editorData"
               @input="setValue"/>
  </div>
</template>

<script>
import CKEditor5 from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'UiEditor5',
  components: {
    ckeditor5: CKEditor5.component
  },
  data () {
    return {
      editorData: '',
      editor: ClassicEditor,
      editorConfig: {
        allowedContent: true
      }
    }
  },
  props: {
    value: {
      default: '',
      required: true
    },
    readonly: {
      default: false
    }
  },
  watch: {
    value () {
      this.editorData = JSON.parse(JSON.stringify(this.value))
    }
  },
  created () {
    if (this.value) {
      this.editorData = JSON.parse(JSON.stringify(this.value))
    }
  },
  methods: {
    setValue (e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-editor {
  & ::v-deep {
    .ck-editor {
      margin: -16px -10px -1px;
    }
  }

  &.readonly::v-deep {
    .ck-editor {
      margin: -10px;
    }

    .ck-sticky-panel {
      display: none;
    }

    .ck-rounded-corners {
      border: none;
    }
  }
}
</style>
