<template>
  <div class="coupon-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :reload="reload"
                          :initialSearchFilter="initialSearchFilter"
                          :selected="selected"
                          @select="setSelected"
                          @showDetail="showDetail">
      <ui-button :color="'green'"
                 @click="toggleInsert">
        <i class="xi-plus"></i> 팝업 생성
      </ui-button>
    </template-search-list>

    <template-insert :show="show.insert"
                     :metaApi="insertMetaApi"
                     :insertApi="insertApi"
                     @input="insertInput"
                     @reload="reloadList"
                     @saved="showDetail"
                     @close="toggleInsert">
      <h4 class="title">
        <i v-if="serviceType === 'beusable'"
           class="bi-beus"></i>
        <i v-else-if="serviceType === 'ba'"
           class="bi-ba"></i>
        팝업 생성
      </h4>

      <ui-button :color="'blue'"
                 @click="togglePreview(model)">
        <i class="xi-eye"></i> 미리보기
      </ui-button>

      <ui-button :color="'green'"
                 @click="removeTemplate">
        <i class="xi-scissors"></i> 템플릿 삭제
      </ui-button>

      <ui-button :color="'green'"
                 @click="insertTemplate">
        <i class="xi-document"></i> 템플릿 저장
      </ui-button>
    </template-insert>

    <template-detail :infoApi="infoApi"
                     :editApi="editApi"
                     :removeApi="removeApi"
                     :apiParam="apiParam"
                     @input="insertInput"
                     @reload="reloadList"
                     @close="setSelected">
      <ui-button :color="'blue'"
                 @click="togglePreview(model)">
        <i class="xi-eye"></i> 미리보기
      </ui-button>
    </template-detail>

    <popup-preview :data="preview"
                   @close="togglePreview(false)"/>
  </div>
</template>

<script>
import {
  getPopupList,
  getPopupInfo,
  getBeusPopupInsertMeta,
  getBaPopupInsertMeta,
  upsertPopup,
  removePopup,
  insertPopupTemplate,
  removePopupTemplate
} from '@/api/marketing'
import { alertError } from '@/utils/tools'

import PopupPreview from '@/components/marketing/popup/PopupPreview'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateInsert from '@/components/_template/Insert'
import TemplateDetail from '@/components/_template/Detail'
import UiButton from '@/components/_ui/UiButton'

export default {
  name: 'MarketingPopupList',
  components: {
    PopupPreview,
    TemplateSearchList,
    TemplateInsert,
    TemplateDetail,
    UiButton
  },
  methods: {
    showDetail (data) {
      this.apiParam = { id: data._id || data.groupId }
      this.model = { ...data }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    insertInput (field, value) {
      this.$set(this.model, field, value)
    },
    removeTemplate () {
      const templateId = this.model.template
      if (templateId) {
        if (confirm('현재 선택된 템플릿을 삭제하고 작성을 끝내시겠습니까?')) {
          removePopupTemplate({ template_ids: [templateId] })
            .then(response => {
              if (response.success) {
                alert('템플릿을 삭제하였습니다.')
                this.toggleInsert()
              } else {
                alertError('저장에 실패했습니다.')
              }
            })
            .catch(err => {
              alertError('저장에 실패했습니다.', err)
            })
        }
      } else {
        alert('선택된 템플릿이 없습니다.')
      }
    },
    togglePreview (value = false) {
      this.preview = value
    },
    insertTemplate () {
      if (confirm('현재 작성된 내용을 템플릿으로 저장하고 작성을 끝내시겠습니까?')) {
        const templateTitle = prompt('템플릿 이름을 입력해주세요')
        if (!templateTitle || !templateTitle.trim()) {
          alert('템플릿 이름을 지정하지 않았습니다!')
          return false
        }
        insertPopupTemplate({
          title: templateTitle,
          content: this.model.content,
          serviceType: this.serviceType,
          status: 'publish'
        })
          .then(response => {
            if (response.success) {
              alert('저장에 성공했습니다.')
              this.toggleInsert()
            } else {
              alertError('저장에 실패했습니다.')
            }
          })
          .catch(err => {
            alertError('저장에 실패했습니다.', err)
          })
      }
    },
    setSelected (row) {
      this.selected = row
    },
  },
  data () {
    return {
      serviceType: this.$route.meta.ba ? 'ba' : 'beusable',
      reload: 0,
      selected: null,

      infoApi: getPopupInfo,
      editApi: upsertPopup,
      removeApi: removePopup,
      insertApi: upsertPopup,
      insertMetaApi: this.$route.meta.ba ? getBaPopupInsertMeta : getBeusPopupInsertMeta,
      apiParam: null,

      listApi: getPopupList,
      buttonOption: [
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',
      initialSearchFilter: {
        serviceType: this.$route.meta.ba ? 'ba' : 'beusable'
      },
      show: {
        insert: false
      },

      preview: false,
      model: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-list-wrap {
}
</style>
