import { request } from '@/utils/api-request'

export function getEmailList (params) {
  const url = '/admin/email/email_list'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getEmail (params) {
  const url = '/admin/email/get_email'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function emailResend (params) {
  const url = '/admin/email/email_resend'
  const config = {
    method: 'post',
    url,
    data: params
  }
  return request(config, true)
}

export function getContactUsHistoryList(params) {
  const url = "/admin/contactus/list";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, true);
}

export function getContactUsHistoryDetail(params) {
  const url = "/admin/contactus/detail";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, true);
}

export function updateContactusHistory(params) {
  const url = "/admin/contactus/update";
  const config = {
    method: "post",
    url,
    data: params,
  };
  return request(config, true);
}

