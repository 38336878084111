<template>
  <div class="beus-apply-list-wrap">
    <template-search-list :listApi="listApi"
                          :buttonOption="buttonOption"
                          :defaultSortBy="defaultSortBy"
                          :initialSearchFilter="initialSearchFilter"
                          :reload="reload"
                          :selected="selected"
                          @select="setSelected"
                          @searchFilter="getSearchFilter"
                          @toReport="toReport"
                          @showDetail="showDetail">
      <ui-button :color="'purple'"
                 @click="toggleExcel(true)">
        <i class="xi-view-list"></i> 엑셀 다운로드
      </ui-button>
    </template-search-list>

    <template-detail :infoApi="infoApi"
                     :apiParam="apiParam"
                     @button="button"
                     @close="setSelected">
    </template-detail>

    <excel-column-select :excelDownload="excelDownload"
                         :metaApi="excelListMetaApi"
                         :listApi="listApi"
                         :searchFilter="searchFilter"
                         @export="excelExport"
                         @close="toggleExcel(false)"/>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { BEUS_URL } from '@/utils/tools'

import {
  getBeusApplyList,
  getBeusApplyInfo,
  getBeusApplyExcelMeta
} from '@/api/abService'

import TemplateSearchList from '@/components/_template/SearchList'
import TemplateDetail from '@/components/_template/Detail'
import UiButton from '@/components/_ui/UiButton'
import Excel from '@/utils/excel-export'
import ExcelColumnSelect from '@/components/_template/ExcelColumnSelect'

import TemplateFile from '@/assets/eagle-apply-bulk-insert.xlsx'
import TemplateFileJa from '@/assets/eagle-apply-bulk-insert-ja.xlsx'
import { STORAGE_ITEM } from '@/utils/constants'

export default {
  name: 'BeusApplyListAB',
  components: {
    ExcelColumnSelect,
    TemplateSearchList,
    TemplateDetail,
    UiButton
  },
  created () {
    const localStorageData = localStorage.getItem(STORAGE_ITEM.searchData)
    if (localStorageData !== '{}' || localStorageData !== 'null') {
      const searchData = JSON.parse(localStorageData)
      if (searchData && searchData.aid) {
        this.initialSearchFilter = {}
        this.initialSearchFilter.orgAid = searchData.aid
      }
    }
    localStorage.setItem(STORAGE_ITEM.searchData, null)
  },
  methods: {
    toReport (data) {
      window.open(`${BEUS_URL}/report/${data.orgAid}/ab/${data._id}`)
    },
    showDetail (data) {
      this.tempDetailData = data
      this.apiParam = { aid: data._id }
    },
    reloadList () {
      this.reload++
    },
    toggleInsert (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insert = !this.show.insert
      } else {
        this.show.insert = value
      }
    },
    toggleInsertBulk (value = null) {
      this.apiParam = null
      if (value !== null) {
        this.show.insertBulk = !this.show.insertBulk
      } else {
        this.show.insertBulk = value
      }
    },
    toggleExcel (value = false) {
      this.excelDownload = value
    },
    excelExport (col, data, selectCol) {
      const fileName = 'eagle_apply'
      const excel = new Excel()
      EventBus.$emit('onLoading', true)
      excel.setExcelData('service', col, data || [], selectCol, {})
        .then(result => {
          excel.download(fileName)
          this.excelDownload = false
          EventBus.$emit('onLoading', false)
        }).catch(e => {
          EventBus.$emit('onLoading', false)
        })
    },
    getSearchFilter (value) {
      this.searchFilter = value
    },
    button (value, data) {
    },
    setSelected (row) {
      this.selected = row
    },
  },
  data () {
    return {
      reload: 0,
      selected: null,

      infoApi: getBeusApplyInfo,
      apiParam: null,
      
      listApi: getBeusApplyList,
      excelListMetaApi: getBeusApplyExcelMeta,
      buttonOption: [
        {
          action: 'toReport',
          columnText: '리포트',
          buttonText: '<i class="xi-document"></i>',
          if: ['installDate', '!=', null]
        },
        {
          action: 'showDetail',
          columnText: '자세히',
          buttonText: '<i class="xi-arrow-right"></i>'
        }
      ],
      defaultSortBy: '-regDate',

      show: {
        insert: false,
        insertBulk: false
      },
      excelDownload: false,
      searchFilter: {},
      initialSearchFilter: null,

      templateFile: TemplateFile,
      templateFileJa: TemplateFileJa,
      tempDetailData: null
    }
  }
}
</script>

<style lang="scss" scoped>
.beus-apply-list-wrap {
}
</style>
