<template>
  <div class="dashboard">

    <div class="info">
      <ui-input :type="'date'"
                :value="date.start.value"
                @enter="$emit('enter')"
                @input="setStartDate"
                @focus="$emit('focus')">
          <span v-if="date.start.label"
                v-html="date.start.label"></span>
      </ui-input>
      <span class="seperator">~</span>
      <ui-input :type="'date'"
                :value="date.end.value"
                @enter="$emit('enter')"
                @input="setEndDate"
                @focus="$emit('focus')">
          <span v-if="date.end.label"
                v-html="date.end.label"></span>
      </ui-input>
      <ui-dropdown
                   :options="date.range.list"
                   :value="date.range.value"
                   @input="setRangeValue"
                   @focus="$emit('focus')">
          <span v-if="date.range.label"
                v-html="date.range.label"></span>
      </ui-dropdown>

      <ui-button @click="loadData">
        조회
      </ui-button>
      <ui-button @click="openNewDashboard">
        새창으로 열기
      </ui-button>
    </div>

    <div class="chart">
      <h1>Beusable</h1>
      <ui-button :color="'purple'"
                  @click="downloadExcel('plan', 'beusable')"
                  v-if="!isFull">
        <i class="xi-view-list"></i> 뷰저블 엑셀 다운로드
      </ui-button>
      <app-chart :chartType="chartType"
                 class="app-chart"
                 v-if="heatmapPlanOption"
                 :key="chartKey"
                 :chartOptions="heatmapPlanOption"
                 :height="250" />
    </div>

    <div class="chart">
      <h1>Beusable 플랜 변화</h1>
      <ui-button :color="'purple'"
                 @click="downloadExcel('plan_change', 'beusable')"
                 v-if="!isFull">
        <i class="xi-view-list"></i> 뷰저블 플랜 변화 엑셀 다운로드
      </ui-button>
      <app-chart :chartType="chartType"
                 class="app-chart"
                 v-if="heatmapPlanChangeOption"
                 :key="chartKey"
                 :chartOptions="heatmapPlanChangeOption"
                 :height="250" />
    </div>

    <div class="chart">
      <h1>Beusable 충전</h1>
      <ui-button :color="'purple'"
                 @click="downloadExcel('pv_charge', 'beusable')"
                 v-if="!isFull">
        <i class="xi-view-list"></i> 뷰저블 충전 엑셀 다운로드
      </ui-button>
      <app-chart :chartType="chartType"
                 class="app-chart"
                 v-if="heatmapChargeOption"
                 :key="chartKey"
                 :chartOptions="heatmapChargeOption"
                 :height="250" />
    </div>

    <div class="chart">
      <h1>BA</h1>
      <ui-button :color="'purple'"
                  @click="downloadExcel('plan', 'ba')"
                  v-if="!isFull">
        <i class="xi-view-list"></i> BA 엑셀 다운로드
      </ui-button>
      <app-chart :chartType="chartType"
                 class="app-chart"
                 v-if="journeyPlanOption"
                 :key="chartKey"
                 :chartOptions="journeyPlanOption"
                 :height="250" />
    </div>

    <div class="chart">
      <h1>BA 플랜 변화</h1>
      <ui-button :color="'purple'"
                 @click="downloadExcel('plan_change', 'ba')"
                 v-if="!isFull">
        <i class="xi-view-list"></i> BA 플랜 변화 엑셀 다운로드
      </ui-button>
      <app-chart :chartType="chartType"
                 class="app-chart"
                 v-if="journeyPlanChangeOption"
                 :key="chartKey"
                 :chartOptions="journeyPlanChangeOption"
                 :height="250" />
    </div>

    <div class="chart">
      <h1>BA 충전</h1>
      <ui-button :color="'purple'"
                 @click="downloadExcel('pv_charge', 'ba')"
                 v-if="!isFull">
        <i class="xi-view-list"></i> BA 충전 엑셀 다운로드
      </ui-button>
      <app-chart :chartType="chartType"
                 class="app-chart"
                 v-if="journeyChargeOption"
                 :key="chartKey"
                 :chartOptions="journeyChargeOption"
                 :height="250" />
    </div>
<!-- TODO: ADMIN-808 (son)  -->
<!--    <div class="chart">-->
<!--      <h1>JAM 충전</h1>-->
<!--      <ui-button :color="'purple'"-->
<!--                 @click="downloadExcel('jam', 'ba')"-->
<!--                 v-if="!isFull">-->
<!--        <i class="xi-view-list"></i> JAM 충전 엑셀 다운로드-->
<!--      </ui-button>-->
<!--      <app-chart :chartType="chartType"-->
<!--                 class="app-chart"-->
<!--                 v-if="jamChargeOption"-->
<!--                 :key="chartKey"-->
<!--                 :chartOptions="jamChargeOption"-->
<!--                 :height="250" />-->
<!--    </div>-->

  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import AppChart from '@/components/chart/index'
import UiInput from '@/components/_ui/UiInput'
import UiDropdown from '@/components/_ui/UiDropdown'
import UiButton from '@/components/_ui/UiButton'
import { getDashboardPlanData } from '@/api/dashboard'
import { dateObjectToString } from '@/utils/date-util'
import { addDays } from 'date-fns'
import { thousand } from '@/utils/tools'

import Excel from '@/utils/excel-export'
import { DASHBOARD_CHART_DATA, SERVICE_NAME_BA, SERVICE_NAME_BEUSABLE } from '@/utils/constants'

export default {
  name: 'DashboardComponent',
  components: {
    AppChart,
    UiInput,
    UiDropdown,
    UiButton
  },
  props: ['isFull'],
  async created () {
    EventBus.$emit('onLoading', true)
    const today = new Date()
    const start = addDays(today, this.isFull ? -30 : -7)

    this.date.start.value = dateObjectToString(start)
    this.date.end.value = dateObjectToString()

    await this.loadData()

    if (this.isFull) {
      setInterval(() => {
        this.forceRerenderChart()
      }, 1800000)
    }
  },
  data () {
    return {
      chartType: 'line',
      chartKey: 0,
      heatmapPlanOption: null,
      heatmapPlanChangeOption: null,
      heatmapChargeOption: null,
      journeyPlanOption: null,
      journeyChargeOption: null,
      journeyPlanChangeOption: null,
      jamChargeOption: null,
      // chartColor: [
      //   '#198310',
      //   '#366032',
      //   '#641931',
      //   '#C10A45',
      //   '#BFBFBF'
      // ],
      chartPlanColor: [
        '#B898D0',
        '#A2B9E2',
        '#BFBFBF',
        '#ffd6d6',
        '#84f3e7',
        '#668493'
      ],
      chartChargeColor: [
        '#366032',
        '#651931'
      ],
      date: {
        start: {
          label: '시작일',
          value: ''
        },
        end: {
          label: '종료일',
          value: ''
        },
        range: {
          label: '범위',
          list: [
            { name: 'day', value: 'day' },
            { name: 'month', value: 'month' },
            { name: 'year', value: 'year' }
          ],
          value: 'day'
        }
      }
    }
  },
  methods: {
    async loadData () {
      EventBus.$emit('onLoading', true)
      // await this.getHeatmapPlan()
      // await this.getJourneyPlan()

      await this.getChartData(DASHBOARD_CHART_DATA.plan, SERVICE_NAME_BEUSABLE)
      await this.getChartData(DASHBOARD_CHART_DATA.pv, SERVICE_NAME_BEUSABLE)
      await this.getChartData(DASHBOARD_CHART_DATA.change, SERVICE_NAME_BEUSABLE)

      await this.getChartData(DASHBOARD_CHART_DATA.plan, SERVICE_NAME_BA)
      await this.getChartData(DASHBOARD_CHART_DATA.pv, SERVICE_NAME_BA)
      await this.getChartData(DASHBOARD_CHART_DATA.change, SERVICE_NAME_BA)
      // TODO: ADMIN-808 (son)
      // await this.getChartData(DASHBOARD_CHART_DATA.jam, SERVICE_NAME_BA)

      console.log('load')
      EventBus.$emit('onLoading', false)
    },
    openNewDashboard () {
      window.open('/dashboard/full')
    },
    setStartDate (value) {
      this.date.start.value = value
    },
    setEndDate (value) {
      this.date.end.value = value
    },
    setRangeValue (value) {
      this.date.range.value = value
    },
    forceRerenderChart () {
      this.chartKey += 1
    },
    getChartOption (result, countType) {
      result.xAxis.type = 'category'
      result.xAxis.boundaryGap = false
      result.legend = {
        data: [],
        inactiveColor: 'rgba(1, 1, 1, 0.05)'
      }
      result.series.forEach((item, index) => {
        item.type = this.chartType
        item.stack = 'Total'
        item.symbol = 'circle'
        item.areaStyle = {
          color: countType === DASHBOARD_CHART_DATA.plan || countType === DASHBOARD_CHART_DATA.change ? this.chartPlanColor[index] : this.chartChargeColor[index]
        }
        item.itemStyle = {
          color: countType === DASHBOARD_CHART_DATA.plan || countType === DASHBOARD_CHART_DATA.change ? this.chartPlanColor[index] : this.chartChargeColor[index]
        }

        // if (index === 1) {
        //   item.lineStyle = {
        //     opacity: 1,
        //     width: 1,
        //     color: '#fff'
        //   }
        // } else {
        //   item.lineStyle = {
        //     opacity: 0
        //   }
        // }

        item.lineStyle = {
          opacity: 0
        }

        item.emphasis = {
          focus: 'series'
        }

        const legend = {
          name: item.name,
          itemStyle: {
            color: countType === DASHBOARD_CHART_DATA.plan || countType === DASHBOARD_CHART_DATA.change ? this.chartPlanColor[index] : this.chartChargeColor[index]
          }
        }
        result.legend.selected = {}
        if (countType === DASHBOARD_CHART_DATA.plan && index === result.series.length - 1) {
          result.legend.selected[item.name] = false
        }

        result.legend.data.push(legend)
      })
      result.tooltip = {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      }

      return result
    },
    getChartData (countType, serviceType) {
      const params = {
        searchFrom: {
          start: this.date.start.value,
          end: this.date.end.value,
          granularity: this.date.range.value,
          countType: countType,
          serviceType: serviceType
        }
      }

      return getDashboardPlanData(params).then(async response => {
        if (response.success) {
          const result = response.result
          const chartOption = await this.getChartOption(result, countType)

          if (serviceType === SERVICE_NAME_BEUSABLE) {
            if (countType === DASHBOARD_CHART_DATA.plan) {
              this.heatmapPlanOption = chartOption
            } else if (countType === DASHBOARD_CHART_DATA.pv) {
              this.heatmapChargeOption = chartOption
            } else {
              this.heatmapPlanChangeOption = chartOption
            }
          } else {
            if (countType === DASHBOARD_CHART_DATA.plan) {
              this.journeyPlanOption = chartOption
            } else if (countType === DASHBOARD_CHART_DATA.pv) {
              this.journeyChargeOption = chartOption
            } else if (countType === DASHBOARD_CHART_DATA.jam) {
              this.jamChargeOption = chartOption
            } else {
              this.journeyPlanChangeOption = chartOption
            }
          }
        }
        return response.success
      })
    },
    /**
     * @param {boolean} isBeusable - Beusable Chart라면 true BA Chart라면 false
     * Excel Download Function
     */
    downloadExcel (countType, serviceType) {
      const excel = new Excel()
      let fileName = ''
      let series = null
      let xAxis = null

      let selectColumn = ['date', 'paid', 'free', 'invalidity']

      let col = [
        {
          name: '날짜',
          field: 'date',
          value: true
        },
        {
          name: '유료',
          field: 'paid',
          value: true
        },
        {
          name: '무료',
          field: 'free',
          value: true
        },
        {
          name: '무효',
          field: 'invalidity',
          value: true
        }
      ]

      if (countType === DASHBOARD_CHART_DATA.pv) {
        col = [
          {
            name: '날짜',
            field: 'date',
            value: true
          },
          {
            name: '유료+충전',
            field: 'paidCharge',
            value: true
          },
          {
            name: '무료+충전',
            field: 'freeCharge',
            value: true
          }
        ]

        selectColumn = ['date', 'paidCharge', 'freeCharge']
      }

      if (countType === DASHBOARD_CHART_DATA.change) {
        col = [
          {
            name: '날짜',
            field: 'date',
            value: true
          },
          {
            name: '유료 -> 무효',
            field: 'paidVoid',
            value: true
          },
          {
            name: '무효 -> 유료',
            field: 'voidPaid',
            value: true
          },
          {
            name: '무료 -> 무효',
            field: 'freeVoid',
            value: true
          },
          {
            name: '무료 -> 유효',
            field: 'freePaid',
            value: true
          },
          {
            name: '무효 -> 무료',
            field: 'voidFree',
            value: true
          },
          {
            name: '유료 -> 무료',
            field: 'paidFree',
            value: true
          }
        ]

        selectColumn = ['date', 'paidVoid', 'voidPaid', 'freeVoid', 'freePaid', 'voidFree', 'paidFree']
      }

      if (countType === DASHBOARD_CHART_DATA.jam) {
        col = [
          {
            name: '날짜',
            field: 'date',
            value: true
          },
          {
            name: '유료',
            field: 'paid',
            value: true
          },
          {
            name: '무료',
            field: 'free',
            value: true
          }
        ]

        selectColumn = ['date', 'paid', 'free']
      }

      if (serviceType === SERVICE_NAME_BEUSABLE) {
        if (countType === DASHBOARD_CHART_DATA.plan) {
          fileName = 'dashboard_plan_beusable'
          series = this.heatmapPlanOption.series
          xAxis = this.heatmapPlanOption.xAxis.data
        } else if (countType === DASHBOARD_CHART_DATA.pv) {
          fileName = 'dashboard_pv_charge_beusable'
          series = this.heatmapChargeOption.series
          xAxis = this.heatmapChargeOption.xAxis.data
        } else {
          fileName = 'dashboard_plan_change_beusable'
          series = this.heatmapPlanChangeOption.series
          xAxis = this.heatmapPlanChangeOption.xAxis.data
        }
      } else {
        if (countType === DASHBOARD_CHART_DATA.plan) {
          fileName = 'dashboard_plan_ba'
          series = this.journeyPlanOption.series
          xAxis = this.journeyPlanOption.xAxis.data
        } else if (countType === DASHBOARD_CHART_DATA.pv) {
          fileName = 'dashboard_pv_charge_ba'
          series = this.journeyChargeOption.series
          xAxis = this.journeyChargeOption.xAxis.data
        } else if (countType === DASHBOARD_CHART_DATA.jam) {
          fileName = 'dashboard_jam_charge_ba'
          series = this.jamChargeOption.series
          xAxis = this.jamChargeOption.xAxis.data
        } else {
          fileName = 'dashboard_pv_change_ba'
          series = this.journeyPlanChangeOption.series
          xAxis = this.journeyPlanChangeOption.xAxis.data
        }
      }

      const data = xAxis.map((x, index) => {
        if (countType === DASHBOARD_CHART_DATA.plan) {
          return (
            {
              date: x,
              paid: thousand(series[0].data[index]),
              free: thousand(series[1].data[index]),
              invalidity: thousand(series[2].data[index])
            }
          )
        } else if (countType === DASHBOARD_CHART_DATA.pv) {
          return (
            {
              date: x,
              paidCharge: thousand(series[0].data[index]),
              freeCharge: thousand(series[1].data[index])
            }
          )
        } else if (countType === DASHBOARD_CHART_DATA.jam) {
          return (
            {
              date: x,
              paid: thousand(series[0].data[index]),
              free: thousand(series[1].data[index])
            }
          )
        } else {
          //  selectColumn = ['date', 'paidVoid', 'voidPaid', 'freeVoid', 'freePaid']
          return (
            {
              date: x,
              paidVoid: thousand(series[0].data[index]),
              voidPaid: thousand(series[1].data[index]),
              freeVoid: thousand(series[2].data[index]),
              freePaid: thousand(series[3].data[index])
            }
          )
        }
      })

      excel.setExcelData('dashboard', col, data, selectColumn, {})
        .then(result => {
          console.log(result)
          excel.download(fileName)
        }).catch(e => {
          console.log(e)
        })
    }
  },
  destroyed () {
    this.heatmapPlanOption = null
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 50px;

  .info {
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 10px;
    & :deep {
      .ui-input, .ui-dropdown {
        width: 240px;
        display: inline-block;

        & > .ui-dropdown-container {
          height: 32px;
        }
      }

      .ui-button {
        padding-bottom: 20px;
      }
    }
    .seperator {
      padding: 0px 20px 20px 20px;
    }
  }

  .chart {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    border: 1px solid #bbbbbb;
    text-align: center;
    padding-right: 10px;

    & > h1 {
      margin: 20px 0;
    }

    & > .ui-button {
      position: absolute;
      top: 13px;
      right: 13px;
    }
  }
  .temp {
    position: relative;
    width: 100%;
    height: calc(100vh - 73px);
    overflow: hidden;

    h1 {
      position: absolute;
      bottom: 3px;
      right: -0.5vw;
      line-height: 0.7;
      font-size: 5vw;
      font-weight: bold;
      color: $light;
      text-align: right;
    }
  }
}
</style>
